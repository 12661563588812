import { UserInformation } from '../app/UserInformationContext';
import LocalStorageKeys from '../constants/local-storage';

const isUserAuthenticated = (userInformation: UserInformation): boolean => {
  // TODO - wire this up for real
  return userInformation && (userInformation.name || '').length > 0;
};

const isUserAuthorized = (userInformation: UserInformation, userRole: string): boolean => {
  // We require that a user has explicit roles for various things (ie. An "Admin" role does
  // not give the user access to everything)
  return userInformation && userInformation.userRoles.find(v => v.id === userRole) !== undefined;
};

const populateUserInformationFromLocalStorage = (): UserInformation | null => {
  const userInfoJSONFromLocalStorage = localStorage.getItem(LocalStorageKeys.UserInformation);

  if (userInfoJSONFromLocalStorage) {
    try {
      const userInfoFromLocalStorage: UserInformation = JSON.parse(userInfoJSONFromLocalStorage);
      if (userInfoFromLocalStorage && userInfoFromLocalStorage.name) {
        return userInfoFromLocalStorage;
      }
    } catch (error) {}
  }

  return null;
};

export { isUserAuthenticated, isUserAuthorized, populateUserInformationFromLocalStorage };
