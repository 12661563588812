import { MultiSelect } from '@progress/kendo-react-dropdowns';
import { Field, FieldInputProps, FieldProps } from 'formik';
import { get, sortBy } from 'lodash';
import React from 'react';
import './Forms.scss';

export interface FormMultiSelectOption {
  id: string;
  name: string;
}

interface FieldValue {
  id: string;
  // NOTE: using any here to make a dict type
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

interface Props {
  placeholderText: string;
  label: string;
  name: string;
  data: FormMultiSelectOption[];
  validator?: (value: string) => string | void;
}

const getValueForMultiSelect = (
  data: FormMultiSelectOption[],
  field: FieldInputProps<FieldValue[]>,
): FieldValue[] => {
  return !field.value
    ? []
    : sortBy(data.filter(v => field.value.find(fv => fv.id === v.id) !== undefined), v => v.name);
};

const WrappedMultiSelect: React.FC<FieldProps & Props> = ({
  field,
  form,
  placeholderText,
  label,
  data,
  ...rest
}) => {
  const errors = get(form.errors, field.name);
  const hasErrors = !!errors;

  const classNames = ['formInput'];
  if (hasErrors && form.submitCount) {
    classNames.push('formInputError');
  }

  // NOTE: Keep the field / rest props at the top so we can override the onChange property
  return (
    <>
      <label htmlFor={field.name}>{label}</label>
      <MultiSelect
        id={field.name}
        className={classNames.join(' ')}
        placeholder={placeholderText}
        autoClose={false}
        textField="name"
        dataItemKey="id"
        data={data ? sortBy(data, v => v.name) : []}
        {...field}
        {...rest}
        value={getValueForMultiSelect(data, field)}
        onChange={(newValue): void => {
          // Sort the values before we set them, to try to prevent data getting out of order and changing unnecessarily
          const newValues = sortBy(newValue.target.value as FormMultiSelectOption[], v => v.name);
          form.setFieldValue(field.name, newValues);
        }}
      />
    </>
  );
};

const FormMultiSelect: React.FC<Props> = props => {
  return (
    <div>
      <Field
        {...props}
        name={props.name}
        validate={props.validator}
        label={props.label}
        component={WrappedMultiSelect}
      />
    </div>
  );
};

export default FormMultiSelect;
