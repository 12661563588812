import moment from 'moment';
import { cloneDeep } from 'lodash';

const getShortDateString = (dateObj: Date): string => {
  // NOTE: converting to UTC here because a timezone conversion would give us the wrong date
  // NOTE: The "L" date format uses the user's locale for the date format
  return moment.utc(dateObj).format('L');
};

// NOTE: using `any` here because of metaprogramming
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getObjectWithDateStringsAsDates = (data: any): any => {
  const result = cloneDeep(data);

  if (Array.isArray(data)) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const resultArray = result as any[];
    return resultArray.map(r => getObjectWithDateStringsAsDates(r));
  }

  for (const key in result) {
    const value = result[key];

    if (typeof value === 'object') {
      result[key] = getObjectWithDateStringsAsDates(value);
      continue;
    }

    const dateValue = moment(value, moment.ISO_8601);

    if (!dateValue.isValid()) {
      continue;
    }

    result[key] = dateValue.toDate();
  }

  return result;
};

export { getShortDateString, getObjectWithDateStringsAsDates };
