import React, { useCallback, useState } from 'react';
import UserConstants from '../constants/users';
import { isUserAuthorized } from '../utils/user-authorization';
import LocalStorageKeys from './../constants/local-storage';

export interface UserInformation {
  id: string;
  emailAddress: string;
  name: string;
  userRoles: UserRole[];
  userIsAdmin?: boolean;
  userIsInternalUser?: boolean;
  userIsClientUser?: boolean;
  userIsBidDaySupport?: boolean;
}

interface UserInformationContextData {
  userInformation: UserInformation | null;
  updateUserInformation: (newUserInformation: UserInformation | null) => void;
}

const UserInformationContext = React.createContext<UserInformationContextData>({
  userInformation: {
    id: '',
    emailAddress: '',
    name: '',
    userRoles: [],
    userIsAdmin: false,
    userIsInternalUser: false,
    userIsClientUser: false,
    userIsBidDaySupport: false,
  },
  updateUserInformation: (newUserInformation: UserInformation | null) => {},
});

const UserInformationContextProvider: React.FC<{}> = props => {
  const { localStorage } = window;

  const [userInformation, setUserInformation] = useState<UserInformation | null>({
    id: '',
    emailAddress: '',
    name: '',
    userRoles: [],
    userIsAdmin: false,
    userIsInternalUser: false,
    userIsClientUser: false,
  });

  const updateUserInformation = useCallback(
    (newUserInformation: UserInformation | null): void => {
      if (newUserInformation) {
        // NOTE: We cannot reference "userInformation" in this method, or else we will have to add it as a dependency and we will end up in an infinite loop
        // since we update the "userInformation"

        // NOTE: Explicitly set the authorization helper properties in this code only

        newUserInformation.userIsAdmin = isUserAuthorized(
          newUserInformation,
          UserConstants.UserRolesValues.ADMIN,
        );

        newUserInformation.userIsInternalUser = isUserAuthorized(
          newUserInformation,
          UserConstants.UserRolesValues.INTERNALUSER,
        );

        newUserInformation.userIsClientUser = isUserAuthorized(
          newUserInformation,
          UserConstants.UserRolesValues.CLIENTUSER,
        );

        newUserInformation.userIsBidDaySupport = isUserAuthorized(
          newUserInformation,
          UserConstants.UserRolesValues.BIDDAYSUPPORT,
        );

        setUserInformation(newUserInformation);

        localStorage.setItem(LocalStorageKeys.UserInformation, JSON.stringify(newUserInformation));
      } else {
        localStorage.removeItem(LocalStorageKeys.UserInformation);
        setUserInformation(null);
      }
    },
    [localStorage],
  );

  return (
    <UserInformationContext.Provider value={{ userInformation, updateUserInformation }}>
      {props.children}
    </UserInformationContext.Provider>
  );
};

export { UserInformationContext, UserInformationContextProvider };
