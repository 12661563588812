import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import LinksConstants from '../../../../constants/links';
import LocalStorageKeys from '../../../../constants/local-storage';
import ApplicationRoutes from '../../../../constants/routes';
import { AuthenticationService } from '../../../../services/authentication/authentication-service';
import { getConfig } from '../../../../utils/config';
import BTButton from '../../../common-page-components/controls/button/BTButton';
import SvgImage from '../../../common-page-components/svg-image/SvgImage';
import './LoginPage.scss';

const LoginPage: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();

  useEffect(() => {
    const accessTokenFromLocalStorage = localStorage.getItem(LocalStorageKeys.AccessToken);

    if (accessTokenFromLocalStorage) {
      // NOTE: We explicitly replace the history entry, not push onto it
      history.replace(ApplicationRoutes.HOME);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const websiteUrl = getConfig('REACT_APP_BECKTECH_URL') || LinksConstants.BeckTechWebsite;
  const websiteUrlCommunity =
    getConfig('REACT_APP_BECKTECH_COMMUNITY_URL') || LinksConstants.BeckTechCommunityWebsite;
  const estimatorWebsite = getConfig('REACT_APP_ESTIMATOR_URL') || LinksConstants.EstimatorWebsite;

  const getUrlForDisplay = (url: string): string => {
    return url.replace('http://', '');
  };

  const signIn = (): void => {
    AuthenticationService.navigateToSignInOrPasswordReset();
  };

  return (
    <div id="loginPage" data-testid="login-page">
      <div className="container-fluid loginPageBanner">
        <div className="loginPageBannerBackground"></div>

        <div className="container">
          <div className="text-center">
            <h1>{t('general:Welcome to the Beck Technology Licensing Portal')}</h1>
          </div>

          <div className="mt-5 text-center">
            <BTButton text="Sign In" onClick={signIn}></BTButton>
          </div>
        </div>
      </div>

      <div className="container estimatorInfo">
        <div className="d-flex justify-content-center">
          <div>
            <a href={estimatorWebsite} title="DESTINI Estimator">
              <SvgImage image={'estimator'} size={'xl'} />
            </a>
          </div>
          <div className="infoText">
            <div>
              To learn more about DESTINI Estimator and preconstruction lifecycle data please visit
              our website at{' '}
              <a href={websiteUrl} title="Beck Technology">
                {getUrlForDisplay(websiteUrl)}
              </a>
              .
            </div>
            <div className="mt-1">
              If you need assistance with Estimator, visit our online community at{' '}
              <a href={websiteUrlCommunity} title="Beck Technology Community">
                {getUrlForDisplay(websiteUrlCommunity)}
              </a>
              .
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
