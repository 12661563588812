import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './app/App';
import { ConfirmationOverlayContextProvider } from './app/common-page-components/confirmation-overlay/ConfirmationOverlayContext';
import LoadingPageOverlay from './app/common-page-components/loading-page-overlay/LoadingPageOverlay';
import { LoadingPageOverlayContextProvider } from './app/common-page-components/loading-page-overlay/LoadingPageOverlayContext';
import { NotificationPanelContextProvider } from './app/common-page-components/notification-panel/NotificationPanelContext';
import { UserInformationContextProvider } from './app/UserInformationContext';
import './internationalization/setup';
import * as serviceWorker from './registerServiceWorker';

const rootElement = document.getElementById('root');

ReactDOM.render(
  <LoadingPageOverlayContextProvider>
    <NotificationPanelContextProvider>
      <ConfirmationOverlayContextProvider>
        <UserInformationContextProvider>
          <LoadingPageOverlay />

          <App />
        </UserInformationContextProvider>
      </ConfirmationOverlayContextProvider>
    </NotificationPanelContextProvider>
  </LoadingPageOverlayContextProvider>,
  rootElement,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
