const getConfig = (key: string): string | undefined => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const env = (window as any).ENV;
  const configVal = env && env[key];

  if (configVal) {
    return configVal;
  }

  return process.env[key];
};

export { getConfig };
