import React, { useEffect, useCallback } from 'react';
import { ReportNames } from '../../reports';
import { UsageApi } from '../../../../../services/api';
import ReportFilterConstants from '../../../../../constants/reports/filters';

interface Props {
  rawDataReportFilters: ReportFiltersWithIncrement;
}

const RawDataFilter: React.FC<ReportChartFilterProps & Props> = ({
  reportFilters,
  setReportDataSource,
}) => {
  const updateReportDataSource = useCallback((): void => {
    setReportDataSource(ReportNames.RawData, () => {
      return UsageApi.getRawUsage(
        reportFilters.fromDate,
        reportFilters.toDate,
        reportFilters.client,
        reportFilters.product &&
          reportFilters.product.id === ReportFilterConstants.PRODUCTOPTION_ALL_PRODUCTS
          ? null
          : reportFilters.product,
      );
    });
  }, [setReportDataSource, reportFilters]);

  // Set the data source values for this component when the generic report filters provided to this component change
  useEffect(() => {
    updateReportDataSource();
  }, [reportFilters, updateReportDataSource]);

  return <></>;
};

export default RawDataFilter;
