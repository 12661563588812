import AdminRoutes from './admin-routes';
import ErrorRoutes from './error-routes';
import ReportRoutes from './reports-routes';

const SITE_ROOT = '/';
const HOME = '/home';

const ApplicationRoutes = {
  SITE_ROOT,
  HOME,
  ADMIN_CLIENTS: AdminRoutes.Clients,
  ADMIN_PRODUCTS: AdminRoutes.Products,
  ADMIN_LICENSES: AdminRoutes.Licenses,
  ADMIN_USERS: AdminRoutes.Users,
  ADMIN_ESTIMATORHOSTED_USERMANAGEMENT: AdminRoutes.EstimatorHostedUserManagement,
  ADMIN_SITESETTINGS: AdminRoutes.SiteSettings,
  ERROR_ERROR: ErrorRoutes.Error,
  ERROR_PAGENOTFOUND: ErrorRoutes.PageNotFound,
  ERROR_UNAUTHORIZED: ErrorRoutes.Unauthorized,
  REPORTS: ReportRoutes.Base,
  REPORTS_USAGE: ReportRoutes.UsageReport,
  REPORTS_DURATION: ReportRoutes.Duration,
  REPORTS_CHECKOUTS: ReportRoutes.Checkouts,
  REPORTS_RAWDATA: ReportRoutes.RawData,
};

export default ApplicationRoutes;
