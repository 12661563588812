import axios from 'axios';
import LicensesConstants from '../../../constants/licenses';
import ApiRoutes from '../../../constants/routes/api-routes';
import {
  LicenseViewDto,
  LicenseAddDto,
  LicenseEditDto,
  DeploymentRequest,
  getLicenseFromDto,
} from './licenses-api-service-helper';

const getLicenses = async (): Promise<License[]> => {
  const response = await axios.get<LicenseViewDto[]>(ApiRoutes.Licenses);
  const dtos: LicenseViewDto[] = response.data;

  const licenses: License[] = dtos.map(dto => getLicenseFromDto(dto));
  return licenses;
};

const addLicense = async (license: License): Promise<License> => {
  const dto: LicenseAddDto = {
    clientOrganizationId: license.clientOrganization.id,
    productId: license.product.id,
    featureIds: license.product.features.map(f => f.id),
    userCount: license.userCount,
    enforceStrictUserCount: license.enforceStrictUserCount,
    effectiveDate: license.effectiveDate,
    expirationDate: license.expirationDate,
    adminUser: license.adminUser,
  };

  const response = await axios.post<LicenseViewDto>(`${ApiRoutes.Licenses}/${license.id}`, dto);
  const newLicense = getLicenseFromDto(response.data);

  return newLicense;
};

const editLicense = async (license: License): Promise<License> => {
  const dto: LicenseEditDto = {
    clientOrganizationId: license.clientOrganization.id,
    productId: license.product.id,
    featureIds: license.product.features.map(f => f.id),
    userCount: license.userCount,
    enforceStrictUserCount: license.enforceStrictUserCount,
    effectiveDate: license.effectiveDate,
    expirationDate: license.expirationDate,
    adminUser: license.adminUser,
  };

  const response = await axios.put<LicenseViewDto>(`${ApiRoutes.Licenses}/${license.id}`, dto);
  const updatedLicense = getLicenseFromDto(response.data);

  return updatedLicense;
};

const deleteLicense = async (licenseId: string): Promise<void> => {
  await axios.delete(`${ApiRoutes.Licenses}/${licenseId}`);
};

const resetDeploymentDetails = async (licenseId: string): Promise<void> => {
  const dto = {
    id: licenseId,
    deploymentStatus: LicensesConstants.DeploymentStatuses.NONE,
    deploymentDetails: {},
  };
  await axios.put(`${ApiRoutes.Licenses}/update-deployment-details/${licenseId}`, dto);
};

const requestDeployment = async (
  licenseId: string,
  adminUser: string,
  emailDomains: string[],
): Promise<void> => {
  const dto: DeploymentRequest = {
    licenseId: licenseId,
    adminUser: adminUser,
    emailDomains: emailDomains,
  };
  await axios.post(`${ApiRoutes.Licenses}/request-deployment/`, dto);
};

export default {
  getLicenses,
  addLicense,
  editLicense,
  deleteLicense,
  resetDeploymentDetails,
  requestDeployment,
};
