import { Switch } from '@progress/kendo-react-inputs';
import { Field, FieldProps } from 'formik';
import _ from 'lodash';
import React from 'react';
import './Forms.scss';

interface Props {
  label: string;
  name: string;
  validator?: (value: string) => string | void;
}

const WrappedFormSwitch: React.FC<FieldProps & Props> = ({ field, form, label, ...rest }) => {
  const errors = _.get(form.errors, field.name);
  const hasErrors = !!errors;

  const classNames = ['formInput'];
  if (hasErrors) {
    classNames.push('formInputError');
  }

  return (
    <>
      <div>
        <label htmlFor={field.name}>{label}</label>
      </div>
      <Switch
        className={classNames.join(' ')}
        {...field}
        {...rest}
        onChange={(newValue): void => {
          form.setFieldValue(field.name, newValue.value);
        }}
        checked={field.value}
      />
    </>
  );
};

const FormSwitch: React.FC<Props> = props => {
  return (
    <div>
      <Field
        id={props.name}
        name={props.name}
        validate={props.validator}
        label={props.label}
        component={WrappedFormSwitch}
        {...props}
      />
    </div>
  );
};

export default FormSwitch;
