import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { clone, flatten, groupBy, sortBy } from 'lodash';
import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { Collapse, Input } from 'reactstrap';
import './BTCardsContainer.scss';

export interface BTCardRegistrationInfo {
  cardId: string;
  metadata: string[];
  sortValue: string;
  groupValue?: string;
  cardElement: ReactElement;
}

const BTCardsContainerContext = React.createContext({
  registerCard: (cardRegistrationInfo: BTCardRegistrationInfo) => {},
  unregisterCard: (cardId: string) => {},
});

interface Props {
  children?: ReactElement[];
  enableSearch?: boolean;
  numberOfCardsPerRow?: number;
  recordsNotFoundMessage?: string;
}

interface GroupedReactElements {
  groupKey: string;
  reactElements: ReactElement[];
  isOpen: boolean;
}

const BTCardsContainer: React.FC<Props> = props => {
  const [searchText, setSearchText] = useState<string>('');

  const [filteredCards, setFilteredCards] = useState<GroupedReactElements[]>([]);

  const [allCards, setAllCards] = useState<BTCardRegistrationInfo[]>([]);

  const registerCard = useCallback((cardRegistrationInfo: BTCardRegistrationInfo): void => {
    setAllCards(existingCards => {
      if (existingCards.some(v => v.cardId === cardRegistrationInfo.cardId)) {
        throw new Error(
          `The card with ID ${cardRegistrationInfo.cardId} has already been registered.`,
        );
      }

      return [...existingCards, cardRegistrationInfo];
    });
  }, []);

  const unregisterCard = useCallback((cardId: string): void => {
    setAllCards(existingCards => {
      const indexOfCardToUnregister = existingCards.findIndex(v => v.cardId === cardId);

      if (indexOfCardToUnregister > -1) {
        const copyOfArray = [...existingCards];
        copyOfArray.splice(indexOfCardToUnregister, 1);
        return copyOfArray;
      }

      return existingCards;
    });
  }, []);

  let columnsCssClass = '';
  switch (props.numberOfCardsPerRow) {
    case 1:
      columnsCssClass = 'col-lg-12';
      break;
    case 3:
      columnsCssClass = 'col-lg-4';
      break;
    case 4:
      columnsCssClass = 'col-lg-3';
      break;
    case 2:
    default:
      columnsCssClass = 'col-lg-6';
      break;
  }

  const groupCards = useCallback((cards: BTCardRegistrationInfo[]): GroupedReactElements[] => {
    return sortBy(
      Object.values(groupBy(cards, v => (v.groupValue || '').toLowerCase())),
      v => v[0].groupValue,
    ).map(
      (v): GroupedReactElements => {
        return {
          groupKey: v[0].groupValue || '',
          reactElements: sortBy(v, m => m.sortValue).map(m => m.cardElement),
          isOpen: true,
        };
      },
    );
  }, []);

  const setFilteredCardsArray = useCallback(
    (newSearchText: string): void => {
      if (!newSearchText) {
        const groupedCards = groupCards(allCards);
        setFilteredCards(groupedCards);
        return;
      }

      const filteredCardsArray = allCards.filter(currCardData => {
        const cardMetadata = currCardData.metadata;

        if (cardMetadata && Array.isArray(cardMetadata)) {
          const flattenedCardMetadata = cardMetadata.join('').toLowerCase();
          return flattenedCardMetadata.indexOf(newSearchText.toLowerCase()) > -1;
        }

        return false;
      });

      const groupedCards = groupCards(filteredCardsArray);
      setFilteredCards(groupedCards);
    },
    [allCards, groupCards],
  );

  const getCardsCountString = useCallback((): string => {
    return `Displaying ${flatten(filteredCards.map(v => v.reactElements)).length} of ${
      allCards.length
    }`;
  }, [allCards, filteredCards]);

  // Update the cards when the search text changes
  useEffect(() => {
    let filteredCardsTimeout: NodeJS.Timeout;

    const cleanedUpSearchText = searchText.trim();

    // Delay the filtering until the user stops typing for a reasonable amount of time so it doesn't flicker with a larger collection of cards
    if (cleanedUpSearchText.length > 0) {
      filteredCardsTimeout = setTimeout(() => {
        setFilteredCardsArray(cleanedUpSearchText);
      }, 250);
    } else {
      setFilteredCardsArray(cleanedUpSearchText);
    }

    return (): void => {
      clearTimeout(filteredCardsTimeout);
    };
  }, [searchText, setFilteredCardsArray]);

  return (
    <BTCardsContainerContext.Provider value={{ registerCard, unregisterCard }}>
      <div className="btCardContainer">
        {props.enableSearch ? (
          <div className="searchTextArea">
            <div className="inputContainer">
              <Input
                autoFocus
                type="text"
                className="input"
                placeholder={'Search'}
                onChange={(e): void => {
                  const newSearchText = e.target.value;
                  setSearchText(newSearchText);
                }}
                value={searchText}
              />

              <div className="iconContainer">
                <FontAwesomeIcon icon="search" />
              </div>
            </div>

            {allCards.length && (
              <div className="recordsCountLabel">
                <label>{getCardsCountString()}</label>
              </div>
            )}
          </div>
        ) : (
          <></>
        )}

        {filteredCards.length ? (
          <div>
            {filteredCards.map((v, i) => {
              return (
                <div key={i}>
                  {v.groupKey && (
                    <h2
                      className="cardGroupLabel"
                      onClick={(): void => {
                        // Make a copy of the cards before updating the open status of the clicked item, then update the state
                        const indexOfMatchingCard = filteredCards.findIndex(
                          m => m.groupKey === v.groupKey,
                        );
                        if (indexOfMatchingCard > -1) {
                          const copyOfFilteredCards = clone(filteredCards);
                          copyOfFilteredCards[indexOfMatchingCard].isOpen = !copyOfFilteredCards[
                            indexOfMatchingCard
                          ].isOpen;
                          setFilteredCards(copyOfFilteredCards);
                        }
                      }}
                    >
                      <span className={'chevronIcon ' + (v.isOpen ? 'isRotated' : '')}>
                        <FontAwesomeIcon icon="chevron-down" />
                      </span>
                      {v.groupKey} &bull; {v.reactElements.length}
                    </h2>
                  )}

                  <Collapse isOpen={v.isOpen}>
                    <div className="row">
                      {v.reactElements.map((v2, i2) => (
                        <div className={['col-12 col-md-6 ', columnsCssClass].join(' ')} key={i2}>
                          {v2}
                        </div>
                      ))}
                    </div>
                  </Collapse>
                </div>
              );
            })}
          </div>
        ) : (
          <></>
        )}

        {(!allCards.length || !filteredCards.length) && (
          <div className="noRecordsFoundMessage">
            {props.recordsNotFoundMessage || 'No records were found.'}
          </div>
        )}
      </div>

      {/* 
      Render the children but don't show them. Any BTCard component that gets rendered anywhere inside of this component will register 
      itself with the provided context from this component and we will display them above after applying any applicable filtering.
      */}
      <div style={{ display: 'none' }}>{props.children}</div>
    </BTCardsContainerContext.Provider>
  );
};

export default BTCardsContainer;

export { BTCardsContainerContext };
