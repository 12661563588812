import React from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { nameof } from '../../../../../utils/nameof';
import { FieldArray, Form, Formik } from 'formik';
import FormFooter from '../../../../common-page-components/forms/FormFooter';
import BTButton from '../../../../common-page-components/controls/button/BTButton';
import Yup from '../../../../../utils/yup';
import FormDropDownList from '../../../../common-page-components/forms/FormDropDownList';
import FormMultiSelect from '../../../../common-page-components/forms/FormMultiSelect';
import FormErrorContainer from '../../../../common-page-components/forms/form-error-container/FormErrorContainer';
import { getFormErrorMessages } from '../../../../../utils/forms';
import BTIconButton from '../../../../common-page-components/controls/icon-button/BTIconButton';
import FormTextInput from '../../../../common-page-components/forms/FormTextInput';
import { isEqual } from 'lodash';

interface FormData {
  clientOrg: ClientOrganization | null;
  userGroups: EstimatorHostedUserGroup[];
  names: string[];
}

interface Props {
  clientOrgs: ClientOrganization[];
  userGroups: EstimatorHostedUserGroup[];
  onSaveClick: (users: EstimatorHostedUser[]) => Promise<void>;
  onDiscardClick: (users: EstimatorHostedUser[]) => void;
}

const AddUsersModal: React.FC<Props> = props => {
  const onSaveClick = (formData: FormData): void => {
    const users = getUsersFromFormData(formData);
    props.onSaveClick(users);
  };

  const onDiscardClick = (formData: FormData): void => {
    if (isEqual(formData, initialFormData)) {
      props.onDiscardClick([]);
    } else {
      const users = getUsersFromFormData(formData);
      props.onDiscardClick(users);
    }
  };

  return (
    <Modal isOpen={true} size="lg">
      <ModalHeader className="justify-content-center">Add Users</ModalHeader>
      <ModalBody>
        <Formik
          initialValues={initialFormData}
          onSubmit={onSaveClick}
          validationSchema={FormSchema}
        >
          {({ errors, values, submitCount }): JSX.Element => (
            <Form>
              <div className="container-fluid">
                <div className="row">
                  <div className="col">
                    <FormDropDownList
                      label="Client Organization"
                      name={nameof<FormData>('clientOrg')}
                      data={props.clientOrgs.map(clientOrg => ({
                        name: clientOrg.name,
                        value: clientOrg,
                      }))}
                    />
                  </div>

                  <div className="col">
                    <FormMultiSelect
                      label="Groups"
                      placeholderText="Select groups..."
                      name={nameof<FormData>('userGroups')}
                      data={props.userGroups.map(group => ({
                        id: group.id,
                        name: group.displayName,
                      }))}
                    />
                  </div>
                </div>

                <FieldArray name={nameof<FormData>('names')}>
                  {({ push, remove }): JSX.Element => (
                    <div>
                      <div className="d-flex align-items-center mt-3 ">
                        <div>Names</div>
                        <div className="ml-3">
                          <BTIconButton
                            tooltip="Add Names"
                            icon="plus"
                            onClick={(): void => push('')}
                            tabIndex={-1}
                          />
                        </div>
                      </div>

                      <div className="row">
                        {values.names.map((_, index) => {
                          const name = `${nameof<FormData>('names')}.${index}`;
                          const colClassNames =
                            values.names.length === 1 ? 'col-12' : 'col-lg-6 col-md-12';

                          return (
                            <div key={name} className={`d-flex align-items-end ${colClassNames}`}>
                              <div className="flex-grow-1">
                                <FormTextInput label="Name" name={name} />
                              </div>
                              <div className="ml-3 mb-1">
                                <BTIconButton
                                  tooltip="Remove"
                                  icon="trash"
                                  onClick={(): void => remove(index)}
                                  tabIndex={-1}
                                />
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </FieldArray>
              </div>

              <FormFooter>
                <div className="d-flex mt-4">
                  <BTButton text="Save" type="submit" />
                  <div className="ml-3">
                    <BTButton
                      text="Discard"
                      color="gray"
                      onClick={(): void => onDiscardClick(values)}
                    />
                  </div>
                </div>
                {submitCount > 0 && (
                  <FormErrorContainer errorMessages={getFormErrorMessages(errors)} />
                )}
              </FormFooter>
            </Form>
          )}
        </Formik>
      </ModalBody>
    </Modal>
  );
};

const initialFormData: FormData = {
  clientOrg: null,
  userGroups: [],
  names: [],
};

const getUsersFromFormData = (formData: FormData): EstimatorHostedUser[] => {
  const groupIds = formData.userGroups.map(group => group.id);
  const clientOrgId = formData.clientOrg ? formData.clientOrg.id : '';
  const users: EstimatorHostedUser[] = formData.names.map(name => ({
    displayName: name,
    emailAddress: '',
    clientOrganizationId: clientOrgId,
    groupIds: groupIds,
  }));
  return users;
};

const FormSchema = Yup.object().shape<FormData>({
  clientOrg: Yup.object()
    .shape<ClientOrganization>({
      id: Yup.string(),
      name: Yup.string(),
      address: Yup.object().shape<Address>({
        streetAddress: Yup.string(),
        aptSuiteBldg: Yup.string(),
        city: Yup.string(),
        stateOrRegion: Yup.object().shape<ClientRegion>({
          id: Yup.number().nullable(),
          name: Yup.string(),
          countryId: Yup.number(),
        }),
        postalCode: Yup.string(),
        country: Yup.object().shape<ClientCountry>({
          id: Yup.number().nullable(),
          name: Yup.string(),
        }),
      }),
      accountExecutive: Yup.string(),
      isNew: Yup.boolean(),
      domains: Yup.array().of(Yup.string()),
      usesCorporateActiveDirectory: Yup.boolean(),
    })
    .required('Client Organization is required'),
  userGroups: Yup.array().of(
    Yup.object().shape<EstimatorHostedUserGroup>({
      id: Yup.string(),
      displayName: Yup.string(),
    }),
  ),
  names: Yup.array().of(Yup.string().required('Name is required')),
});

export default AddUsersModal;
