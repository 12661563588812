import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import ApplicationRoutes from '../../../../../constants/routes';
import { AuthenticationService } from '../../../../../services/authentication/authentication-service';
import { isDevelopmentEnvironment } from '../../../../../utils/general';
import { LoadingPageOverlayContext } from '../../../../common-page-components/loading-page-overlay/LoadingPageOverlayContext';
import SvgImage from '../../../../common-page-components/svg-image/SvgImage';

const UnauthorizedPage: React.FC = () => {
  const history = useHistory();
  const { showPageLoadingOverlay, hidePageLoadingOverlay } = useContext(LoadingPageOverlayContext);

  const [pageIsDisplayed, setPageIsDisplayed] = useState<boolean>(false);

  useEffect(() => {
    // If the user is logged in, we want to redirect them to the home page
    const checkUserAuthenticationStatus = async (): Promise<void> => {
      try {
        showPageLoadingOverlay();

        const accessTokenWasRetrieved = await AuthenticationService.getAccessToken();
        if (accessTokenWasRetrieved) {
          history.push(ApplicationRoutes.HOME);
          return;
        }

        // Display the page as usual if the user is not logged in
        setPageIsDisplayed(true);
      } catch {
        // Display the page as usual if an error occurred
        setPageIsDisplayed(true);
      } finally {
        hidePageLoadingOverlay();
      }
    };

    checkUserAuthenticationStatus();
  }, [showPageLoadingOverlay, hidePageLoadingOverlay, history]);

  if (!pageIsDisplayed) {
    return <></>;
  }

  return (
    <div className="errorPage">
      <div className="container">
        <div className="errorMessageArea">
          <div className="errorImage text-center">
            <SvgImage size="xl" image="stop" />
          </div>
        </div>

        <div className="mt-4 text-center">
          <h1>We're sorry, but you are not authorized to view the requested page.</h1>
        </div>

        {isDevelopmentEnvironment ? (
          <div className="d-flex justify-content-center my-5">
            <img
              className="img-fluid shadow rounded-lg pointer"
              src="/images/error/Unauthorized.jpg"
              alt="Error"
              onClick={(): void => history.push(ApplicationRoutes.SITE_ROOT)}
              title="Click here to go home, Forrest"
            />
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default UnauthorizedPage;
