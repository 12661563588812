import React from 'react';
import './FormErrorContainer.scss';
import { sortBy } from 'lodash';

interface Props {
  errorMessages: string[];
}

const FormErrorContainer: React.FC<Props> = props => {
  const containerCssClass = props.errorMessages && props.errorMessages.length ? 'isDisplayed' : '';
  const uniqueErrorMessages = sortBy(Array.from(new Set(props.errorMessages)), v => v);

  return (
    <div className={['formErrorContainer', containerCssClass].join(' ')}>
      <div className="errorMessagesContainer">
        {uniqueErrorMessages.map((currErrorMessage, i) => {
          return (
            <div className="errorMessage" key={i}>
              {currErrorMessage}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default FormErrorContainer;
