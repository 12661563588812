const REPORTS_ROOT = 'reports';

const ReportRoutes = {
  Base: ['', REPORTS_ROOT].join('/'),
  UsageReport: ['', REPORTS_ROOT, 'usage'].join('/'),
  Checkouts: ['', REPORTS_ROOT, 'checkouts'].join('/'),
  Duration: ['', REPORTS_ROOT, 'duration'].join('/'),
  RawData: ['', REPORTS_ROOT, 'raw-data'].join('/'),
};

export default ReportRoutes;
