import { getConfig } from '../../utils/config';

const API_URL = getConfig('REACT_APP_API_URL');

const ApiRoutes = {
  Clients: [API_URL, 'api', 'clientorganizations'].join('/'),
  ClientByEmail: [API_URL, 'api', 'clientorganizations', 'by-email'].join('/'),
  Products: [API_URL, 'api', 'products'].join('/'),
  ProductsByClient: [API_URL, 'api', 'products', 'by-client-id'].join('/'),
  Licenses: [API_URL, 'api', 'licenses'].join('/'),
  Users: [API_URL, 'api', 'users'].join('/'),
  EstimatorHostedUsers: [API_URL, 'api', 'estimator-hosted', 'users'].join('/'),
  EstimatorHostedGroups: [API_URL, 'api', 'estimator-hosted', 'groups'].join('/'),
  Usage: [API_URL, 'api', 'usage'].join('/'),
  Raw: [API_URL, 'api', 'usage', 'raw'].join('/'),
  UsageByDay: [API_URL, 'api', 'usage', 'by-day'].join('/'),
  UsageByWeek: [API_URL, 'api', 'usage', 'by-week'].join('/'),
  UsageByMonth: [API_URL, 'api', 'usage', 'by-month'].join('/'),
  DurationByDay: [API_URL, 'api', 'usage', 'duration-by-day'].join('/'),
  DurationByWeek: [API_URL, 'api', 'usage', 'duration-by-week'].join('/'),
  DurationByMonth: [API_URL, 'api', 'usage', 'duration-by-month'].join('/'),
};

export default ApiRoutes;
