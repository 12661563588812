import React, { useState, useCallback } from 'react';

interface PageLoadingOverlayDisplayInfo {
  isDisplayed: boolean;
  hasBeenToggled: boolean;
}

const LoadingPageOverlayContext = React.createContext({
  pageLoadingOverlayDisplayInfo: {
    isDisplayed: false,
    hasBeenToggled: false,
  },
  showPageLoadingOverlay: () => {},
  hidePageLoadingOverlay: () => {},
});

const LoadingPageOverlayContextProvider: React.FC<{}> = props => {
  const [pageLoadingOverlayDisplayInfo, setPageLoadingOverlayDisplayInfo] = useState<
    PageLoadingOverlayDisplayInfo
  >({
    isDisplayed: false,
    hasBeenToggled: false,
  });

  const showPageLoadingOverlay = useCallback((): void => {
    setPageLoadingOverlayDisplayInfo({
      isDisplayed: true,
      hasBeenToggled: true,
    });
  }, []);

  const hidePageLoadingOverlay = useCallback((): void => {
    setPageLoadingOverlayDisplayInfo({
      isDisplayed: false,
      hasBeenToggled: true,
    });
  }, []);

  return (
    <LoadingPageOverlayContext.Provider
      value={{ pageLoadingOverlayDisplayInfo, showPageLoadingOverlay, hidePageLoadingOverlay }}
    >
      {props.children}
    </LoadingPageOverlayContext.Provider>
  );
};

export { LoadingPageOverlayContext, LoadingPageOverlayContextProvider };
