import React, { RefObject } from 'react';
import { GridCellProps } from '@progress/kendo-react-grid';
import { DropDownList, DropDownListChangeEvent } from '@progress/kendo-react-dropdowns';

interface Props extends GridCellProps {
  listAnchor: RefObject<HTMLElement>;
  required?: boolean;
  listValues: any[]; // eslint-disable-line @typescript-eslint/no-explicit-any
  textField?: string;
  dataItemKey?: string;
  valueField?: string;
}

const GridDropdownCell: React.FC<Props> = props => {
  const onChange = (event: DropDownListChangeEvent): void => {
    if (props.onChange) {
      props.onChange({
        ...props,
        value: props.valueField ? event.target.value[props.valueField] : event.target.value,
        syntheticEvent: event.syntheticEvent,
      });
    }
  };

  const value = props.field ? props.dataItem[props.field] : '';

  return (
    <td>
      <DropDownList
        data={props.listValues}
        textField={props.textField}
        dataItemKey={props.dataItemKey}
        value={
          props.valueField
            ? props.listValues.find(listValue => listValue[props.valueField!] === value)
            : value
        }
        onChange={onChange}
        required={props.required}
        popupSettings={{ appendTo: props.listAnchor.current || undefined }}
      />
    </td>
  );
};

export default GridDropdownCell;
