import React from 'react';
import { Route, Switch } from 'react-router';
import ApplicationRoutes from '../../../../constants/routes';
import LicenseUsageFilter from '../charts/LicenseUsage/LicenseUsageFilter';
import LicenseDurationFilter from '../charts/LicenseDuration/LicenseDurationFilter';
import LicenseCheckoutsFilter from '../charts/LicenseCheckouts/LicenseCheckoutsFilter';
import { ReportNames } from '../reports';
import RawDataFilter from '../charts/RawData/RawDataFilter';

interface Props {
  allSpecificReportFilters: Dictionary<SpecificReportFiltersBase>;
}

const ReportsPageCustomFilters: React.FC<ReportChartFilterProps & Props> = props => {
  return (
    <Switch>
      <Route
        exact
        path={ApplicationRoutes.REPORTS_USAGE}
        render={(): JSX.Element => (
          <LicenseUsageFilter
            usageReportFilters={props.allSpecificReportFilters[ReportNames.Usage]}
            {...props}
          />
        )}
      />
      <Route
        exact
        path={ApplicationRoutes.REPORTS_CHECKOUTS}
        render={(): JSX.Element => (
          <LicenseCheckoutsFilter
            checkoutsReportFilters={props.allSpecificReportFilters[ReportNames.Checkouts]}
            {...props}
          />
        )}
      />
      <Route
        exact
        path={ApplicationRoutes.REPORTS_DURATION}
        render={(): JSX.Element => (
          <LicenseDurationFilter
            durationReportFilters={props.allSpecificReportFilters[ReportNames.Duration]}
            {...props}
          />
        )}
      />
      <Route
        exact
        path={ApplicationRoutes.REPORTS_RAWDATA}
        render={(): JSX.Element => (
          <RawDataFilter
            rawDataReportFilters={props.allSpecificReportFilters[ReportNames.RawData]}
            {...props}
          />
        )}
      />
    </Switch>
  );
};

export default ReportsPageCustomFilters;
