const LicensesConstants = {
  DeploymentStatuses: {
    COMPLETED: 'Completed',
    NONE: 'None',
    IN_PROGRESS: 'InProgress',
    ERROR: 'Error',
    REQUESTED: 'Requested',
  },
  DeploymentStatusesDisplayNames: {
    COMPLETED: 'Completed',
    NONE: 'No Deployment Run',
    IN_PROGRESS: 'In Progress',
    ERROR: 'Error',
    REQUESTED: 'Deployment Requested',
  },
};

export default LicensesConstants;
