import {
  Chart,
  ChartCategoryAxis,
  ChartLegend,
  ChartSeries,
  ChartSeriesItem,
  ChartTitle,
  ChartTooltip,
} from '@progress/kendo-react-charts';
import React from 'react';
import ChartDefaults from '../../../../../constants/charts';
import { defaultTooltipRender, getChartCategoryAxisItem } from '../../../../../utils/charts';

const LicenseCheckoutsChart: React.FC<ReportChartProps> = props => {
  const {
    data: {
      chartSeries,
      appliedFilters: { specificFilters },
    },
  } = props;

  const checkoutFilters = specificFilters as ReportFiltersWithIncrement;

  return (
    <div data-testid="license-checkouts-chart">
      <Chart>
        <ChartTooltip render={defaultTooltipRender} />
        <ChartTitle text={`Total Checkouts Per ${checkoutFilters.increment}`} />
        <ChartLegend position="top" orientation="horizontal" />
        <ChartSeries>
          {chartSeries.map((cs, idx) => {
            return (
              <ChartSeriesItem
                key={idx}
                type="column"
                name={cs.name}
                field="value"
                aggregate={'sum'}
                categoryField="label"
                data={cs.data}
                color={
                  cs.name === 'Automatic' ? ChartDefaults.colors.blue : ChartDefaults.colors.red
                }
              />
            );
          })}
        </ChartSeries>
        <ChartCategoryAxis>{getChartCategoryAxisItem(checkoutFilters.increment)}</ChartCategoryAxis>
      </Chart>
    </div>
  );
};

export default LicenseCheckoutsChart;
