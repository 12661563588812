import React, { useCallback, useState } from 'react';
import NotificationPanel from './NotificationPanel';

export interface NotificationPanelMessage {
  title: string;
  message: string;
  type: NotificationPanelTypes;
}

export enum NotificationPanelTypes {
  Success = 1,
  Error = 2,
  Info = 3,
}

const NotificationPanelContext = React.createContext({
  popFirstQueuedNotification: (): NotificationPanelMessage | null => null,
  showSuccessNotification: (title: string, message: string) => {},
  showErrorNotification: (title: string, message: string) => {},
  showInfoNotification: (title: string, message: string) => {},
});

const NotificationPanelContextProvider: React.FC<{}> = props => {
  const [queuedNotifications, setQueuedNotifications] = useState<NotificationPanelMessage[]>([]);

  const addNotificationToQueue = useCallback(
    (title: string, message: string, type: NotificationPanelTypes): void => {
      // Use the state updater callback pattern so we don't have any dependencies for useCallback
      setQueuedNotifications(existingNotifications => {
        // Do not allow duplicate messages
        if (
          existingNotifications.some(
            v => v.type === type && v.title === title && v.message === message,
          )
        ) {
          return existingNotifications;
        }

        return [
          ...existingNotifications,
          {
            title: title,
            message: message,
            type: type,
          },
        ];
      });
    },
    [],
  );

  const popFirstQueuedNotification = useCallback((): NotificationPanelMessage | null => {
    if (queuedNotifications.length > 0) {
      // NOTE: Shift() mutates the array so we want to make a copy of the array and mutate that, and then set the state
      const copyOfQueuedNotifications = [...queuedNotifications];
      const firstElementInArray = copyOfQueuedNotifications.shift() as NotificationPanelMessage;
      setQueuedNotifications(copyOfQueuedNotifications);
      return firstElementInArray;
    }

    return null;
  }, [queuedNotifications]);

  const showSuccessNotification = useCallback(
    (title: string, message: string): void => {
      addNotificationToQueue(title, message, NotificationPanelTypes.Success);
    },
    [addNotificationToQueue],
  );

  const showErrorNotification = useCallback(
    (title: string, message: string): void => {
      addNotificationToQueue(title, message, NotificationPanelTypes.Error);
    },
    [addNotificationToQueue],
  );

  const showInfoNotification = useCallback(
    (title: string, message: string): void => {
      addNotificationToQueue(title, message, NotificationPanelTypes.Info);
    },
    [addNotificationToQueue],
  );

  return (
    <>
      <NotificationPanelContext.Provider
        value={{
          popFirstQueuedNotification,
          showSuccessNotification,
          showErrorNotification,
          showInfoNotification,
        }}
      >
        <NotificationPanel queuedNotifications={queuedNotifications} />

        {props.children}
      </NotificationPanelContext.Provider>
    </>
  );
};

export { NotificationPanelContext, NotificationPanelContextProvider };
