// https://stackoverflow.com/a/2117523
const uuidv4 = (): string => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (([1e7] as any) + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c: any) =>
    (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16),
  );
};

const generateUuid = (): string => uuidv4();

// https://stackoverflow.com/a/13653180/3661319
const isValidUuid = (uuid: string): boolean => {
  const regex = new RegExp(
    /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i,
  );

  return regex.test(uuid);
};

export { generateUuid, isValidUuid };
