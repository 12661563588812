import { Increments } from './increments';
import { ProductOptions } from './products';

const ReportFilterConstants = {
  FILTERINCREMENT_DAY: Increments.Day,
  FILTERINCREMENT_WEEK: Increments.Week,
  FILTERINCREMENT_MONTH: Increments.Month,
  FILTERINCREMENT_DEFAULT_INCREMENTS: [Increments.Day, Increments.Week, Increments.Month],
  PRODUCTOPTION_ALL_PRODUCTS: ProductOptions.All,
  PRODUCTOPTION_NO_PRODUCTS: ProductOptions.None,
};

export default ReportFilterConstants;
