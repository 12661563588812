import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faChartArea,
  faChevronDown,
  faClock,
  faClone,
  faCog,
  faCopy,
  faDatabase,
  faEdit,
  faEye,
  faEyeSlash,
  faFileAlt,
  faIdCard,
  faKey,
  faLightbulb,
  faPlus,
  faSave,
  faSearch,
  faServer,
  faShoppingBasket,
  faSignOutAlt,
  faTable,
  faTimes,
  faTrash,
  faUndo,
  faUsers,
  faWrench,
} from '@fortawesome/free-solid-svg-icons';
import { ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { IntlProvider } from '@progress/kendo-react-intl';
import { createBrowserHistory } from 'history';
import i18n from 'i18next';
import * as moment from 'moment';
import * as Msal from 'msal';
import { default as React, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { BrowserRouter, useHistory, useLocation } from 'react-router-dom';
import LocalStorageKeys from '../constants/local-storage';
import ApplicationRoutes from '../constants/routes';
import UserConstants from '../constants/users';
import axiosMiddleware from '../extensions/axiosMiddleware';
import { DEFAULT_LANG } from '../internationalization/i18n';
import usersApiService from '../services/api/users/users-api-service';
import {
  AuthenticationService,
  SSOError,
  SSOErrorCode,
} from '../services/authentication/authentication-service';
import '../styles/kendo/all.css';
import '../styles/kendo/variables.scss';
import { getConfig } from '../utils/config';
import { populateUserInformationFromLocalStorage } from '../utils/user-authorization';
import Layout from './common-page-components/layout/Layout';
import { LoadingPageOverlayContext } from './common-page-components/loading-page-overlay/LoadingPageOverlayContext';
import { NotificationPanelContext } from './common-page-components/notification-panel/NotificationPanelContext';
import AuthenticatedRoute from './common-page-components/routes/AuthenticatedRoute';
import ClientsPage from './pages/admin/clients/ClientsPage';
import LicensesPage from './pages/admin/licenses/LicensesPage';
import ProductsPage from './pages/admin/products/ProductsPage';
import SiteSettingsPage from './pages/admin/settings/SiteSettingsPage';
import UsersPage from './pages/admin/users/UsersPage';
import EstimatorHostedUserManagementPage from './pages/admin/hosted-estimator-user-management/EstimatorHostedUserManagementPage';
import GenericErrorPage from './pages/common/error/generic-error/GenericErrorPage';
import PageNotFoundPage from './pages/common/error/page-not-found/PageNotFoundPage';
import UnauthorizedPage from './pages/common/error/unauthorized/UnauthorizedPage';
import HomePage from './pages/common/home/HomePage';
import LoginPage from './pages/common/login/LoginPage';
import ReportsPage from './pages/reports/ReportsPage';
import { UserInformationContext } from './UserInformationContext';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href') || '/';
const browserHistory = createBrowserHistory({ basename: baseUrl });

const reactPlugin = new ReactPlugin();

const instrumentationKey = getConfig('REACT_APP_APPINSIGHTS_INSTRUMENTATIONKEY');

if (instrumentationKey) {
  const appInsights = new ApplicationInsights({
    config: {
      instrumentationKey: instrumentationKey,
      extensions: [reactPlugin],
      extensionConfig: {
        [reactPlugin.identifier]: { history: browserHistory },
      },
    },
  });

  appInsights.loadAppInsights();
}

const App: React.FC = () => {
  const [locale, setLocale] = useState<string>(DEFAULT_LANG);
  const { updateUserInformation } = useContext(UserInformationContext);
  const { showSuccessNotification, showErrorNotification } = useContext(NotificationPanelContext);
  const history = useHistory();
  const location = useLocation();

  const { showPageLoadingOverlay, hidePageLoadingOverlay } = useContext(LoadingPageOverlayContext);

  // Assume this is true so we don't display anything until we are sure the URL doesn't contain SSO hash data
  const [urlContainsSSOHashData, setUrlContainsSSOHashData] = useState<boolean>(true);

  const [allInitialDataIsLoaded, setAllInitialDataIsLoaded] = useState<boolean>(false);
  const [passwordResetInProgress, setPasswordResetInProgress] = useState<boolean>(false);
  const lastLocationValue = useRef('');

  // Set tab title
  useEffect(() => {
    const envName = (getConfig('REACT_APP_ENVIRONMENT') || '').toUpperCase();
    document.title = envName.includes('PROD')
      ? 'DESTINI Licensing'
      : `DESTINI Licensing - ${envName}`;
  }, []);

  useEffect(() => {
    const checkUserAuthenticationStatus = async (): Promise<void> => {
      // Since this can fire several times because of its dependencies, we only want to take action when the location changes
      if (lastLocationValue.current === location.pathname) {
        return;
      }

      const ssoPasswordResetCompleted = localStorage.getItem(
        LocalStorageKeys.SSOPasswordResetCompleted,
      );
      let authTokenFromLocalStorage = localStorage.getItem(LocalStorageKeys.AuthToken);
      let accessTokenFromLocalStorage = localStorage.getItem(LocalStorageKeys.AccessToken);

      const validateTokens = (): void => {
        // If the expiration date is not valid for the auth token or the access token, do some housekeeping and clear them out
        if (
          authTokenFromLocalStorage &&
          !AuthenticationService.isTokenExpirationValid(authTokenFromLocalStorage)
        ) {
          authTokenFromLocalStorage = null;
          localStorage.removeItem(LocalStorageKeys.AuthToken);
        }

        if (
          accessTokenFromLocalStorage &&
          !AuthenticationService.isTokenExpirationValid(accessTokenFromLocalStorage)
        ) {
          accessTokenFromLocalStorage = null;
          localStorage.removeItem(LocalStorageKeys.AccessToken);
        }
      };

      validateTokens();

      const logUserOutAndClearInformation = (): void => {
        updateUserInformation(null);
        AuthenticationService.logout();
      };

      const handleSSOErrors = (): boolean => {
        const ssoErrorObj = localStorage.getItem(LocalStorageKeys.SSOError);
        if (ssoErrorObj) {
          const ssoError: SSOError = JSON.parse(ssoErrorObj);
          localStorage.removeItem(LocalStorageKeys.SSOError);
          showErrorNotification('Error', ssoError.message);

          switch (ssoError.code) {
            case SSOErrorCode.OtherError:
              history.push(ApplicationRoutes.ERROR_ERROR);
              break;
            case SSOErrorCode.LicensingAppSSOError:
              history.push(ApplicationRoutes.ERROR_UNAUTHORIZED);
              break;
          }
          return true;
        }

        return false;
      };

      // When the user initiates a password reset flow, they get redirected to an SSO page, then back to our app with query string parameters.
      // The MSAL library then runs and sets local storage flags, which we then have to check and redirect the user again to a different SSO page.
      // This code will check the local storage flags and take whatever actions it needs to based on their values.
      const handleSSOPasswordResetLogic = (): boolean => {
        const ssoPasswordResetInitiated = localStorage.getItem(
          LocalStorageKeys.SSOPasswordResetInitiated,
        );

        // We conditionally control the AuthenticationService flow based on whether the ssoPasswordResetInitiated flag is set.
        // While both flags below should never be set at the same time, if we do end up in this state we need to wipe out user information and local storage.
        if (ssoPasswordResetInitiated && ssoPasswordResetCompleted) {
          logUserOutAndClearInformation();
          return true;
        }
        // If only ssoPasswordResetInitiated is set, we initialize the service to a password-reset flow and redirect the user to a password reset screen
        else if (ssoPasswordResetInitiated) {
          localStorage.removeItem(LocalStorageKeys.SSOPasswordResetInitiated);
          setPasswordResetInProgress(true);
          AuthenticationService.initializePasswordReset();
          AuthenticationService.navigateToSignInOrPasswordReset();
          return true;
        } else if (ssoPasswordResetCompleted) {
          showSuccessNotification(
            'Success',
            'Your password was changed successfully.  Please log in using your new credentials.',
          );
        }

        return false;
      };

      // In some instances, if we fail to get an access token, we set an error message in local storage and then log the user out via the MSAL library,
      // which involves some redirects. Once we get back to our app, we want to show the user the error message.
      const handleAccessTokenError = (): void => {
        const accessTokenError = localStorage.getItem(LocalStorageKeys.AccessTokenError);
        if (accessTokenError) {
          localStorage.removeItem(LocalStorageKeys.AccessTokenError);
          showErrorNotification('Error', accessTokenError);
        }
      };

      const getAccessToken = async (): Promise<boolean> => {
        // If the password reset just completed, we don't want to get an access token, we want the user to have to log in again
        if (ssoPasswordResetCompleted) {
          return false;
        }

        // If we already have an access token, return true
        if (accessTokenFromLocalStorage) {
          return true;
        }

        // NOTE: If we don't have an auth token, that's ok, we can still get a new access token silently as long as we have the MSAL
        // specific stuff in local storage - there is no harm in trying to get a new access token at this point

        try {
          showPageLoadingOverlay();
          return await AuthenticationService.getAccessToken();
        } catch {
          // NOTE: We want to catch the error but not do anything else here
        } finally {
          hidePageLoadingOverlay();
        }

        return false;
      };

      // If the URL changed, reset the flag so we don't render any page routes until we get all of the user's authorization info
      setAllInitialDataIsLoaded(false);
      lastLocationValue.current = location.pathname;

      // If the URL contains hash data, it is because of a successful SSO login or an error. In either case, the hash data will get removed from the URL
      // and the page redirected -- in this situation, we conditionally do not render certain parts of the application, like page components, using a state variable
      // to dictate that.
      const urlContainsSSOHash = Msal.UserAgentApplication.prototype.urlContainsHash(location.hash);
      setUrlContainsSSOHashData(urlContainsSSOHash);

      AuthenticationService.initializeSignIn();

      if (!urlContainsSSOHash) {
        // Handle SSO error logic -- if the function returned true, it triggered a redirect so we want to return early from this code in that case.
        if (handleSSOErrors()) {
          return;
        }

        // Handle SSO password reset logic -- if the function returned true, it triggered a redirect so we want to return early from this code in that case.
        if (handleSSOPasswordResetLogic()) {
          return;
        }

        // If we have just completed a password reset, return early and load the app as usual
        if (ssoPasswordResetCompleted) {
          localStorage.removeItem(LocalStorageKeys.SSOPasswordResetCompleted);
          setAllInitialDataIsLoaded(true);
          return;
        }

        // Handle access token errors
        handleAccessTokenError();

        const accessTokenWasAcquired = await getAccessToken();

        // If we don't have a valid access token at this point, the user needs to take explicit action in order to acquire one since the authentication
        // service tried to refresh it silently and couldn't for whatever reason.
        if (!accessTokenWasAcquired) {
          // Clear out the user's info from local storage if we couldn't get an access token
          updateUserInformation(null);

          // If we had an auth token but couldn't get an access token, wipe everything and log the user out
          if (authTokenFromLocalStorage) {
            logUserOutAndClearInformation();
            return;
          } else {
            // If we never had an auth token to begin with, load the app as usual
            setAllInitialDataIsLoaded(true);
          }
        } else {
          const userInformationFromLocalStorage = localStorage.getItem(
            LocalStorageKeys.UserInformation,
          );

          if (!userInformationFromLocalStorage) {
            try {
              showPageLoadingOverlay();

              // Try to get the current user's information - if the AJAX call returns a NotFound status, it means the user does not have
              // a user record in our database
              try {
                const user = await usersApiService.getUser();

                updateUserInformation({
                  id: user.id!,
                  emailAddress: user.emailAddress,
                  name: localStorage.getItem(LocalStorageKeys.UserName) as string,
                  userRoles: user.roles,
                });
              } catch (error) {
                if (error && error.response && error.response.status === 404) {
                  // Since we could not get the user's info from the server, we want to get what we can by reading in the data from the access token
                  // and populate the UserInformation object
                  const userInfoFromToken = AuthenticationService.getUserInfoFromAccessToken(
                    localStorage.getItem(LocalStorageKeys.AccessToken)!,
                  );

                  if (!userInfoFromToken) {
                    throw new Error('The user could not be logged in.');
                  }

                  updateUserInformation({
                    id: '0', // NOTE: We use a placeholder ID in this case
                    emailAddress: userInfoFromToken.emailAddress,
                    name: userInfoFromToken.name,
                    userRoles: [],
                  });
                } else {
                  // If the error wasn't what we want to handle, rethrow it
                  throw error;
                }
              }

              setAllInitialDataIsLoaded(true);
            } catch {
              // If we couldn't get the user's info (or it didn't exist on the server), we want to log them out
              // and set an error message in local storage that our app will show the next time it loads
              logUserOutAndClearInformation();

              // NOTE: We have to set the local storage after we call the logUserOutAndClearInformation() method
              // because that method wipes local storage
              localStorage.setItem(
                LocalStorageKeys.AccessTokenError,
                'Sorry, but we could not log you in at this time.',
              );
            } finally {
              hidePageLoadingOverlay();
            }
          } else {
            const parsedUserInfo = populateUserInformationFromLocalStorage();
            updateUserInformation(parsedUserInfo);
            setAllInitialDataIsLoaded(true);
          }
        }
      }
    };

    checkUserAuthenticationStatus();
  }, [
    location,
    hidePageLoadingOverlay,
    showPageLoadingOverlay,
    history,
    updateUserInformation,
    showSuccessNotification,
    showErrorNotification,
  ]);

  const onLanguageChanged = useCallback((lang: string) => {
    moment.locale(lang); // Update moment locale globally
    setLocale(lang); // Update locale state for kendo provider
  }, []);

  i18n.on('languageChanged', onLanguageChanged);

  // Add commonly used icons to the library collection so they're available
  // in every component without explicit imports
  library.add(
    faChartArea,
    faChevronDown,
    faClock,
    faClone,
    faCog,
    faCopy,
    faDatabase,
    faEdit,
    faEye,
    faEyeSlash,
    faFileAlt,
    faIdCard,
    faKey,
    faLightbulb,
    faPlus,
    faSave,
    faSearch,
    faServer,
    faShoppingBasket,
    faSignOutAlt,
    faTable,
    faTimes,
    faTrash,
    faUndo,
    faUsers,
    faWrench,
  );

  // Convert date strings to dates in axios responses
  axiosMiddleware.enableDateStringToDateResponseMiddleware();

  return (
    <div className={urlContainsSSOHashData || passwordResetInProgress ? 'd-none' : ''}>
      <IntlProvider locale={locale}>
        <>
          {/* We wait until all initial data is loaded before we render any page components */}
          {allInitialDataIsLoaded && (
            <Layout>
              <Switch>
                {/* Login page */}
                <Route exact path={ApplicationRoutes.SITE_ROOT} component={LoginPage} />

                {/* Home page */}
                <AuthenticatedRoute exact path={ApplicationRoutes.HOME} component={HomePage} />

                {/* Admin pages */}
                <AuthenticatedRoute
                  exact
                  path={ApplicationRoutes.ADMIN_CLIENTS}
                  component={ClientsPage}
                  requiredUserRoles={[UserConstants.UserRolesValues.ADMIN]}
                />

                <AuthenticatedRoute
                  exact
                  path={ApplicationRoutes.ADMIN_PRODUCTS}
                  component={ProductsPage}
                  requiredUserRoles={[UserConstants.UserRolesValues.ADMIN]}
                />

                <AuthenticatedRoute
                  exact
                  path={ApplicationRoutes.ADMIN_LICENSES}
                  component={LicensesPage}
                  requiredUserRoles={[UserConstants.UserRolesValues.ADMIN]}
                />

                <AuthenticatedRoute
                  exact
                  path={ApplicationRoutes.ADMIN_SITESETTINGS}
                  component={SiteSettingsPage}
                  requiredUserRoles={[UserConstants.UserRolesValues.ADMIN]}
                />

                <AuthenticatedRoute
                  exact
                  path={ApplicationRoutes.ADMIN_USERS}
                  component={UsersPage}
                  requiredUserRoles={[UserConstants.UserRolesValues.ADMIN]}
                />

                <AuthenticatedRoute
                  exact
                  path={ApplicationRoutes.ADMIN_ESTIMATORHOSTED_USERMANAGEMENT}
                  component={EstimatorHostedUserManagementPage}
                  requiredUserRoles={[UserConstants.UserRolesValues.ADMIN]}
                />

                {/* Report pages */}
                {/* Redirect the user to the usage report page if the user hit the root reports page */}

                <Route exact path={ApplicationRoutes.REPORTS}>
                  <Redirect to={ApplicationRoutes.REPORTS_USAGE} />
                </Route>

                <AuthenticatedRoute
                  exact
                  path={ApplicationRoutes.REPORTS_USAGE}
                  component={ReportsPage}
                  requiredUserRoles={[
                    UserConstants.UserRolesValues.CLIENTUSER,
                    UserConstants.UserRolesValues.INTERNALUSER,
                    UserConstants.UserRolesValues.ADMIN,
                  ]}
                  allowAnyRoles
                />

                <AuthenticatedRoute
                  exact
                  path={ApplicationRoutes.REPORTS_CHECKOUTS}
                  component={ReportsPage}
                  requiredUserRoles={[
                    UserConstants.UserRolesValues.CLIENTUSER,
                    UserConstants.UserRolesValues.INTERNALUSER,
                    UserConstants.UserRolesValues.ADMIN,
                  ]}
                  allowAnyRoles
                />

                <AuthenticatedRoute
                  exact
                  path={ApplicationRoutes.REPORTS_DURATION}
                  component={ReportsPage}
                  requiredUserRoles={[
                    UserConstants.UserRolesValues.CLIENTUSER,
                    UserConstants.UserRolesValues.INTERNALUSER,
                    UserConstants.UserRolesValues.ADMIN,
                  ]}
                  allowAnyRoles
                />

                <AuthenticatedRoute
                  exact
                  path={ApplicationRoutes.REPORTS_RAWDATA}
                  component={ReportsPage}
                  requiredUserRoles={[
                    UserConstants.UserRolesValues.CLIENTUSER,
                    UserConstants.UserRolesValues.INTERNALUSER,
                    UserConstants.UserRolesValues.ADMIN,
                  ]}
                  allowAnyRoles
                />

                {/* Error pages */}
                <Route exact path={ApplicationRoutes.ERROR_ERROR} component={GenericErrorPage} />
                <Route
                  exact
                  path={ApplicationRoutes.ERROR_UNAUTHORIZED}
                  component={UnauthorizedPage}
                />
                <Route
                  exact
                  path={ApplicationRoutes.ERROR_PAGENOTFOUND}
                  component={PageNotFoundPage}
                />
                <Route>
                  <Redirect to={ApplicationRoutes.ERROR_PAGENOTFOUND} />
                </Route>
              </Switch>
            </Layout>
          )}
        </>
      </IntlProvider>
    </div>
  );
};

const BrowserApp = (): JSX.Element => (
  <BrowserRouter basename={baseUrl}>
    <App />
  </BrowserRouter>
);

export default withAITracking(reactPlugin, BrowserApp, 'App');
