import React, { useMemo } from 'react';
import { Route, RouteProps, useHistory } from 'react-router';
import ApplicationRoutes from '../../../constants/routes';
import { populateUserInformationFromLocalStorage } from '../../../utils/user-authorization';

interface Props {
  requiredUserRoles?: string[];
  allowAnyRoles?: boolean; // Specifies that a user having any of the required roles is OK (as opposed to requiring every role)
}

const AuthenticatedRoute: React.FC<RouteProps & Props> = props => {
  const history = useHistory();

  return useMemo(() => {
    const redirectUser = (): void => {
      // Explicitly replace the current URL
      history.replace(ApplicationRoutes.ERROR_UNAUTHORIZED);
    };

    // The app does not render any routes until a user is authenticated and their user information is set in the local storage (if they are logged in)
    // so we can check that here / get any user information (like their roles) we need for below
    const userInfoFromLocalStorage = populateUserInformationFromLocalStorage();

    if (userInfoFromLocalStorage === null) {
      redirectUser();
      return <></>;
    }

    // Check authorization if it's required
    if (props.requiredUserRoles) {
      const allUserRoles = userInfoFromLocalStorage.userRoles.map(v => v.id);

      if (
        (props.allowAnyRoles &&
          !props.requiredUserRoles.some(v => allUserRoles.some(m => m === v))) ||
        (!props.allowAnyRoles &&
          !props.requiredUserRoles.every(v => allUserRoles.some(m => m === v)))
      ) {
        redirectUser();
        return <></>;
      }
    }

    return (
      <div data-testid="authenticated-route" className="authenticatedRoute flex-grow-1">
        {' '}
        <Route {...props} />
      </div>
    );

    // NOTE: If the URL changes, this component will get re-rendered so we only want to run this useEffect code when the component mounts
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default AuthenticatedRoute;
