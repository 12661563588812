import React, { useContext, useEffect, useLayoutEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import {
  Collapse,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  Navbar,
  NavbarToggler,
  NavLink,
  UncontrolledDropdown,
} from 'reactstrap';
import { AuthenticationService } from '../../../services/authentication/authentication-service';
import { UserInformationContext } from '../../UserInformationContext';
import ApplicationRoutes from './../../../constants/routes';
import './NavigationMenu.scss';

interface CustomNavigationLink {
  title: string;
  url?: string;
  hasDivider?: boolean;
  onClick?: () => void;
  subLinks?: CustomNavigationLink[];
}

const NavigationMenu: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [mobileNavigationMenuIsOpen, setMobileNavigationMenuIsOpen] = useState<boolean>(false);
  const [pageIsScrolled, setPageIsScrolled] = useState<boolean>(false);
  const { userInformation, updateUserInformation } = useContext(UserInformationContext);

  const [navigationLinks, setNavigationLinks] = useState<CustomNavigationLink[]>([]);

  // Set the navigation links based on the user's information
  useEffect(() => {
    const generateNavigationLinks = (): CustomNavigationLink[] => {
      const newNavigationLinks: CustomNavigationLink[] = [
        { title: t('general:home'), url: ApplicationRoutes.SITE_ROOT },
      ];

      if (userInformation && userInformation.id) {
        if (
          userInformation.userIsClientUser ||
          userInformation.userIsInternalUser ||
          userInformation.userIsAdmin
        ) {
          newNavigationLinks.push({
            title: t('reporting:reports'),
            subLinks: [
              {
                title: t('reporting:reportNames.usage'),
                url: ApplicationRoutes.REPORTS_USAGE,
              },
              {
                title: t('reporting:reportNames.checkouts'),
                url: ApplicationRoutes.REPORTS_CHECKOUTS,
              },
              {
                title: t('reporting:reportNames.duration'),
                url: ApplicationRoutes.REPORTS_DURATION,
              },
              {
                title: t('reporting:reportNames.rawData'),
                url: ApplicationRoutes.REPORTS_RAWDATA,
              },
            ],
          });
        }

        if (userInformation.userIsAdmin) {
          newNavigationLinks.push({
            title: 'Admin',
            subLinks: [
              {
                title: 'Clients',
                url: ApplicationRoutes.ADMIN_CLIENTS,
              },
              {
                title: 'Licenses',
                url: ApplicationRoutes.ADMIN_LICENSES,
              },
              {
                title: 'Products',
                url: ApplicationRoutes.ADMIN_PRODUCTS,
              },
              {
                title: 'Users',
                url: ApplicationRoutes.ADMIN_USERS,
              },
              {
                title: 'Estimator Hosted - User Management',
                url: ApplicationRoutes.ADMIN_ESTIMATORHOSTED_USERMANAGEMENT,
              },
              {
                title: 'Site Settings',
                url: ApplicationRoutes.ADMIN_SITESETTINGS,
                hasDivider: true,
              },
            ],
          });
        }

        newNavigationLinks.push({
          title: userInformation.name,
          subLinks: [
            {
              title: 'Log Out',
              onClick: (): void => {
                AuthenticationService.logout();
              },
            },
          ],
        });
      } else {
        newNavigationLinks.push({
          title: 'Sign In',
          onClick: (): void => {
            AuthenticationService.navigateToSignInOrPasswordReset();
          },
        });
      }

      return newNavigationLinks;
    };

    setNavigationLinks(generateNavigationLinks());
  }, [userInformation, history, t, updateUserInformation]);

  const toggleMobileNavigationLinks = (): void => {
    setMobileNavigationMenuIsOpen(!mobileNavigationMenuIsOpen);
  };

  // Watch when the user scrolls so we can set a property that will dictate a CSS class in the navigation area
  useLayoutEffect(() => {
    let scrollEventTimer: NodeJS.Timeout;

    const scrollEventHandler = (): void => {
      if (scrollEventTimer) {
        clearTimeout(scrollEventTimer);
      }

      scrollEventTimer = setTimeout(() => {
        setPageIsScrolled(window.pageYOffset >= 25);
      }, 25);
    };

    window.addEventListener('scroll', scrollEventHandler);

    return (): void => {
      clearTimeout(scrollEventTimer);
      window.removeEventListener('scroll', scrollEventHandler);
    };
  }, []);

  return (
    <Navbar
      light
      fixed={'top'}
      className={'shadow-sm d-flex ' + (pageIsScrolled ? 'pageIsScrolledHeader' : '')}
    >
      <div className="navigationMenuInnerArea container">
        <div className="logoContainer">
          <Link to={ApplicationRoutes.SITE_ROOT}>
            <img
              className="img-fluid"
              src="/images/common/BeckTechLogo.png"
              alt="Beck Technology logo"
            />
          </Link>
        </div>

        <div className="linksContainer">
          {navigationLinks.map(currLink => {
            // If the current link has a URL, show it as a regular link.
            // If the current link has an array of sublinks, show the current link and sublinks as a dropdown.
            // NOTE: This code only handles one level of sublinks for the time being.
            if (currLink.url || currLink.onClick) {
              return (
                <div className="navLinkContainer" key={currLink.title}>
                  <NavLink
                    tag={Link}
                    to={currLink.url || ''}
                    onClick={(e): void => {
                      if (currLink.onClick) {
                        currLink.onClick();
                        e.preventDefault();
                      }
                    }}
                  >
                    {currLink.title}
                  </NavLink>
                </div>
              );
            } else if (currLink.subLinks && currLink.subLinks.length) {
              return (
                <div className="navLinkContainer" key={currLink.title}>
                  <UncontrolledDropdown>
                    <DropdownToggle nav caret>
                      <span title={currLink.title}>{currLink.title}</span>
                    </DropdownToggle>

                    <DropdownMenu>
                      {currLink.subLinks.map(currSubLink => {
                        return (
                          <div key={currSubLink.title}>
                            {currSubLink.hasDivider && <DropdownItem divider />}
                            <DropdownItem>
                              <NavLink
                                tag={Link}
                                to={currSubLink.url || ''}
                                onClick={(e): void => {
                                  if (currSubLink.onClick) {
                                    currSubLink.onClick();
                                    e.preventDefault();
                                  }
                                }}
                              >
                                {currSubLink.title}
                              </NavLink>
                            </DropdownItem>
                          </div>
                        );
                      })}
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </div>
              );
            }

            throw new Error('navigationLinks was not in an expectedFormat');
          })}
        </div>

        <div className="mobileLinksContainerToggle d-md-none">
          <NavbarToggler onClick={toggleMobileNavigationLinks} />
        </div>
      </div>

      <Collapse
        isOpen={mobileNavigationMenuIsOpen}
        navbar
        className="mobileLinksContainer shadow-sm d-md-none"
      >
        <Nav navbar>
          {navigationLinks.map(currLink => {
            // If the current link has a URL, show it as a regular link.
            // If the current link has an array of sublinks, show the current link and sublinks in an indented format.
            // NOTE: This code only handles one level of sublinks for the time being.
            if (currLink.url || currLink.onClick) {
              return (
                <div className="navLinkContainer" key={currLink.title}>
                  <NavLink
                    tag={Link}
                    to={currLink.url || ''}
                    onClick={(e): void => {
                      if (currLink.onClick) {
                        currLink.onClick();
                        e.preventDefault();
                      }

                      setMobileNavigationMenuIsOpen(false);
                    }}
                  >
                    {currLink.title}
                  </NavLink>
                </div>
              );
            } else if (currLink.subLinks && currLink.subLinks.length) {
              return (
                <div key={currLink.title}>
                  <NavLink className="indentedNavigationLinkParent">{currLink.title}</NavLink>

                  {currLink.subLinks.map(currSubLink => {
                    return (
                      <div key={currSubLink.title}>
                        {currSubLink.hasDivider && <DropdownItem divider />}
                        <NavLink
                          tag={Link}
                          to={currSubLink.url || ''}
                          onClick={(e): void => {
                            if (currSubLink.onClick) {
                              currSubLink.onClick();
                              e.preventDefault();
                            }

                            setMobileNavigationMenuIsOpen(false);
                          }}
                          className="indentedNavigationLink"
                        >
                          {currSubLink.title}
                        </NavLink>
                      </div>
                    );
                  })}
                </div>
              );
            }

            throw new Error('navigationLinks was not in an expectedFormat');
          })}
        </Nav>
      </Collapse>

      {/* NOTE: Since this component gets re-rendered when we load a page, the loading progress bar will always animate when it loads */}
      <div className="loadingProgressBar">
        <div className="progressBar"></div>
      </div>
    </Navbar>
  );
};

export default NavigationMenu;
