import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isDate } from 'lodash';
import React, { useState } from 'react';
import { Collapse, Popover, PopoverBody, PopoverHeader } from 'reactstrap';
import { getShortDateString } from '../../../../../utils/dates';
import BTCard from '../../../../common-page-components/card/BTCard';
import BTButton from '../../../../common-page-components/controls/button/BTButton';
import LicensesConstants from './../../../../../constants/licenses';
import ProductsConstants from './../../../../../constants/products';
import './LicenseListing.scss';

interface Props {
  editLicenseButtonClick: (productLicense: License) => void;
  deleteLicenseButtonClick: (productLicense: License) => void;
  requestDeploymentButtonClick: (productLicense: License) => void;
  redeployButtonClick: (productLicense: License) => void;
  isEditAreaOpen: boolean;
  license: License;
}

const LicenseListing: React.FC<Props> = ({
  license,
  isEditAreaOpen,
  editLicenseButtonClick,
  deleteLicenseButtonClick,
  requestDeploymentButtonClick,
  redeployButtonClick,
}) => {
  const title = `${license.clientOrganization.name} - ${license.product.name}`;

  const [deploymentInfoAreaIsDisplayed, setDeploymentInfoAreaIsDisplayed] = useState<boolean>(
    false,
  );

  const [popoverOpen, setPopoverOpen] = useState<Dictionary<boolean>>({});

  const toggle = (target: string): void => {
    if (!popoverOpen[target]) {
      setPopoverOpen({
        ...popoverOpen,
        [target]: true,
      });
    } else {
      setPopoverOpen({
        ...popoverOpen,
        [target]: !popoverOpen[target],
      });
    }
  };

  const shouldShowDeploymentInfo = (): boolean => {
    return (
      license.product.deploymentTypeId !== null &&
      license.product.deploymentTypeId.length > 0 &&
      license.product.deploymentTypeId !== ProductsConstants.DeploymentTypes.NONE
    );
  };

  const isDeploymentComplete = (): boolean =>
    license.deploymentStatus === LicensesConstants.DeploymentStatuses.COMPLETED;

  const isDeploymentError = (): boolean =>
    license.deploymentStatus === LicensesConstants.DeploymentStatuses.ERROR;

  const canDeploymentBeRequested = (): boolean =>
    [null, LicensesConstants.DeploymentStatuses.NONE].includes(license.deploymentStatus);

  const getDeploymentStatusCSSClass = (): string => {
    switch (license.deploymentStatus) {
      case LicensesConstants.DeploymentStatuses.COMPLETED:
        return 'completed';

      case LicensesConstants.DeploymentStatuses.ERROR:
        return 'error';

      case LicensesConstants.DeploymentStatuses.REQUESTED:
        return 'requested';

      default:
        return '';
    }
  };

  const getDeploymentStatusText = (): string => {
    switch (license.deploymentStatus) {
      case LicensesConstants.DeploymentStatuses.COMPLETED:
        return LicensesConstants.DeploymentStatusesDisplayNames.COMPLETED;

      case LicensesConstants.DeploymentStatuses.ERROR:
        return LicensesConstants.DeploymentStatusesDisplayNames.ERROR;

      case LicensesConstants.DeploymentStatuses.IN_PROGRESS:
        return LicensesConstants.DeploymentStatusesDisplayNames.IN_PROGRESS;

      case LicensesConstants.DeploymentStatuses.REQUESTED:
        return LicensesConstants.DeploymentStatusesDisplayNames.REQUESTED;

      case LicensesConstants.DeploymentStatuses.NONE:
      default:
        return LicensesConstants.DeploymentStatusesDisplayNames.NONE;
    }
  };

  // A deployment detail can contain a date string.
  // Our axios middleware recognizes and converts date strings to date objects, for convenience, so
  // we need to handle formatting the date strings (when applicable).
  const getDeploymentDetailValueText = (deploymentDetailValueObject: string | Date): string => {
    return isDate(deploymentDetailValueObject)
      ? getShortDateString(deploymentDetailValueObject)
      : deploymentDetailValueObject;
  };

  const getDeploymentDetailsStringForKey = (input: string): string => {
    return input.replace(/\s/g, '');
  };

  return (
    <BTCard
      cardObject={license}
      sortValue={title}
      title={title}
      key={license.id}
      groupValue={license.product.name}
      metadata={[
        license.clientOrganization.name,
        license.product.name,
        (license.product.features || []).join(' '),
        getShortDateString(license.effectiveDate),
        getShortDateString(license!.expirationDate),
      ]}
      cardIcons={[
        {
          buttonIcon: 'edit',
          onClick: (): void => {
            editLicenseButtonClick(license);
          },
          tooltip: isEditAreaOpen ? '' : 'Edit',
          disabled: isEditAreaOpen,
          'data-testid': 'license-edit',
        },
        {
          buttonIcon: 'trash',
          onClick: (): void => {
            deleteLicenseButtonClick(license);
          },
          tooltip: isEditAreaOpen ? '' : 'Delete',
          disabled: isEditAreaOpen,
          'data-testid': 'license-delete',
        },
      ]}
    >
      <div className="productLicenseCardContent">
        <div className="line1">
          <div className="productName">
            <label htmlFor="productName" className="cardLabel">
              Product
            </label>
            <div id="productName">{license.product.name}</div>
          </div>

          <div className="usersCount">
            <label htmlFor="usersCount" className="cardLabel">
              User Count
            </label>

            <div id="usersCount">
              {!license.enforceStrictUserCount
                ? `${license.userCount} User` + (license.userCount === 1 ? '' : 's')
                : 'Unlimited'}
            </div>
          </div>
        </div>

        <div className="d-flex">
          <div className="featuresList flex-grow-1">
            <label className="cardLabel">Features</label>

            {!license.product.features.length ? (
              <div className="featureListing">(No Features)</div>
            ) : (
              <div className="allFeatures">
                {license.product.features.map(currFeature => {
                  return (
                    <div key={currFeature.id} className="featureListing">
                      {currFeature.name}
                    </div>
                  );
                })}
              </div>
            )}
          </div>

          <div className="activationDateRange">
            <label htmlFor="activationDates" className="cardLabel">
              Activation Dates
            </label>
            <div id="activationDates">
              <span>{getShortDateString(license.effectiveDate)}</span>
              <span className="mx-2">&ndash;</span>
              <span>{getShortDateString(license.expirationDate)}</span>
            </div>
          </div>
        </div>

        {shouldShowDeploymentInfo() && (
          <div className="deploymentInfoArea">
            <label
              className="pointer cardLabel"
              onClick={(): void => {
                setDeploymentInfoAreaIsDisplayed(!deploymentInfoAreaIsDisplayed);
              }}
            >
              <span className={'chevronIcon ' + (deploymentInfoAreaIsDisplayed ? 'isRotated' : '')}>
                <FontAwesomeIcon icon="chevron-down" />
              </span>

              <span>Deployment Info</span>
              <FontAwesomeIcon className="ml-1" icon="database" />
            </label>

            <Collapse isOpen={deploymentInfoAreaIsDisplayed}>
              <div className="deploymentStatusInfo">
                <div>
                  <div>
                    <label className="cardLabel" htmlFor="deploymentStatus">
                      Status
                    </label>
                    <div
                      id="deploymentStatus"
                      className={'flex-grow-1 deploymentStatus ' + getDeploymentStatusCSSClass()}
                    >
                      {getDeploymentStatusText()}
                    </div>

                    {canDeploymentBeRequested() && (
                      <div className="mt-2 mb-1">
                        <BTButton
                          text="Run Deployment"
                          disabled={isEditAreaOpen}
                          onClick={(): void => requestDeploymentButtonClick(license)}
                        />
                      </div>
                    )}
                  </div>

                  {(isDeploymentComplete() || isDeploymentError()) && (
                    <>
                      <div className="mt-3">
                        <div className="deploymentDetailsContainer">
                          <label className="cardLabel">Details</label>
                          <div className="deploymentDetailsTable container-fluid">
                            {Object.keys(license.deploymentDetails).map(v => (
                              <div className="row" key={v}>
                                <div className="col-3 p-0 mr-auto text-nowrap">{v}</div>
                                <div className="col-7 p-0 mr-auto text-nowrap text-truncate">
                                  {getDeploymentDetailValueText(license.deploymentDetails[v])}
                                </div>
                                <Popover
                                  placement="bottom"
                                  isOpen={
                                    popoverOpen[`view-${getDeploymentDetailsStringForKey(v)}`]
                                  }
                                  target={`view-${getDeploymentDetailsStringForKey(v)}`}
                                  toggle={(): void =>
                                    toggle(`view-${getDeploymentDetailsStringForKey(v)}`)
                                  }
                                >
                                  <PopoverHeader>{v}</PopoverHeader>
                                  <PopoverBody>
                                    {getDeploymentDetailValueText(license.deploymentDetails[v])}
                                  </PopoverBody>
                                </Popover>
                                <div
                                  id={`view-${getDeploymentDetailsStringForKey(v)}`}
                                  className="col-1 p-0 icon-button"
                                >
                                  <FontAwesomeIcon icon="eye" title="View" />
                                </div>
                                <div
                                  className="col-1 p-0 icon-button"
                                  onClick={(): void => {
                                    navigator.clipboard.writeText(
                                      getDeploymentDetailValueText(license.deploymentDetails[v]),
                                    );
                                  }}
                                >
                                  <FontAwesomeIcon icon="clone" title="Copy to clipboard" />
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>

                      <div className="mt-3">
                        <label className="cardLabel">Actions</label>
                        <div>
                          <BTButton
                            text="Redeploy"
                            disabled={isEditAreaOpen}
                            onClick={(): void => redeployButtonClick(license)}
                          />
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </Collapse>
          </div>
        )}
      </div>
    </BTCard>
  );
};

export default LicenseListing;
