export type FileType = 'csv';

const saveFile = (arrayBuffer: ArrayBuffer, fileName: string, fileType: FileType): void => {
  // Source: https://expertcodeblog.wordpress.com/2018/08/13/asp-net-core-angular-download-file/
  const byteArray = new Uint8Array(arrayBuffer);
  const blob = new Blob([byteArray], { type: getFileMimeType(fileType) });
  const name = fileName + '.' + fileType;
  // IE doesn't allow using a blob object directly as link href
  // instead it is necessary to use msSaveOrOpenBlob
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(blob, name);
    return;
  }

  // For other browsers:
  // Create a link pointing to the ObjectURL containing the blob.
  const data = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = data;
  link.download = name;
  link.click();
  setTimeout(() => {
    // For Firefox it is necessary to delay revoking the ObjectURL
    window.URL.revokeObjectURL(data);
  }, 100);
};

const getFileMimeType = (fileType: FileType): string => {
  switch (fileType) {
    case 'csv':
      return 'text/csv';
    default:
      return 'text/plain';
  }
};

export { saveFile };
