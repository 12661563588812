import React, { useEffect, useState } from 'react';
import BTCard from '../../../../common-page-components/card/BTCard';

interface Props {
  client: ClientOrganization;
  licenses: License[];
  isDisabled: boolean;
  onEditClick: (client: ClientOrganization) => void;
  onDeleteClick: (client: ClientOrganization) => void;
}

const getMetadata = (client: ClientOrganization): string[] => {
  const metadata = [client.id, client.name];

  if (client.address) {
    metadata.push(
      ...[
        client.address.streetAddress,
        client.address.aptSuiteBldg,
        client.address.city,
        client.accountExecutive,
      ],
    );
  }

  return metadata;
};

const isAddressBlank = (address: Address): boolean => {
  return (
    !address ||
    (!(address.aptSuiteBldg || '').trim() &&
      !(address.city || '').trim() &&
      !address.country &&
      !(address.streetAddress || '').trim() &&
      !address.stateOrRegion &&
      !(address.postalCode || '').trim())
  );
};

const ClientListing: React.FC<Props> = ({
  client,
  licenses,
  isDisabled,
  onEditClick,
  onDeleteClick,
}) => {
  const [metadata, setMetadata] = useState<string[]>(getMetadata(client));
  const [doesClientOrganizationHaveLicenses, setDoesClientOrganizationHaveLicenses] = useState<
    boolean
  >();

  // Update metadata when client changes
  useEffect(() => {
    setMetadata(getMetadata(client));
  }, [client]);

  const getDeleteIconTooltip = (): string => {
    if (isDisabled) {
      return '';
    } else if (doesClientOrganizationHaveLicenses) {
      return 'This client organization cannot be deleted because it has active licenses';
    }
    return 'Delete';
  };

  useEffect(() => {
    setDoesClientOrganizationHaveLicenses(
      licenses &&
        licenses.findIndex(v => v.clientOrganization && v.clientOrganization.id === client.id) > -1,
    );
  }, [client, licenses]);

  return (
    <BTCard
      sortValue={client.name}
      cardObject={client}
      title={client.name}
      key={client.name}
      metadata={metadata}
      cardIcons={[
        {
          buttonIcon: 'edit',
          onClick: (): void => {
            onEditClick(client);
          },
          tooltip: isDisabled ? '' : 'Edit',
          disabled: isDisabled,
          'data-testid': 'client-edit',
        },
        {
          buttonIcon: 'trash',
          onClick: (): void => {
            onDeleteClick(client);
          },
          tooltip: getDeleteIconTooltip(),
          disabled: isDisabled || doesClientOrganizationHaveLicenses,
          'data-testid': 'client-delete',
        },
      ]}
    >
      <div>
        <div className="d-flex">
          <div>
            <label className="cardLabel">ID</label>
            <div>{client.id}</div>
          </div>
        </div>

        <div className="d-flex">
          <div>
            <label className="cardLabel">Account Executive</label>
            <div>{client.accountExecutive}</div>
          </div>
          <div className="flex-grow-1 text-right">
            <label className="cardLabel">Domains</label>
            {client.domains && client.domains.length > 0 ? (
              client.domains.map(d => <div key={d}>{d}</div>)
            ) : (
              <div>(No Domains)</div>
            )}
          </div>
        </div>

        <div className="d-flex">
          <div className="address">
            <label className="cardLabel">Address</label>
            {!client.address || isAddressBlank(client.address) ? (
              <div>(No Address)</div>
            ) : (
              <>
                <div>{client.address.streetAddress}</div>
                <div>{client.address.aptSuiteBldg}</div>
                <div>
                  {!client.address.city ? '' : client.address.city + ', '}
                  {!client.address.stateOrRegion ? '' : client.address.stateOrRegion.name + ' '}
                  {client.address.postalCode}
                </div>
                {client.address.country && <div>{client.address.country.name}</div>}
              </>
            )}
          </div>
          <div className="flex-grow-1 text-right">
            {' '}
            <label className="cardLabel">Uses Corporate Active Directory</label>
            <div>{client.usesCorporateActiveDirectory ? 'Yes' : 'No'}</div>
          </div>
        </div>
      </div>
    </BTCard>
  );
};

export default ClientListing;
