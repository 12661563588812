import axios from 'axios';
import ApiRoutes from '../../../constants/routes/api-routes';

export class EstimatorHostedApi {
  public static async getUsers(): Promise<EstimatorHostedUser[]> {
    const response = await axios.get<EstimatorHostedUser[]>(ApiRoutes.EstimatorHostedUsers);
    return response.data;
  }

  public static async getUserByEmail(email: string): Promise<EstimatorHostedUser> {
    const url = ApiRoutes.EstimatorHostedUsers + '/' + email;
    const response = await axios.get<EstimatorHostedUser>(url);
    return response.data;
  }

  public static async addUser(
    name: string,
    clientOrganizationId: string,
    groupIds: string[],
  ): Promise<EstimatorHostedUserWithPassword> {
    const response = await axios.post<EstimatorHostedUserWithPassword>(
      ApiRoutes.EstimatorHostedUsers,
      {
        name: name,
        clientOrganizationId: clientOrganizationId,
        groupIds: groupIds,
      },
    );
    return response.data;
  }

  public static async updateUser(
    email: string,
    name: string,
    clientOrganizationId: string,
    groupIds: string[],
  ): Promise<void> {
    const url = ApiRoutes.EstimatorHostedUsers + '/' + email;
    await axios.put(url, {
      name: name,
      clientOrganizationId: clientOrganizationId,
      groupIds: groupIds,
    });
  }

  public static async resetUserPassword(email: string): Promise<string> {
    const url = `${ApiRoutes.EstimatorHostedUsers}/${email}/reset-password`;
    const response = await axios.post<string>(url);
    return response.data;
  }

  public static async deleteUser(email: string): Promise<void> {
    await axios.delete(ApiRoutes.EstimatorHostedUsers + '/' + email);
  }

  public static async getGroups(): Promise<EstimatorHostedUserGroup[]> {
    const response = await axios.get<EstimatorHostedUserGroup[]>(ApiRoutes.EstimatorHostedGroups);
    return response.data;
  }

  public static async getEstimatorConfigForUser(
    email: string,
    configEnvironment: EstimatorHostedConfigurationEnvironment,
  ): Promise<EstimatorHostedConfiguration> {
    const url = `${ApiRoutes.EstimatorHostedUsers}/${email}/configuration/${configEnvironment}`;
    const response = await axios.get<EstimatorHostedConfiguration>(url);
    return response.data;
  }

  public static async saveEstimatorConfigForUser(
    email: string,
    config: EstimatorHostedConfiguration,
  ): Promise<void> {
    const url = `${ApiRoutes.EstimatorHostedUsers}/${email}/configuration`;
    await axios.post(url, config);
  }

  public static async copyEstimatorConfigToUsers(
    emailOfUserToCopyFrom: string,
    configEnvironment: EstimatorHostedConfigurationEnvironment,
    emailsOfUsersToCopyTo: string[],
  ): Promise<void> {
    const url = `${ApiRoutes.EstimatorHostedUsers}/${emailOfUserToCopyFrom}/configuration/${configEnvironment}/copy`;
    await axios.post(url, { emailAddresses: emailsOfUsersToCopyTo });
  }
}
