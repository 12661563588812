import React, { useCallback, useState } from 'react';
import { GridCellProps } from '@progress/kendo-react-grid';
import { Input, InputChangeEvent } from '@progress/kendo-react-inputs';
import { debounce } from 'lodash';

interface Props extends GridCellProps {
  required?: boolean;
}

const GridTextCell: React.FC<Props> = props => {
  const dataValue = props.field && props.dataItem[props.field] ? props.dataItem[props.field] : '';
  const [text, setText] = useState<string>(dataValue);

  const debouncedOnChange = useCallback(debounce(props.onChange!, 250), [props.onChange]);

  const onChange = (e: InputChangeEvent): void => {
    setText(e.target.value as string);

    debouncedOnChange({
      ...props,
      value: e.target.value,
      syntheticEvent: e.syntheticEvent,
    });
  };

  return (
    <td>
      <Input value={text} onChange={onChange} required={props.required} />
    </td>
  );
};

export default GridTextCell;
