import React, { useEffect, useCallback } from 'react';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { ReportNames } from '../../reports';
import { useHistory } from 'react-router';
import { UsageApi } from '../../../../../services/api';
import ApplicationRoutes from '../../../../../constants/routes';
import { useTranslation } from 'react-i18next';
import { applyPartialDateRangeRules } from '../../../../../utils/calendars';
import ReportFilterConstants from './../../../../../constants/reports/filters';

interface Props {
  durationReportFilters: ReportFiltersWithIncrement;
}

const LicenseDurationFilter: React.FC<ReportChartFilterProps & Props> = ({
  reportFilters,
  durationReportFilters,
  setSpecificReportFilters,
  setReportDataSource,
  setCalendarType,
}) => {
  const { t } = useTranslation();
  const history = useHistory();

  const updateReportDataSource = useCallback((): void => {
    setReportDataSource(ReportNames.Duration, () => {
      switch (durationReportFilters.increment) {
        case ReportFilterConstants.FILTERINCREMENT_DAY: {
          return UsageApi.getUsageByDay(
            reportFilters.fromDate,
            reportFilters.toDate,
            reportFilters.client,
            reportFilters.product &&
              reportFilters.product.id === ReportFilterConstants.PRODUCTOPTION_ALL_PRODUCTS
              ? null
              : reportFilters.product,
            'duration',
          );
        }

        case ReportFilterConstants.FILTERINCREMENT_WEEK: {
          return UsageApi.getUsageByWeek(
            applyPartialDateRangeRules(reportFilters.fromDate, reportFilters.calendarType, true),
            applyPartialDateRangeRules(reportFilters.toDate, reportFilters.calendarType, false),
            reportFilters.client,
            reportFilters.product &&
              reportFilters.product.id === ReportFilterConstants.PRODUCTOPTION_ALL_PRODUCTS
              ? null
              : reportFilters.product,
            'duration',
          );
        }

        case ReportFilterConstants.FILTERINCREMENT_MONTH: {
          return UsageApi.getUsageByMonth(
            applyPartialDateRangeRules(reportFilters.fromDate, reportFilters.calendarType, true),
            applyPartialDateRangeRules(reportFilters.toDate, reportFilters.calendarType, false),
            reportFilters.client,
            reportFilters.product &&
              reportFilters.product.id === ReportFilterConstants.PRODUCTOPTION_ALL_PRODUCTS
              ? null
              : reportFilters.product,
            'duration',
          );
        }

        default: {
          // NOTE:  This is based on a fixed dropdown, so this case should never be hit. Returning
          // an empty promise to satisfy the return type, but the redirect will run prior.
          history.push(ApplicationRoutes.ERROR_ERROR);
          return new Promise<BTChartData>((): void => {});
        }
      }
    });
  }, [setReportDataSource, durationReportFilters, reportFilters, history]);

  const updateReportFilterValues = useCallback(
    (durationReportFilters: ReportFiltersWithIncrement): void => {
      setSpecificReportFilters(ReportNames.Duration, durationReportFilters);
      setCalendarType(durationReportFilters.increment);
      updateReportDataSource();
    },
    [setSpecificReportFilters, setCalendarType, updateReportDataSource],
  );

  // Set the data source values for this component when the generic report filters provided to this component change
  useEffect(() => {
    updateReportDataSource();
  }, [reportFilters, updateReportDataSource]);

  // If the duration report filters are not initialized, initialize them
  useEffect(() => {
    if (!durationReportFilters) {
      updateReportFilterValues({
        increment: ReportFilterConstants.FILTERINCREMENT_DAY,
      });
    }
  }, [updateReportFilterValues, durationReportFilters]);

  return (
    <div className="row" data-testid="reports-page-license-duration-chart-filters">
      <div className="col-sm-12 col-md-3 mt-3">
        <label htmlFor="reportIntervalDropdown">{t('reporting:filters.reportInterval')}</label>
        <DropDownList
          id="reportIntervalDropdown"
          data={ReportFilterConstants.FILTERINCREMENT_DEFAULT_INCREMENTS}
          onChange={(event): void => {
            updateReportFilterValues({
              ...durationReportFilters,
              increment: event.target.value,
            });
          }}
          value={(durationReportFilters && durationReportFilters.increment) || ''}
        />
      </div>
    </div>
  );
};

export default LicenseDurationFilter;
