import React from 'react';
import ConfirmationOverlay from '../confirmation-overlay/ConfirmationOverlay';
import NavigationMenu from './../navigation-menu/NavigationMenu';
import './Layout.scss';

const Layout: React.FC<{}> = props => {
  return (
    <div>
      <ConfirmationOverlay />

      <NavigationMenu />

      <div className="pageComponentContainer">{props.children}</div>
    </div>
  );
};

export default Layout;
