//
// NOTE: TO ADD SUPPORT FOR ANOTHER LOCALE, REFER TO THE WIKI ARTICLE FOR INSTRUCTIONS
//

import { load } from '@progress/kendo-react-intl';
import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import { TranslationFilesMap, DEFAULT_LANG, format } from './i18n';
import { Import } from '../utils/imports';
import moment from 'moment';

import 'moment/locale/fr-ca';

import weekData from 'cldr-core/supplemental/weekData.json';
import frCaGeneric from 'cldr-dates-full/main/fr-CA/ca-generic.json';
import frCaGregorian from 'cldr-dates-full/main/fr-CA/ca-gregorian.json';
import frCaDateFields from 'cldr-dates-full/main/fr-CA/dateFields.json';
import frCaTimeZoneNames from 'cldr-dates-full/main/fr-CA/timeZoneNames.json';

load(weekData, frCaGeneric, frCaGregorian, frCaDateFields, frCaTimeZoneNames);

const getInitialTranslations = (): Dictionary<object> => {
  const resources: Dictionary<object> = { [DEFAULT_LANG]: {} };
  const getTranslationsFunc = TranslationFilesMap[DEFAULT_LANG] as () => Import[];
  const translationFiles = getTranslationsFunc();

  translationFiles.forEach(translationFile => {
    resources[DEFAULT_LANG][translationFile.name] = translationFile.translations;
  });

  return resources;
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: getInitialTranslations(),
    lng: DEFAULT_LANG,
    interpolation: {
      escapeValue: false, // react already safes from xss
      format: format,
    },
  });

// Set initial moment locale to the default language
moment.locale(DEFAULT_LANG);
