export interface FeatureViewDto {
  id: string;
  name: string;
  hasActiveLicenses: boolean;
}

export interface ProductViewDto {
  id: string;
  name: string;
  deploymentTypeId: string;
  hasActiveLicenses: boolean;
  features: FeatureViewDto[];
}

export interface FeatureAddDto {
  name: string;
}

export interface ProductAddDto {
  name: string;
  deploymentTypeId: string;
  features: FeatureAddDto[];
}

export interface FeatureEditDto {
  id: string;
  newId?: string;
  name: string;
}

export interface ProductEditDto {
  id?: string;
  name: string;
  deploymentTypeId: string;
  features: FeatureEditDto[];
}

export interface DeploymentTypeDto {
  id: string;
  name: string;
}

export const getFeatureFromDto = (featureDto: FeatureViewDto): ProductFeature => {
  const feature: ProductFeature = {
    id: featureDto.id,
    name: featureDto.name,
    hasActiveLicenses: featureDto.hasActiveLicenses,
    isNew: false,
  };

  return feature;
};

export const getProductFromDto = (productDto: ProductViewDto): Product => {
  const product: Product = {
    id: productDto.id,
    name: productDto.name,
    deploymentTypeId: productDto.deploymentTypeId,
    isNew: false,
    hasActiveLicenses: productDto.hasActiveLicenses,
    features: productDto.features.map<ProductFeature>(featureDto => getFeatureFromDto(featureDto)),
  };

  return product;
};

export const getDeploymentTypeFromDto = (deploymentTypeDto: DeploymentTypeDto): DeploymentType => {
  return {
    id: deploymentTypeDto.id,
    name: deploymentTypeDto.name,
  };
};
