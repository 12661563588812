import { isEqual } from 'lodash';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { Collapse } from 'reactstrap';
import { ClientApi } from '../../../../services/api';
import '../../../../styles/common.scss';
import { scrollToRef } from '../../../../utils/general';
import BTCardsContainer from '../../../common-page-components/cards-container/BTCardsContainer';
import ApplicationRoutes from './../../../../constants/routes';
import { ConfirmationOverlayContext } from './../../../common-page-components/confirmation-overlay/ConfirmationOverlayContext';
import BTFloatingIconButton from './../../../common-page-components/controls/floating-icon-button/BTFloatingIconButton';
import { LoadingPageOverlayContext } from './../../../common-page-components/loading-page-overlay/LoadingPageOverlayContext';
import { NotificationPanelContext } from './../../../common-page-components/notification-panel/NotificationPanelContext';
import './ClientsPage.scss';
import ClientEditForm from './components/ClientEditForm';
import ClientListing from './components/ClientListing';
import { Countries, Regions } from './data/LocationData';
import licensesApiService from '../../../../services/api/licenses/licenses-api-service';

const ClientsPage: React.FC = () => {
  const { showPageLoadingOverlay, hidePageLoadingOverlay } = useContext(LoadingPageOverlayContext);
  const { showConfirmationOverlay, hideConfirmationOverlay } = useContext(
    ConfirmationOverlayContext,
  );
  const { showSuccessNotification, showInfoNotification, showErrorNotification } = useContext(
    NotificationPanelContext,
  );

  const [licenses, setLicenses] = useState<License[]>([]);
  const [clientToEdit, setClientToEdit] = useState<ClientOrganization | null>();
  const [isPageDataLoaded, setIsPageDataLoaded] = useState<boolean>(false);
  const [clients, setClients] = useState<ClientOrganization[]>([]);
  const [countries, setCountries] = useState<ClientCountry[]>(Array<ClientCountry>());
  const [regions, setRegions] = useState<ClientRegion[]>(Array<ClientRegion>());
  const [isEditAreaOpen, setIsEditAreaOpen] = useState<boolean>(false);
  const adminPageEditAreaRef = useRef(null);
  const history = useHistory();

  // Initial load of data
  useEffect(() => {
    setCountries(Countries);
    setRegions(Regions);

    const fetchData = async (): Promise<void> => {
      try {
        showPageLoadingOverlay();

        const [clientData, licenseData] = await Promise.all([
          await ClientApi.getClientOrganizations(),
          await licensesApiService.getLicenses(),
        ]);

        setLicenses(licenseData);

        clientData.forEach(client => hydrateClientOrganization(client, Countries, Regions));

        setClients(clientData);

        setIsPageDataLoaded(true);
      } catch (error) {
        // In this case, we should send the user to an error page since this page isn't very useful without this core data
        showErrorNotification('Error', 'An error occurred while loading the page.');
        history.push(ApplicationRoutes.ERROR_ERROR);
      } finally {
        hidePageLoadingOverlay();
      }
    };

    fetchData();
  }, [hidePageLoadingOverlay, history, showPageLoadingOverlay, showErrorNotification]);

  const hydrateClientOrganization = (
    clientOrg: ClientOrganization,
    countries: ClientCountry[],
    regions: ClientRegion[],
  ): void => {
    if (!clientOrg.address) {
      return;
    }

    if (clientOrg.address.country) {
      const country = countries.find(c => c.name === clientOrg.address!.country!.name);

      if (!country) {
        throw new Error(`Could not find country with name '${clientOrg.address.country.name}'`);
      }

      clientOrg.address.country = country;
    }

    if (clientOrg.address.stateOrRegion) {
      const stateOrRegion = regions.find(c => c.name === clientOrg.address!.stateOrRegion!.name);

      if (!stateOrRegion) {
        throw new Error(
          `Could not find region with name '${clientOrg.address.stateOrRegion.name}'`,
        );
      }

      clientOrg.address.stateOrRegion = stateOrRegion;
    }
  };

  const getNewClient = (): ClientOrganization => ({
    id: '',
    name: '',
    address: {
      streetAddress: '',
      aptSuiteBldg: '',
      city: '',
      postalCode: '',
      stateOrRegion: {
        name: '',
      },
      country: {
        name: '',
      },
    },
    accountExecutive: '',
    isNew: true,
    domains: [],
    usesCorporateActiveDirectory: false,
  });

  const toggleCollapse = (open: boolean): void => {
    setIsEditAreaOpen(open);

    if (open) {
      setTimeout(() => {
        scrollToRef(adminPageEditAreaRef);
      }, 500);
    }
  };

  const onFormDiscard = (client: ClientOrganization): void => {
    const discardChanges = (): void => {
      // Delay this slightly so the edit area has time to animate closed
      toggleCollapse(false);
      setTimeout(() => {
        setClientToEdit(null);
      }, 250);
    };

    if (!isEqual(client, clientToEdit)) {
      showConfirmationOverlay({
        title: 'Confirm',
        text: 'Are you sure you want to discard your changes?',
        buttons: [
          {
            text: 'Yes',
            onClick: (): void => {
              hideConfirmationOverlay();
              discardChanges();
            },
          },
          {
            text: 'No',
            onClick: (): void => hideConfirmationOverlay(),
            color: 'gray',
          },
        ],
      });
    } else {
      discardChanges();
    }
  };

  const onFormSave = async (client: ClientOrganization): Promise<void> => {
    showPageLoadingOverlay();

    const closeEditAreaAndClearOutEditModel = (): void => {
      setIsEditAreaOpen(false);

      // Delay this slightly so the edit area has time to animate closed
      setTimeout(() => {
        setClientToEdit(null);
      }, 250);
    };

    // If populated, match up the selected country or stateOrRegion id with appropriate object,
    // and clean up the region field if the user has changed the country selection without making a new stateOrRegion
    if (client.address) {
      if (client.address.country) {
        client.address.country = countries.find(c => c.id === client.address!.country.id);
      }
      if (client.address.stateOrRegion) {
        client.address.stateOrRegion = regions.find(r => r.id === client.address!.stateOrRegion.id);
      }
    }

    if (client.isNew) {
      try {
        const clientOrg = await ClientApi.addClientOrganization(client);
        hydrateClientOrganization(clientOrg, countries, regions);
        setClients([...clients, clientOrg]);

        closeEditAreaAndClearOutEditModel();

        showSuccessNotification('Success', 'The client was created successfully.');
      } catch {
        showErrorNotification('Error', 'An error occurred while attempting to create the client.');
      } finally {
        hidePageLoadingOverlay();
      }
      return;
    }

    try {
      if (isEqual(client, clientToEdit)) {
        closeEditAreaAndClearOutEditModel();

        showInfoNotification('No Changes', 'No changes were made to the client.');
        return;
      }

      await ClientApi.editClientOrganization(client);
      const clientsClone = [...clients];
      const index = clientsClone.findIndex(c => c.id === client.id);

      if (index === -1) {
        throw new Error(`Could not find client with ID '${client.id}'.`);
      }

      clientsClone[index] = client;

      setClients(clientsClone);

      closeEditAreaAndClearOutEditModel();

      showSuccessNotification('Success', 'The client was updated successfully.');
    } catch {
      showErrorNotification('Error', 'An error occurred while attempting to update the client.');
    } finally {
      hidePageLoadingOverlay();
    }
  };

  const onAddClick = (): void => {
    setClientToEdit(getNewClient());
    toggleCollapse(true);
  };

  const onEditClick = (client: ClientOrganization): void => {
    setClientToEdit(client);
    toggleCollapse(true);
  };

  const onDeleteClick = (client: ClientOrganization): void => {
    // NOTE: The delete button on the card listing should be disabled so if we get to this
    // point, we can assume things are OK and the server can confirm any errors
    showConfirmationOverlay({
      title: 'Confirm',
      text: `Are you sure you want to delete the client "${client.name}"?`,
      buttons: [
        {
          text: 'Yes',
          onClick: async (): Promise<void> => {
            hideConfirmationOverlay();
            showPageLoadingOverlay();

            try {
              await ClientApi.deleteClientOrganization(client.id);
              const updatedClients = clients.filter(c => c.id !== client.id);
              setClients(updatedClients);

              showSuccessNotification('Success', 'The client was deleted successfully.');
            } catch {
              showErrorNotification(
                'Error',
                'An error occurred while attempting to delete the client.',
              );
            } finally {
              hidePageLoadingOverlay();
            }
          },
          color: 'normal',
        },
        {
          text: 'No',
          onClick: (): void => {
            hideConfirmationOverlay();
          },
          color: 'gray',
        },
      ],
    });
  };

  if (!isPageDataLoaded) {
    return <></>;
  }

  return (
    <>
      <div className="container">
        <h1>Clients</h1>
      </div>

      <div className="adminPageEditArea" ref={adminPageEditAreaRef}>
        <Collapse isOpen={isEditAreaOpen}>
          <div className="container-fluid grayBackground">
            <div className="container">
              {clientToEdit && (
                <ClientEditForm
                  client={clientToEdit}
                  countries={countries}
                  regions={regions}
                  licenses={licenses.filter(l => l.clientOrganization.id === clientToEdit.id)}
                  existingClientNames={clients.map(c => c.name.toLowerCase())}
                  existingClientDomains={clients
                    .filter(c => c.id !== clientToEdit.id)
                    .map(c => c.domains)
                    .reduce((previousValue, currentValue) =>
                      previousValue.concat(currentValue.map(domain => domain.toLowerCase())),
                    )}
                  onDiscardClick={onFormDiscard}
                  onSaveClick={onFormSave}
                />
              )}
            </div>
          </div>
        </Collapse>
      </div>
      <div className="container">
        <BTCardsContainer enableSearch recordsNotFoundMessage="No clients were found.">
          {clients.map(client => {
            return (
              <ClientListing
                key={client.id}
                client={client}
                licenses={licenses}
                isDisabled={isEditAreaOpen}
                onEditClick={onEditClick}
                onDeleteClick={onDeleteClick}
              />
            );
          })}
        </BTCardsContainer>
      </div>

      <BTFloatingIconButton
        buttonIcon="plus"
        onClick={onAddClick}
        tooltip={'Add new client'}
        disabled={isEditAreaOpen}
      />
    </>
  );
};

export default ClientsPage;
