import axios from 'axios';
import ApiRoutes from '../../../constants/routes/api-routes';
import {
  DeploymentTypeDto,
  FeatureAddDto,
  FeatureEditDto,
  FeatureViewDto,
  getDeploymentTypeFromDto,
  getFeatureFromDto,
  getProductFromDto,
  ProductAddDto,
  ProductEditDto,
  ProductViewDto,
} from './products-api-service-helper';

const getProducts = async (): Promise<Product[]> => {
  const response = await axios.get<ProductViewDto[]>(ApiRoutes.Products);
  const dtos: ProductViewDto[] = response.data;

  const products: Product[] = dtos.map(productDto => getProductFromDto(productDto));

  return products;
};

const getProductsByClient = async (client: ClientOrganization): Promise<Product[]> => {
  const url = `${ApiRoutes.ProductsByClient}/${client.id}`;

  const response = await axios.get<Product[]>(url);
  return response.data;
};

const addProduct = async (product: Product): Promise<Product> => {
  const productDto: ProductAddDto = {
    name: product.name,
    deploymentTypeId: product.deploymentTypeId,
    features: product.features.map(feature => ({ name: feature.name })),
  };

  const response = await axios.post<ProductViewDto>(ApiRoutes.Products, productDto);
  const newProduct: Product = getProductFromDto(response.data);

  return newProduct;
};

const editProduct = async (product: Product): Promise<Product> => {
  const featuresToAdd: FeatureAddDto[] = product.features
    .filter(feature => feature.isNew)
    .map(feature => ({ id: feature.id, name: feature.name }));

  const addFeatureResponses = await Promise.all(
    featuresToAdd.map(featureDto =>
      axios.post<FeatureViewDto>(`${ApiRoutes.Products}/${product.id}/features`, featureDto),
    ),
  );

  const featuresToEdit: FeatureEditDto[] = product.features
    .filter(feature => !feature.isNew)
    .map(feature => ({ id: feature.id, name: feature.name, newId: feature.newId }));

  await Promise.all(
    featuresToEdit.map(featureDto =>
      axios.put(`${ApiRoutes.Products}/${product.id}/features/${featureDto.id}`, {
        id: featureDto.newId,
        name: featureDto.name,
      }),
    ),
  );

  const featureIdsToDelete: string[] = product.features
    .filter(feature => feature.isDeleted)
    .map(feature => feature.id);

  await Promise.all(
    featureIdsToDelete.map(featureId =>
      axios.delete(`${ApiRoutes.Products}/${product.id}/features/${featureId}`),
    ),
  );

  const productDto: ProductEditDto = {
    id: product.newId,
    name: product.name,
    deploymentTypeId: product.deploymentTypeId,
    features: [],
  };

  await axios.put(`${ApiRoutes.Products}/${product.id}`, productDto);

  const newFeatures: ProductFeature[] = product.features
    .filter(feature => feature.isNew)
    .map(feature => {
      const featureDto = addFeatureResponses
        .map(response => response.data)
        .find(featureDto => featureDto.name === feature.name);

      if (!featureDto) {
        throw new Error(`Could not find feature with name '${feature.name}' in response`);
      }

      return getFeatureFromDto(featureDto);
    });

  const features = product.features.filter(feature => !feature.isNew && !feature.isDeleted);

  features.forEach(feature => {
    if (!feature.newId || feature.id === feature.newId) {
      return;
    }

    feature.id = feature.newId;
  });

  const newProduct: Product = {
    ...product,
    features: [...features, ...newFeatures],
  };

  return newProduct;
};

const deleteProduct = async (productId: string): Promise<void> => {
  await axios.delete(`${ApiRoutes.Products}/${productId}`);
};

const getDeploymentTypes = async (): Promise<DeploymentType[]> => {
  const response = await axios.get<DeploymentTypeDto[]>(`${ApiRoutes.Products}/deployment-types`);
  return response.data.map(v => getDeploymentTypeFromDto(v));
};

export default {
  getProducts,
  getProductsByClient,
  addProduct,
  editProduct,
  deleteProduct,
  getDeploymentTypes,
};
