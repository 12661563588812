import React, { useState } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import BTButton from '../../../../common-page-components/controls/button/BTButton';
import Yup from '../../../../../utils/yup';
import { Form, Formik, FieldArray } from 'formik';
import FormFooter from '../../../../common-page-components/forms/FormFooter';
import FormErrorContainer from '../../../../common-page-components/forms/form-error-container/FormErrorContainer';
import { getFormErrorMessages } from '../../../../../utils/forms';
import FormTextInput from '../../../../common-page-components/forms/FormTextInput';
import { nameof } from '../../../../../utils/nameof';
import BTIconButton from '../../../../common-page-components/controls/icon-button/BTIconButton';
import { DropDownList, DropDownListChangeEvent } from '@progress/kendo-react-dropdowns';
import './EstimatorConfigurationModal.scss';

interface Props {
  users: EstimatorHostedUser[];
  getConfigForUser: (
    environment: EstimatorHostedConfigurationEnvironment,
    user: EstimatorHostedUser,
  ) => Promise<EstimatorHostedConfiguration | null>;
  closeModal: () => void;
  onSaveClick: (
    originalConfig: EstimatorHostedConfiguration,
    updatedConfig: EstimatorHostedConfiguration,
  ) => Promise<void>;
  onDiscardClick: (
    originalConfig: EstimatorHostedConfiguration,
    updatedConfig: EstimatorHostedConfiguration,
  ) => void;
}

const EstimatorConfigurationModal: React.FC<Props> = props => {
  // State
  const [originalConfiguration, setOriginalConfiguration] = useState<
    EstimatorHostedConfiguration
  >();
  const [configuration, setConfiguration] = useState<EstimatorHostedConfiguration>();
  const [selectedEnvironment, setSelectedEnvironment] = useState<
    EstimatorHostedConfigurationEnvironment
  >();

  const onEnvironmentDropdownChange = async (event: DropDownListChangeEvent): Promise<void> => {
    const environment = event.target.value as EstimatorHostedConfigurationEnvironment;
    setSelectedEnvironment(environment);

    if (props.users.length === 1) {
      const user = props.users[0];
      const config = await props.getConfigForUser(environment, user);

      if (!config) {
        props.closeModal();
        return;
      }

      setOriginalConfiguration(config);
      setConfiguration(config);
    } else {
      const config = getNewEstimatorConfig(environment);
      setOriginalConfiguration(config);
      setConfiguration(config);
    }
  };

  const onSubmit = async (values: EstimatorHostedConfiguration): Promise<void> => {
    await props.onSaveClick(originalConfiguration!, values);
  };

  return (
    <Modal isOpen={true} size="xl">
      <ModalHeader className="justify-content-center">Estimator Configuration</ModalHeader>
      <ModalBody>
        {!configuration ? (
          <div className="container">
            <label htmlFor="configEnvironmentDropdown">Select an environment</label>
            <DropDownList
              id="configEnvironmentDropdown"
              data={['Test', 'Production']}
              value={selectedEnvironment}
              onChange={onEnvironmentDropdownChange}
            />
          </div>
        ) : (
          <Formik initialValues={configuration} onSubmit={onSubmit} validationSchema={FormSchema}>
            {({ errors, values, submitCount }): JSX.Element => (
              <Form>
                <div className="container-fluid">
                  <FieldArray name={nameof<EstimatorHostedConfiguration>('costDatabases')}>
                    {({ push, remove }): JSX.Element => (
                      <div>
                        <div className="row">
                          <div>Cost Databases</div>
                          <div className="ml-3">
                            <BTIconButton
                              tooltip="Add Cost Database"
                              icon="plus"
                              onClick={(): void => push({})}
                              tabIndex={-1}
                            />
                          </div>
                        </div>

                        {values.costDatabases.map((_, index) => {
                          return (
                            <DatabaseConnectionFormSection
                              key={`${nameof<EstimatorHostedConfiguration>(
                                'costDatabases',
                              )}.${index}`}
                              arrayName={nameof<EstimatorHostedConfiguration>('costDatabases')}
                              index={index}
                              remove={(): void => remove(index)}
                            />
                          );
                        })}
                      </div>
                    )}
                  </FieldArray>

                  <FieldArray name={nameof<EstimatorHostedConfiguration>('jobsDatabases')}>
                    {({ push, remove }): JSX.Element => (
                      <div className="mt-5">
                        <div className="row">
                          <div>Jobs Databases</div>
                          <div className="ml-3">
                            <BTIconButton
                              tooltip="Add Jobs Database"
                              icon="plus"
                              onClick={(): void => push({})}
                              tabIndex={-1}
                            />
                          </div>
                        </div>

                        {values.jobsDatabases.map((_, index) => {
                          return (
                            <DatabaseConnectionFormSection
                              key={`${nameof<EstimatorHostedConfiguration>(
                                'jobsDatabases',
                              )}.${index}`}
                              arrayName={nameof<EstimatorHostedConfiguration>('jobsDatabases')}
                              index={index}
                              remove={(): void => remove(index)}
                            />
                          );
                        })}
                      </div>
                    )}
                  </FieldArray>
                </div>

                <FormFooter>
                  <div className="d-flex mt-4">
                    <BTButton text="Save" type="submit" />
                    <div className="ml-3">
                      <BTButton
                        text="Discard"
                        color="gray"
                        onClick={(): void => {
                          props.onDiscardClick(originalConfiguration!, values);
                        }}
                      />
                    </div>
                  </div>
                  {submitCount > 0 && (
                    <FormErrorContainer errorMessages={getFormErrorMessages(errors)} />
                  )}
                </FormFooter>
              </Form>
            )}
          </Formik>
        )}
      </ModalBody>
    </Modal>
  );
};

const DatabaseConnectionFormSection: React.FC<{
  arrayName: string;
  index: number;
  remove: () => void;
}> = props => {
  const namePrefix = `${props.arrayName}.${props.index}`;

  return (
    <div className="databaseConnectionFormSection">
      <div className="row d-flex">
        <div className="col-11">
          <FormTextInput
            label="Name"
            name={`${namePrefix}.${nameof<DatabaseConnection>('name')}`}
          />
        </div>
        <div className="col-1 mt-4">
          <BTIconButton tooltip="Delete" icon="trash" onClick={props.remove} tabIndex={-1} />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6 col-md-12">
          <FormTextInput
            label="Server"
            name={`${namePrefix}.${nameof<DatabaseConnection>('server')}`}
          />
        </div>
        <div className="col-lg-6 col-md-12">
          <FormTextInput
            label="Database"
            name={`${namePrefix}.${nameof<DatabaseConnection>('database')}`}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6 col-md-12">
          <FormTextInput
            label="Username"
            name={`${namePrefix}.${nameof<DatabaseConnection>('username')}`}
          />
        </div>
        <div className="col-lg-6 col-md-12">
          <FormTextInput
            label="Password"
            name={`${namePrefix}.${nameof<DatabaseConnection>('password')}`}
            type="password"
          />
        </div>
      </div>
    </div>
  );
};

const DatabaseConnectionSchema = Yup.object().shape<DatabaseConnection>({
  name: Yup.string().required('Name is required'),
  server: Yup.string().required('Server is required'),
  database: Yup.string().required('Database is required'),
  username: Yup.string().required('Username is required'),
  password: Yup.string().required('Password is required'),
});

const FormSchema = Yup.object().shape<EstimatorHostedConfiguration>({
  environment: Yup.mixed<EstimatorHostedConfigurationEnvironment>().oneOf(['Test', 'Production']),
  costDatabases: Yup.array().of(DatabaseConnectionSchema),
  jobsDatabases: Yup.array().of(DatabaseConnectionSchema),
});

const getNewEstimatorConfig = (
  environment: EstimatorHostedConfigurationEnvironment,
): EstimatorHostedConfiguration => ({
  environment: environment,
  costDatabases: [{ name: '', server: '', database: '', username: '', password: '' }],
  jobsDatabases: [{ name: '', server: '', database: '', username: '', password: '' }],
});

export default EstimatorConfigurationModal;
