import React, { useContext, useRef, useEffect, MutableRefObject } from 'react';
import BTButton from '../controls/button/BTButton';
import './ConfirmationOverlay.scss';
import { ConfirmationOverlayContext } from './ConfirmationOverlayContext';

const ConfirmationOverlay: React.FC<{}> = () => {
  const { confirmationOverlayDisplayInfo, hideConfirmationOverlay } = useContext(
    ConfirmationOverlayContext,
  );

  const firstButtonRef: MutableRefObject<HTMLButtonElement | null> = useRef(null);
  const lastButtonRef: MutableRefObject<HTMLButtonElement | null> = useRef(null);

  const loadingPageOverlayCssClasses = ['confirmationOverlay'];

  // Don't even show the overlay if it hasn't explicitly been toggled
  if (!confirmationOverlayDisplayInfo.hasBeenToggled) {
    loadingPageOverlayCssClasses.push('d-none');
  } else if (confirmationOverlayDisplayInfo.isDisplayed) {
    loadingPageOverlayCssClasses.push('isDisplayed');
  }

  const onTab = (event: React.KeyboardEvent<HTMLDivElement>): void => {
    if (event.shiftKey) {
      if (document.activeElement === firstButtonRef.current) {
        event.preventDefault();
        (lastButtonRef.current as HTMLButtonElement).focus();
      }
    } else {
      //if tabbing forward
      if (document.activeElement === lastButtonRef.current) {
        event.preventDefault();
        (firstButtonRef.current as HTMLButtonElement).focus();
      }
    }
  };

  // Focus overlay params if not null
  useEffect(() => {
    if (confirmationOverlayDisplayInfo.currentConfirmationOverlayParameters) {
      (firstButtonRef.current as HTMLButtonElement).focus();
    }
  }, [confirmationOverlayDisplayInfo.currentConfirmationOverlayParameters]);

  return (
    confirmationOverlayDisplayInfo.currentConfirmationOverlayParameters && (
      <div
        className={loadingPageOverlayCssClasses.join(' ')}
        tabIndex={0}
        onKeyDown={(event): void => {
          // Hide the overlay when the escape key is pressed
          if (event.keyCode === 27) {
            hideConfirmationOverlay();
          }

          // Restrict tabbing to the overlay buttons
          if (event.keyCode === 9) {
            onTab(event);
          }
        }}
      >
        <div className="container">
          <div className="confirmationOverlayTitle">
            {confirmationOverlayDisplayInfo.currentConfirmationOverlayParameters!.title}
          </div>
          <div className="confirmationOverlayText">
            {confirmationOverlayDisplayInfo.currentConfirmationOverlayParameters!.text}
          </div>
          <div className="confirmationOverlayButtonsArea">
            {confirmationOverlayDisplayInfo.currentConfirmationOverlayParameters!.buttons.map(
              (currButton, index) => {
                const checkIfLastItem = (
                  index: number,
                ): MutableRefObject<HTMLButtonElement | null> | null => {
                  if (
                    index ===
                    confirmationOverlayDisplayInfo.currentConfirmationOverlayParameters!.buttons
                      .length -
                      1
                  ) {
                    return lastButtonRef;
                  } else {
                    return null;
                  }
                };

                return (
                  <BTButton
                    ref={index === 0 ? firstButtonRef : checkIfLastItem(index)}
                    key={currButton.text}
                    color={currButton.color}
                    text={currButton.text}
                    onClick={currButton.onClick}
                  />
                );
              },
            )}
          </div>
        </div>
      </div>
    )
  );
};

export default ConfirmationOverlay;
