import { isEqual } from 'lodash';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { Collapse } from 'reactstrap';
import { scrollToRef } from '../../../../utils/general';
import BTCardsContainer from '../../../common-page-components/cards-container/BTCardsContainer';
import BTFloatingIconButton from '../../../common-page-components/controls/floating-icon-button/BTFloatingIconButton';
import { NotificationPanelContext } from '../../../common-page-components/notification-panel/NotificationPanelContext';
import ApplicationRoutes from './../../../../constants/routes';
import { ClientApi, LicenseApi, ProductApi } from './../../../../services/api';
import { ConfirmationOverlayContext } from './../../../common-page-components/confirmation-overlay/ConfirmationOverlayContext';
import { LoadingPageOverlayContext } from './../../../common-page-components/loading-page-overlay/LoadingPageOverlayContext';
import LicenseEditForm from './components/LicenseEditForm';
import LicenseListing from './components/LicenseListing';
import './LicensesPage.scss';
import licensesApiService from '../../../../services/api/licenses/licenses-api-service';
import LicensesConstants from '../../../../constants/licenses';

const LicensesPage: React.FC<{}> = () => {
  const history = useHistory();

  const { showPageLoadingOverlay, hidePageLoadingOverlay } = useContext(LoadingPageOverlayContext);
  const { showConfirmationOverlay, hideConfirmationOverlay } = useContext(
    ConfirmationOverlayContext,
  );
  const { showSuccessNotification, showInfoNotification, showErrorNotification } = useContext(
    NotificationPanelContext,
  );

  const [licenses, setLicenses] = useState<License[]>([]);
  const [availableProducts, setAvailableProducts] = useState<Product[]>([]);
  const [availableClientOrganizations, setAvailableClientOrganizations] = useState<
    ClientOrganization[]
  >([]);
  const [isEditAreaOpen, setIsEditAreaOpen] = useState<boolean>(false);
  const [licenseToEdit, setLicenseToEdit] = useState<License | null>();
  const [isPageDataLoaded, setIsPageDataLoaded] = useState<boolean>(false);

  const adminPageEditAreaRef = useRef(null);

  // TODO: we are not currently getting enough data back from the `getLicenses` API call so we
  // must hydrate manually. This should be removed once we update the API call.
  const hydrateLicense = (
    license: License,
    clientOrgs: ClientOrganization[],
    products: Product[],
  ): void => {
    const clientOrg = clientOrgs.find(clientOrg => clientOrg.id === license.clientOrganization.id);

    if (!clientOrg) {
      throw new Error(
        `Could not find Client Organization with ID '${license.clientOrganization.id}'`,
      );
    }

    license.clientOrganization.name = clientOrg.name;
    license.clientOrganization.domains = clientOrg.domains;

    const product = products.find(product => product.id === license.product.id);

    if (!product) {
      throw new Error(`Could not find Product with ID '${license.product.id}'`);
    }

    license.product.name = product.name;

    license.product.features.forEach(feature => {
      const productFeature = product.features.find(
        productFeature => productFeature.id === feature.id,
      );

      if (!productFeature) {
        throw new Error(
          `Could not find Feature with ID '${feature.id}' on Product with ID '${product.id}'`,
        );
      }

      feature.name = productFeature.name;
      feature.hasActiveLicenses = productFeature.hasActiveLicenses;
    });

    license.product.deploymentTypeId = product.deploymentTypeId;
  };

  // Initial load of data
  useEffect(() => {
    const getAndSetLicensesData = async (): Promise<void> => {
      try {
        showPageLoadingOverlay();

        const [licensesData, availableProductsData, clientOrganizationsData] = await Promise.all([
          LicenseApi.getLicenses(),
          ProductApi.getProducts(),
          ClientApi.getClientOrganizations(),
        ]);

        // TODO: we are not currently getting enough data back from the `getLicenses` API call so we
        // must hydrate manually. This should be removed once we update the API call.
        licensesData.forEach(license =>
          hydrateLicense(license, clientOrganizationsData, availableProductsData),
        );

        setAvailableProducts(availableProductsData);
        setAvailableClientOrganizations(clientOrganizationsData);
        setLicenses(licensesData);

        setIsPageDataLoaded(true);
      } catch (error) {
        // In this case, we should send the user to an error page since this page isn't very useful without this core data
        showErrorNotification('Error', 'An error occurred while loading the page.');
        history.push(ApplicationRoutes.ERROR_ERROR);
      } finally {
        hidePageLoadingOverlay();
      }
    };

    getAndSetLicensesData();
  }, [hidePageLoadingOverlay, history, showPageLoadingOverlay, showErrorNotification]);

  const onSubmit = async (license: License): Promise<void> => {
    showPageLoadingOverlay();

    const closeEditAreaAndClearOutEditModel = (): void => {
      setIsEditAreaOpen(false);

      // Delay this slightly so the edit area has time to animate closed
      setTimeout(() => {
        setLicenseToEdit(null);
      }, 250);
    };

    try {
      if (license.isNew) {
        try {
          const newLicense = await LicenseApi.addLicense(license);
          hydrateLicense(newLicense, availableClientOrganizations, availableProducts);
          setLicenses([...licenses, newLicense]);

          closeEditAreaAndClearOutEditModel();

          showSuccessNotification('Success', 'The license was created successfully.');
        } catch {
          showErrorNotification(
            'Error',
            'An error occurred while attempting to create the license.',
          );
        }
      } else {
        try {
          if (isEqual(license, licenseToEdit)) {
            closeEditAreaAndClearOutEditModel();

            showInfoNotification('No Changes', 'No changes were made to the license.');
            return;
          }

          await LicenseApi.editLicense(license);
          const licensesClone = [...licenses];

          const index = licensesClone.findIndex(l => l.id === license.id);

          if (index === -1) {
            throw new Error(`Could not find license with ID '${license.id}'.`);
          }

          licensesClone[index] = license;

          setLicenses(licensesClone);

          closeEditAreaAndClearOutEditModel();

          showSuccessNotification('Success', 'The license was updated successfully.');
        } catch {
          showErrorNotification(
            'Error',
            'An error occurred while attempting to update the license.',
          );
        }
      }
    } finally {
      hidePageLoadingOverlay();
    }
  };

  const onDiscard = (license: License): void => {
    const discardChanges = (): void => {
      setIsEditAreaOpen(false);

      // Delay this slightly so the edit area has time to animate closed
      setTimeout(() => {
        setLicenseToEdit(null);
      }, 250);
    };

    if (!isEqual(license, licenseToEdit)) {
      showConfirmationOverlay({
        title: 'Confirm',
        text: 'Are you sure you want to discard your unsaved changes?',
        buttons: [
          {
            text: 'Yes',
            onClick: (): void => {
              discardChanges();
              hideConfirmationOverlay();
            },
            color: 'normal',
          },
          {
            text: 'No',
            onClick: (): void => {
              hideConfirmationOverlay();
            },
            color: 'gray',
          },
        ],
      });
    } else {
      discardChanges();
    }
  };

  const editLicenseButtonClick = (license: License | null): void => {
    if (isEditAreaOpen) {
      return;
    }

    if (!license) {
      license = {
        id: '',
        clientOrganization: { id: '', name: '', domains: [] },
        product: { id: '', name: '', features: [], deploymentTypeId: null },
        effectiveDate: new Date(),
        expirationDate: new Date(),
        enforceStrictUserCount: false,
        userCount: 1,
        isNew: true,
        deploymentDetails: {},
        deploymentStatus: null,
        adminUser: '',
      };
    }

    setLicenseToEdit({ ...license });

    // Delay this slightly so the edit area has time to animate in
    setTimeout(() => {
      scrollToRef(adminPageEditAreaRef);
    }, 500);

    setIsEditAreaOpen(true);
  };

  const deleteLicenseButtonClick = (license: License): void => {
    showConfirmationOverlay({
      title: 'Confirm',
      text: `Are you sure you want to delete the license for "${license.clientOrganization.name}" / "${license.product.name}"?`,
      buttons: [
        {
          text: 'Yes',
          onClick: async (): Promise<void> => {
            try {
              hideConfirmationOverlay();
              showPageLoadingOverlay();

              await LicenseApi.deleteLicense(license.id);
              const newLicenses = licenses.filter(l => l.id !== license.id);
              setLicenses(newLicenses);

              showSuccessNotification('Success', 'The license was deleted successfully.');
            } catch {
              showErrorNotification(
                'Error',
                'An error occurred while attempting to delete the license.',
              );
            } finally {
              hidePageLoadingOverlay();
            }
          },
          color: 'normal',
        },
        {
          text: 'No',
          onClick: (): void => {
            hideConfirmationOverlay();
          },
          color: 'gray',
        },
      ],
    });
  };

  const requestDeploymentButtonClick = async (license: License): Promise<void> => {
    if (![null, LicensesConstants.DeploymentStatuses.NONE].includes(license.deploymentStatus)) {
      return;
    }

    showConfirmationOverlay({
      title: 'Confirm',
      text: `Are you sure you want to run a deployment to set up "${license.product.name}" for "${license.clientOrganization.name}"?`,
      buttons: [
        {
          text: 'Yes',
          onClick: async (): Promise<void> => {
            try {
              hideConfirmationOverlay();

              showPageLoadingOverlay();

              await licensesApiService.requestDeployment(
                license.id,
                license.adminUser!,
                license.clientOrganization.domains,
              );

              license.deploymentStatus = LicensesConstants.DeploymentStatuses.REQUESTED;

              showSuccessNotification(
                'Success',
                'The deployment was successfully requested. Refresh to view status.',
              );
            } catch {
              showErrorNotification('Error', 'The deployment request could not complete.');
            } finally {
              hidePageLoadingOverlay();
            }
          },
        },
        {
          text: 'No',
          onClick: (): void => hideConfirmationOverlay(),
          color: 'gray',
        },
      ],
    });
  };

  const redeployButtonClick = async (license: License): Promise<void> => {
    if (
      ![
        LicensesConstants.DeploymentStatuses.COMPLETED,
        LicensesConstants.DeploymentStatuses.ERROR,
      ].includes(license.deploymentStatus || '')
    ) {
      return;
    }

    showConfirmationOverlay({
      title: 'Confirm',
      text: `Are you sure you want to redeploy "${license.product.name}" for "${license.clientOrganization.name}"?`,
      buttons: [
        {
          text: 'Yes',
          onClick: async (): Promise<void> => {
            try {
              hideConfirmationOverlay();

              showPageLoadingOverlay();

              // Reset deployment details
              await licensesApiService.resetDeploymentDetails(license.id);

              // Request new deployment
              await licensesApiService.requestDeployment(
                license.id,
                license.adminUser!,
                license.clientOrganization.domains,
              );
              license.deploymentStatus = LicensesConstants.DeploymentStatuses.REQUESTED;

              showSuccessNotification(
                'Success',
                'The deployment was successfully requested. Refresh to view status.',
              );
            } catch {
              showErrorNotification('Error', 'The deployment request could not complete.');
            } finally {
              hidePageLoadingOverlay();
            }
          },
        },
        {
          text: 'No',
          onClick: (): void => hideConfirmationOverlay(),
          color: 'gray',
        },
      ],
    });
  };

  if (!isPageDataLoaded) {
    return <></>;
  }

  return (
    <>
      <div className="container">
        <h1>Licenses</h1>
      </div>

      <div className="adminPageEditArea" ref={adminPageEditAreaRef}>
        <Collapse isOpen={isEditAreaOpen}>
          <div className="container-fluid grayBackground">
            <div className="container">
              {licenseToEdit && (
                <LicenseEditForm
                  license={licenseToEdit}
                  availableClientOrganizations={availableClientOrganizations}
                  availableProducts={availableProducts}
                  existingLicenses={licenses}
                  onSubmitClick={onSubmit}
                  onDiscardClick={onDiscard}
                />
              )}
            </div>
          </div>
        </Collapse>
      </div>

      <div className="container">
        <BTCardsContainer enableSearch recordsNotFoundMessage="No licenses were found.">
          {licenses.map(currLicense => {
            return (
              <LicenseListing
                key={currLicense.id}
                license={currLicense}
                isEditAreaOpen={isEditAreaOpen}
                editLicenseButtonClick={editLicenseButtonClick}
                deleteLicenseButtonClick={deleteLicenseButtonClick}
                requestDeploymentButtonClick={requestDeploymentButtonClick}
                redeployButtonClick={redeployButtonClick}
              />
            );
          })}
        </BTCardsContainer>
      </div>

      <BTFloatingIconButton
        buttonIcon={'plus'}
        onClick={(): void => {
          editLicenseButtonClick(null);
        }}
        tooltip={'Add new license'}
        disabled={isEditAreaOpen}
      />
    </>
  );
};

export default LicensesPage;
