import { UserRolesDisplayNames, UserRolesValues } from './user-roles';

const UserConstants = {
  UserRolesValues: {
    CLIENTUSER: UserRolesValues.ClientUser,
    INTERNALUSER: UserRolesValues.InternalUser,
    ADMIN: UserRolesValues.Admin,
    BIDDAYSUPPORT: UserRolesValues.BidDaySupport,
  },
  UserRolesDisplayNames: {
    CLIENTUSER: UserRolesDisplayNames.ClientUser,
    INTERNALUSER: UserRolesDisplayNames.InternalUser,
    ADMIN: UserRolesDisplayNames.Admin,
    BIDDAYSUPPORT: UserRolesDisplayNames.BidDaySupport,
  },
};

export default UserConstants;
