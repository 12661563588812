import moment from 'moment';

export const applyPartialDateRangeRules = (
  date: Date,
  increment: string,
  isFromDate: boolean,
): Date => {
  let newMomentObj = moment(date);
  switch (increment) {
    case 'Week': {
      if (isFromDate) {
        newMomentObj = newMomentObj.startOf('week');
      } else {
        newMomentObj = newMomentObj.endOf('week');
      }
      break;
    }
    case 'Month': {
      if (isFromDate) {
        newMomentObj = newMomentObj.startOf('month');
      } else {
        newMomentObj = newMomentObj.endOf('month');
      }
      break;
    }
  }

  return newMomentObj.toDate();
};
