import React from 'react';
import {
  ChartCategoryAxisItem,
  TooltipContext,
  SharedTooltipContext,
} from '@progress/kendo-react-charts';
import { CategoryBaseUnit } from '@progress/kendo-react-charts/dist/npm/common/property-types';
import moment from 'moment';

const getChartCategoryAxisItem = (increment: string): JSX.Element => {
  switch (increment) {
    case 'Day':
    case 'Week':
    case 'Month': {
      const baseUnit = (increment.toLowerCase() + 's') as CategoryBaseUnit;
      return <ChartCategoryAxisItem maxDivisions={9} baseUnit={baseUnit} />;
    }

    default: {
      return <ChartCategoryAxisItem />;
    }
  }
};

const defaultTooltipRender = (props: TooltipContext | SharedTooltipContext): React.ReactNode => {
  return (
    <div>
      {`${moment(new Date((props as TooltipContext).point.category)).format(
        'M/D/YYYY',
      )} - Checkouts: ${(props as TooltipContext).point.value}`}
    </div>
  );
};

const customTooltipRender = (
  props: TooltipContext | SharedTooltipContext,
  dateFormat: string,
  tooltipLabel: string,
): React.ReactNode => {
  return (
    <div>
      {`${moment(new Date((props as TooltipContext).point.category)).format(
        dateFormat,
      )} - ${tooltipLabel}: ${(props as TooltipContext).point.value}`}
    </div>
  );
};

export { getChartCategoryAxisItem, defaultTooltipRender, customTooltipRender };
