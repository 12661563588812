const ADMIN_ROOT = 'admin';

// NOTE: When creating 'full' routes, we use an empty string as the first element in the array we are concatenating so we get a leading forward slash
const AdminRoutes = {
  Clients: ['', ADMIN_ROOT, 'clients'].join('/'),
  Products: ['', ADMIN_ROOT, 'products'].join('/'),
  Licenses: ['', ADMIN_ROOT, 'licenses'].join('/'),
  SiteSettings: ['', ADMIN_ROOT, 'settings'].join('/'),
  Users: ['', ADMIN_ROOT, 'users'].join('/'),
  EstimatorHostedUserManagement: ['', ADMIN_ROOT, 'estimator-hosted-user-management'].join('/'),
};

export default AdminRoutes;
