import { connect, Form, Formik, FormikProps } from 'formik';
import { isEqual, sortBy } from 'lodash';
import React, { useState } from 'react';
import * as Yup from 'yup';
import { UserRolesDisplayNames, UserRolesValues } from '../../../../../constants/users/user-roles';
import { getFormErrorMessages } from '../../../../../utils/forms';
import BTButton from '../../../../common-page-components/controls/button/BTButton';
import FormErrorContainer from '../../../../common-page-components/forms/form-error-container/FormErrorContainer';
import FormFooter from '../../../../common-page-components/forms/FormFooter';
import FormMultiSelect, {
  FormMultiSelectOption,
} from '../../../../common-page-components/forms/FormMultiSelect';
import FormTextInput from '../../../../common-page-components/forms/FormTextInput';

const FormSchema = Yup.object().shape<User>({
  id: Yup.string().nullable(),
  isNew: Yup.boolean().required(),
  isBeckTechUser: Yup.boolean(),
  emailAddress: Yup.string()
    .trim()
    .email('The email address is not valid')
    .required('Email address is a required field'),
  roles: Yup.array()
    .of(
      Yup.object().shape<UserRole>({
        id: Yup.string(),
        name: Yup.string(),
      }),
    )
    .min(1, 'At least one role must be specified'),
});

interface Props {
  user: User;
  allUsers: User[];
  onSubmitClick: (user: User) => void;
  onDiscardClick: (user: User) => void;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const FormikEffect = connect((props: any) => {
  props.onFormChanged(props.formik);
  return null;
});

const isBeckTechEmailAddress = (emailAddress: string): boolean => {
  return /@beck-technology\.com$/i.test(emailAddress);
};

const UserEditForm: React.FC<Props> = ({ user, allUsers, onSubmitClick, onDiscardClick }) => {
  const [isEmailAddressBeckTech, setIsEmailAddressBeckTech] = useState<boolean>();

  const rolesBeckTechUser: UserRole[] = [
    { id: UserRolesValues.ClientUser, name: UserRolesDisplayNames.ClientUser },
    { id: UserRolesValues.InternalUser, name: UserRolesDisplayNames.InternalUser },
    { id: UserRolesValues.Admin, name: UserRolesDisplayNames.Admin },
    { id: UserRolesValues.BidDaySupport, name: UserRolesDisplayNames.BidDaySupport },
  ];
  const rolesNonBeckTechUser: UserRole[] = [
    { id: UserRolesValues.ClientUser, name: UserRolesDisplayNames.ClientUser },
  ];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onFormChanged = (form: FormikProps<any>): void => {
    if (form && form.values) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const formValues = form.values as any;
      const currEmailAddress = formValues.emailAddress || '';
      // Order the roles first, so we have consistent behavior between runs of this code
      const currRoles: UserRole[] = sortBy(formValues.roles || [], (v: UserRole) => v.id);

      // The roles the user can select depend on the entered email address.
      // If we are going down to a more restrictive set of available roles because the email address is not a Beck Tech email,
      // we need to adjust the already selected roles.
      const emailIsBechTechEmail = isBeckTechEmailAddress(currEmailAddress);
      setIsEmailAddressBeckTech(emailIsBechTechEmail);

      if (!emailIsBechTechEmail) {
        // Order the roles first, so we have consistent behavior between runs of this code
        const newUserRoles = sortBy(
          rolesNonBeckTechUser.filter(v => currRoles.some(m => m.id === v.id)),
          v => v.id,
        );

        // NOTE: Setting the form value will trigger this function to run again so we have to make sure we only set it when it's different,
        // otherwise we'll trigger an infinite loop, and who knows how long that'll take to run....
        if (!isEqual(newUserRoles, currRoles)) {
          form.setFieldValue('roles', newUserRoles);
        }
      }
    }
  };

  const getAvailableUserRoles = (): FormMultiSelectOption[] => {
    return isEmailAddressBeckTech ? rolesBeckTechUser : rolesNonBeckTechUser;
  };

  const uniqueEmailValidator = (emailAddress: string): string | void => {
    emailAddress = emailAddress.trim().toLowerCase();

    // NOTE: When we are editing a user, the email address is not editable so we don't have to worry about
    // checking to make sure the user with the same email address isn't the user being edited or if the
    // user object is new
    if (allUsers.find(v => v.emailAddress.trim().toLowerCase() === emailAddress)) {
      return 'The email address must be unique.';
    }
  };

  return (
    <div className="editForm">
      <h2>{`${user && user.isNew ? 'Create' : 'Edit'} User`}</h2>

      {user && (
        <>
          <Formik initialValues={user} onSubmit={onSubmitClick} validationSchema={FormSchema}>
            {({ errors, values, submitCount }): JSX.Element => (
              <>
                <FormikEffect onFormChanged={onFormChanged} />
                <Form>
                  {
                    <>
                      <div className="row">
                        {!user.isNew ? (
                          <>
                            <div className="col-12 col-lg-6">
                              <label>Email</label>
                              <div>{user.emailAddress}</div>
                            </div>
                          </>
                        ) : (
                          <div className="col-12 col-lg-6">
                            <FormTextInput
                              label="Email Address"
                              name="emailAddress"
                              validator={uniqueEmailValidator}
                            />
                          </div>
                        )}

                        <div className="col-12">
                          <FormMultiSelect
                            data={getAvailableUserRoles()}
                            placeholderText="Roles"
                            label="Roles"
                            name="roles"
                          />
                        </div>
                      </div>
                    </>
                  }

                  <FormFooter>
                    <div className="buttonsArea">
                      <BTButton text="Save" type="submit" />
                      <BTButton
                        text="Discard"
                        color="gray"
                        onClick={(): void => {
                          onDiscardClick(values);
                        }}
                      />
                    </div>
                    {submitCount > 0 && (
                      <FormErrorContainer errorMessages={getFormErrorMessages(errors)} />
                    )}
                  </FormFooter>
                </Form>
              </>
            )}
          </Formik>
        </>
      )}
    </div>
  );
};

export default UserEditForm;
