import {
  Chart,
  ChartCategoryAxis,
  ChartLegend,
  ChartSeries,
  ChartSeriesItem,
  ChartTitle,
  ChartTooltip,
  SharedTooltipContext,
  TooltipContext,
} from '@progress/kendo-react-charts';
import React from 'react';
import ChartDefaults from '../../../../../constants/charts';
import { customTooltipRender, getChartCategoryAxisItem } from '../../../../../utils/charts';

const LicenseDurationChart: React.FC<ReportChartProps> = props => {
  const {
    data: {
      chartSeries,
      appliedFilters: { specificFilters },
    },
  } = props;

  const durationFilters = specificFilters as ReportFiltersWithIncrement;

  const tooltipRender = (props: TooltipContext | SharedTooltipContext): React.ReactNode => {
    return customTooltipRender(props, 'M/D/YYYY', 'Hours');
  };

  return (
    <div data-testid="license-duration-chart">
      <Chart>
        <ChartTooltip render={tooltipRender} />
        <ChartTitle text={`Average Checkout Duration Per ${durationFilters.increment}`} />
        <ChartLegend position="top" orientation="horizontal" />
        <ChartSeries>
          {chartSeries.map((cs, idx) => {
            return (
              <ChartSeriesItem
                key={idx}
                type="column"
                name={cs.name}
                field="value"
                aggregate={'sum'}
                categoryField="label"
                data={cs.data}
                color={
                  cs.name === 'Automatic' ? ChartDefaults.colors.blue : ChartDefaults.colors.red
                }
              />
            );
          })}
        </ChartSeries>
        <ChartCategoryAxis>{getChartCategoryAxisItem(durationFilters.increment)}</ChartCategoryAxis>
      </Chart>
    </div>
  );
};

export default LicenseDurationChart;
