import React, { useState, useContext } from 'react';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import BTButton from '../../../common-page-components/controls/button/BTButton';
import { NotificationPanelContext } from '../../../common-page-components/notification-panel/NotificationPanelContext';
import { loadTranslationsForLocale } from '../../../../internationalization/i18n';
import './SiteSettingsPage.scss';
import { useTranslation } from 'react-i18next';

interface Language {
  name: string;
  value: string;
}

const languages: Language[] = [
  { name: 'English (US)', value: 'en-US' },
  { name: 'French (Canada)', value: 'fr-CA' },
];

const SiteSettingsPage: React.FC = () => {
  const { t, i18n } = useTranslation();
  const { showSuccessNotification, showErrorNotification } = useContext(NotificationPanelContext);

  // NOTE: it's okay if this is undefined, there just won't be a lang selected in the dropdown
  const currentLang = languages.find(l => l.value === i18n.language);

  const [selectedLang, setSelectedLang] = useState<Language | undefined>(currentLang);

  return (
    <>
      <div className="container">
        <h1>Site Settings</h1>
      </div>

      <div className="container changeLanguage">
        <h2>Change Language</h2>
        <DropDownList
          textField="name"
          dataItemKey="value"
          defaultValue={currentLang}
          data={languages}
          value={selectedLang}
          onChange={(event): void => setSelectedLang(event.target.value)}
        />
        <BTButton
          text="Apply"
          disabled={!selectedLang}
          onClick={async (): Promise<void> => {
            try {
              // NOTE: button is only enabled when `selectedLang` has a value
              const locale = selectedLang!.value;

              await i18n.changeLanguage(locale);
              loadTranslationsForLocale(locale);
              showSuccessNotification('Success', 'The language was updated successfully.');
            } catch (ex) {
              showErrorNotification(
                'Error',
                'An error occurred while attempting to update the language.',
              );
            }
          }}
        />

        <div>
          <label>Short Date Test</label> {t('general:shortDateFormat', { date: new Date() })}
        </div>

        <div>
          <label>Long Date Test</label> {t('general:longDateFormat', { date: new Date() })}
        </div>

        <div>
          <label>Integer Test</label> {t('general:numberFormat', { number: 1234567 })}
        </div>

        <div>
          <label>Decimal test</label> {t('general:numberFormat', { number: 1234567.89 })}
        </div>
      </div>
    </>
  );
};

export default SiteSettingsPage;
