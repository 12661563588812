import { DropDownList, DropDownListChangeEvent } from '@progress/kendo-react-dropdowns';
import { GridFilterCellProps } from '@progress/kendo-react-grid';
import React from 'react';

interface Props extends GridFilterCellProps {
  listValues: any[]; // eslint-disable-line @typescript-eslint/no-explicit-any
  textField?: string;
  dataItemKey?: string;
  valueField?: string;
  filterOperator?: 'eq' | 'contains';
}

const GridDropdownFilterCell: React.FC<Props> = props => {
  const filterOperator = props.filterOperator || 'eq';

  const onChange = (e: DropDownListChangeEvent): void => {
    props.onChange({
      value: props.valueField ? e.target.value[props.valueField] : e.target.value,
      operator: e.target.value ? filterOperator : '',
      syntheticEvent: e.syntheticEvent,
    });
  };

  const onClearClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    event.preventDefault();
    event.stopPropagation();
    props.onChange({
      value: '',
      operator: '',
      syntheticEvent: event,
    });
  };

  return (
    <div className="k-filtercell">
      <DropDownList
        data={props.listValues}
        textField={props.textField}
        dataItemKey={props.dataItemKey}
        onChange={onChange}
        value={
          props.valueField
            ? props.listValues.find(listValue => listValue[props.valueField!] === props.value) || ''
            : props.value
        }
      />
      <button
        className="k-button k-button-icon k-clear-button-visible"
        title="Clear"
        disabled={!props.value}
        onClick={onClearClick}
      >
        <span className="k-icon k-i-filter-clear" />
      </button>
    </div>
  );
};

export default GridDropdownFilterCell;
