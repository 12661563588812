import { DropDownList } from '@progress/kendo-react-dropdowns';
import React, { useEffect, useCallback } from 'react';
import { ReportNames } from '../../reports';
import { UsageApi } from '../../../../../services/api';
import { useHistory } from 'react-router';
import ApplicationRoutes from '../../../../../constants/routes';
import { useTranslation } from 'react-i18next';
import { applyPartialDateRangeRules } from '../../../../../utils/calendars';
import ReportFilterConstants from './../../../../../constants/reports/filters';

interface Props {
  usageReportFilters: ReportFiltersWithIncrement;
}

const LicenseUsageFilter: React.FC<ReportChartFilterProps & Props> = ({
  reportFilters,
  usageReportFilters,
  setReportDataSource,
  setSpecificReportFilters,
  setCalendarType,
}) => {
  const { t } = useTranslation();
  const history = useHistory();

  const updateReportDataSource = useCallback((): void => {
    setReportDataSource(ReportNames.Usage, () => {
      switch (usageReportFilters.increment) {
        case ReportFilterConstants.FILTERINCREMENT_DAY: {
          return UsageApi.getUsageByDay(
            reportFilters.fromDate,
            reportFilters.toDate,
            reportFilters.client,
            reportFilters.product &&
              reportFilters.product.id === ReportFilterConstants.PRODUCTOPTION_ALL_PRODUCTS
              ? null
              : reportFilters.product,
            'distinct',
          );
        }
        case ReportFilterConstants.FILTERINCREMENT_WEEK: {
          return UsageApi.getUsageByWeek(
            applyPartialDateRangeRules(reportFilters.fromDate, reportFilters.calendarType, true),
            applyPartialDateRangeRules(reportFilters.toDate, reportFilters.calendarType, false),
            reportFilters.client,
            reportFilters.product &&
              reportFilters.product.id === ReportFilterConstants.PRODUCTOPTION_ALL_PRODUCTS
              ? null
              : reportFilters.product,
            'distinct',
          );
        }
        case ReportFilterConstants.FILTERINCREMENT_MONTH: {
          return UsageApi.getUsageByMonth(
            applyPartialDateRangeRules(reportFilters.fromDate, reportFilters.calendarType, true),
            applyPartialDateRangeRules(reportFilters.toDate, reportFilters.calendarType, false),
            reportFilters.client,
            reportFilters.product &&
              reportFilters.product.id === ReportFilterConstants.PRODUCTOPTION_ALL_PRODUCTS
              ? null
              : reportFilters.product,
            'distinct',
          );
        }
        default: {
          // NOTE:  This is based on a fixed dropdown, so this case should never be hit. Returning
          // an empty promise to satisfy the return type, but the redirect will run prior.
          history.push(ApplicationRoutes.ERROR_ERROR);
          return new Promise<BTChartData>((): void => {});
        }
      }
    });
  }, [setReportDataSource, usageReportFilters, reportFilters, history]);

  const updateReportFilterValues = useCallback(
    (usageReportFilters: ReportFiltersWithIncrement): void => {
      setSpecificReportFilters(ReportNames.Usage, usageReportFilters);
      setCalendarType(usageReportFilters.increment);
      updateReportDataSource();
    },
    [setSpecificReportFilters, setCalendarType, updateReportDataSource],
  );

  // Set the data source values for this component when the generic report filters provided to this component change
  useEffect(() => {
    updateReportDataSource();
  }, [reportFilters, updateReportDataSource]);

  // If the usage report filters are not initialized, initialize them
  useEffect(() => {
    if (!usageReportFilters) {
      updateReportFilterValues({
        increment: ReportFilterConstants.FILTERINCREMENT_DAY,
      });
    }
  }, [updateReportFilterValues, usageReportFilters]);

  return (
    <div className="row" data-testid="reports-page-license-usage-chart-filters">
      <div className="col-12 col-md-3 mt-3">
        <label htmlFor="reportIntervalDropdown">{t('reporting:filters.reportInterval')}</label>
        <DropDownList
          id="reportIntervalDropdown"
          data={ReportFilterConstants.FILTERINCREMENT_DEFAULT_INCREMENTS}
          onChange={(event): void => {
            updateReportFilterValues({
              ...usageReportFilters,
              increment: event.target.value,
            });
          }}
          value={(usageReportFilters && usageReportFilters.increment) || ''}
        />
      </div>
    </div>
  );
};

export default LicenseUsageFilter;
