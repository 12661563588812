interface AddressDto {
  streetAddress: string;
  aptSuiteBldg: string;
  city: string;
  stateOrRegion?: string;
  country?: string;
  postalCode: string;
}

export interface ClientOrganizationAddOrEditDto {
  name: string;
  address?: AddressDto;
  accountExecutive: string;
  domains: string[];
  usesCorporateActiveDirectory: boolean;
}

export interface ClientOrganizationViewDto extends ClientOrganizationAddOrEditDto {
  id: string;
}

export const getClientOrganizationFromDto = (
  dto: ClientOrganizationViewDto,
): ClientOrganization => {
  const clientOrg: ClientOrganization = {
    id: dto.id,
    name: dto.name,
    accountExecutive: dto.accountExecutive,
    isNew: false,
    domains: dto.domains,
    address: !dto.address
      ? undefined
      : {
          streetAddress: dto.address.streetAddress,
          aptSuiteBldg: dto.address.aptSuiteBldg,
          city: dto.address.city,
          stateOrRegion: !dto.address.stateOrRegion
            ? undefined
            : { name: dto.address.stateOrRegion },
          country: !dto.address.country ? undefined : { name: dto.address.country },
          postalCode: dto.address.postalCode,
        },
    usesCorporateActiveDirectory: dto.usesCorporateActiveDirectory,
  };

  return clientOrg;
};

export const getDtoFromAddress = (address?: Address): AddressDto | undefined => {
  if (!address) {
    return undefined;
  }

  const dto: AddressDto = {
    streetAddress: address.streetAddress,
    aptSuiteBldg: address.aptSuiteBldg,
    city: address.city,
    stateOrRegion: address.stateOrRegion ? address.stateOrRegion.name : undefined,
    country: address.country ? address.country.name : undefined,
    postalCode: address.postalCode,
  };

  return dto;
};
