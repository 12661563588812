import React, { useState, useEffect, useRef, useContext, useCallback } from 'react';
import ClientOrganizationsApi from '../../../../services/api/clients/clients-api-service';
import { EstimatorHostedApi } from '../../../../services/api/estimator-hosted/estimator-hosted-service';
import {
  Grid,
  GridCellProps,
  GridColumn,
  GridFilterCellProps,
  GridFilterChangeEvent,
  GridHeaderSelectionChangeEvent,
  GridItemChangeEvent,
  GridSelectionChangeEvent,
  GridToolbar,
} from '@progress/kendo-react-grid';
import { CompositeFilterDescriptor, filterBy } from '@progress/kendo-data-query';
import { nameof } from '../../../../utils/nameof';
import { isEqual, sortBy } from 'lodash';
import BTButton from '../../../common-page-components/controls/button/BTButton';
import BTIconButton from '../../../common-page-components/controls/icon-button/BTIconButton';
import GridTextCell from './components/GridTextCell';
import GridDropdownCell from './components/GridDropdownCell';
import GridDropdownFilterCell from './components/GridDropdownFilterCell';
import GridMultiSelectCell from './components/GridMultiSelectCell';
import TemporaryPasswordModal, { TemporaryPasswordInfo } from './components/TemporaryPasswordModal';
import EstimatorConfigurationModal from './components/EstimatorConfigurationModal';
import { LoadingPageOverlayContext } from '../../../common-page-components/loading-page-overlay/LoadingPageOverlayContext';
import { ConfirmationOverlayContext } from '../../../common-page-components/confirmation-overlay/ConfirmationOverlayContext';
import { NotificationPanelContext } from '../../../common-page-components/notification-panel/NotificationPanelContext';
import './EstimatorHostedUserManagementPage.scss';
import { useHistory } from 'react-router-dom';
import ApplicationRoutes from '../../../../constants/routes';
import EstimatorCopyConfigurationModal from './components/EstimatorCopyConfigurationModal';
import AddUsersModal from './components/AddUsersModal';

interface GridHostedUser extends EstimatorHostedUser {
  inEdit: boolean;
  isNew: boolean;
  selected: boolean;
}

interface EstimatorConfigState {
  users: EstimatorHostedUser[];
}

const EstimatorHostedUserManagementPage: React.FC = () => {
  // Hooks
  const history = useHistory();

  // Contexts
  const { showPageLoadingOverlay, hidePageLoadingOverlay } = useContext(LoadingPageOverlayContext);
  const { showConfirmationOverlay, hideConfirmationOverlay } = useContext(
    ConfirmationOverlayContext,
  );
  const { showSuccessNotification, showErrorNotification, showInfoNotification } = useContext(
    NotificationPanelContext,
  );

  // State
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [clientOrgs, setClientOrgs] = useState<ClientOrganization[]>([]);
  const [userGroups, setUserGroups] = useState<EstimatorHostedUserGroup[]>([]);
  const [users, setUsers] = useState<GridHostedUser[]>([]);
  const [filter, setFilter] = useState<CompositeFilterDescriptor>({ logic: 'and', filters: [] });
  const [showAddUsersModal, setShowAddUsersModal] = useState<boolean>(false);
  const [tempPasswordInfo, setTemporaryPasswordInfo] = useState<TemporaryPasswordInfo[]>();
  const [estimatorConfigState, setEstimatorConfigState] = useState<EstimatorConfigState>();
  const [userToCopyConfigFrom, setUserToCopyConfigFrom] = useState<EstimatorHostedUser>();

  // Ref to the grid container div
  // Used as the element to anchor any droplist elements on
  const gridContainer = useRef<HTMLDivElement>(null);

  // Apply filter on users for each render
  const filteredUsers = filterBy(users, filter);

  // Selected users
  const selectedUsers = filteredUsers.filter(user => user.selected && !user.isNew);

  // Initial data load
  useEffect(() => {
    const loadData = async (): Promise<void> => {
      try {
        showPageLoadingOverlay();

        // Get clients
        const clients = await ClientOrganizationsApi.getClientOrganizations();
        setClientOrgs(sortBy(clients, client => client.name));

        // Get groups
        const groups = await EstimatorHostedApi.getGroups();
        setUserGroups(groups);

        // Get users
        const hostedUsers = await EstimatorHostedApi.getUsers();
        const gridUsers: GridHostedUser[] = hostedUsers.map(user => ({
          ...user,
          inEdit: false,
          isNew: false,
          selected: false,
        }));
        setUsers(sortUsers(gridUsers));

        setIsLoading(false);
      } catch {
        showErrorNotification('Error', 'Failed to load data.');
        history.push(ApplicationRoutes.ERROR_ERROR);
      } finally {
        hidePageLoadingOverlay();
      }
    };

    loadData();
  }, [hidePageLoadingOverlay, history, showErrorNotification, showPageLoadingOverlay]);

  const saveUsers = async (users: GridHostedUser[]): Promise<void> => {
    showPageLoadingOverlay();

    const passwordInfoToDisplay: TemporaryPasswordInfo[] = [];
    const failedUsers: GridHostedUser[] = [];

    try {
      for (const user of users) {
        try {
          if (user.isNew) {
            // Add user via API
            const newUser = await EstimatorHostedApi.addUser(
              user.displayName,
              user.clientOrganizationId,
              user.groupIds,
            );

            // Update state
            setUsers(users => {
              const usersClone = [...users];
              usersClone.push({ ...newUser, inEdit: false, isNew: false, selected: false });
              return sortUsers(usersClone);
            });

            // Save password info to display later
            passwordInfoToDisplay.push({
              name: newUser.displayName,
              email: newUser.emailAddress,
              password: newUser.temporaryPassword,
            });
          } else {
            // Update user via API
            await EstimatorHostedApi.updateUser(
              user.emailAddress,
              user.displayName,
              user.clientOrganizationId,
              user.groupIds,
            );

            // Set State
            setUsers(users => {
              const usersClone = [...users];
              const index = usersClone.findIndex(u => u.emailAddress === user.emailAddress);
              usersClone[index] = { ...usersClone[index], inEdit: false };
              return usersClone;
            });
          }
        } catch {
          failedUsers.push(user);
        }
      }

      // Notify of success/failure
      if (failedUsers.length === 0) {
        showSuccessNotification('Success', 'The save was successful');
      } else {
        showErrorNotification(
          'Error',
          `The following users failed to save: ${failedUsers
            .map(user => user.displayName)
            .join(', ')}`,
        );
      }

      // Display password info if necessary
      if (passwordInfoToDisplay.length > 0) {
        if (failedUsers.length === 0) {
          setTemporaryPasswordInfo(passwordInfoToDisplay);
        } else {
          // Allow enough time to read error message
          setTimeout(() => setTemporaryPasswordInfo(passwordInfoToDisplay), 2000);
        }
      }
    } finally {
      hidePageLoadingOverlay();
    }
  };

  const onAddUsersSaveClick = async (users: EstimatorHostedUser[]): Promise<void> => {
    setShowAddUsersModal(false);

    const gridUsers: GridHostedUser[] = users.map(user => ({
      ...user,
      isNew: true,
      inEdit: false,
      selected: false,
    }));

    await saveUsers(gridUsers);
  };

  const onAddUsersDiscardClick = async (users: EstimatorHostedUser[]): Promise<void> => {
    if (users.length === 0) {
      setShowAddUsersModal(false);
      return;
    }

    showConfirmationOverlay({
      title: 'Confirm',
      text: 'Are you sure you want to discard your unsaved changes?',
      buttons: [
        {
          text: 'Yes',
          onClick: (): void => {
            setShowAddUsersModal(false);
            hideConfirmationOverlay();
          },
          color: 'normal',
        },
        {
          text: 'No',
          onClick: (): void => {
            hideConfirmationOverlay();
          },
          color: 'gray',
        },
      ],
    });
  };

  const onCopyPasswordClick = async (): Promise<void> => {
    if (!tempPasswordInfo || tempPasswordInfo.length === 0) {
      return;
    }

    const linesToCopy = tempPasswordInfo.map(
      info => `Name: ${info.name}\nEmail: ${info.email}\nPassword: ${info.password}`,
    );
    const copyText = linesToCopy.join('\n\n');
    await navigator.clipboard.writeText(copyText);

    setTemporaryPasswordInfo(undefined);
  };

  const getConfigForUser = async (
    environment: EstimatorHostedConfigurationEnvironment,
    user: EstimatorHostedUser,
  ): Promise<EstimatorHostedConfiguration | null> => {
    try {
      showPageLoadingOverlay();
      const config = await EstimatorHostedApi.getEstimatorConfigForUser(
        user.emailAddress,
        environment,
      );
      return config;
    } catch {
      showErrorNotification('Error', 'Failed to retrieve the user configuration.');
      return null;
    } finally {
      hidePageLoadingOverlay();
    }
  };

  const onEstimatorConfigSaveClick = async (
    originalConfig: EstimatorHostedConfiguration,
    updatedConfig: EstimatorHostedConfiguration,
  ): Promise<void> => {
    if (!estimatorConfigState) {
      return;
    }

    if (isEqual(updatedConfig, originalConfig)) {
      showInfoNotification('Info', 'There were no changes to save.');
      setEstimatorConfigState(undefined);
      return;
    }

    try {
      showPageLoadingOverlay();

      for (const user of estimatorConfigState.users) {
        await EstimatorHostedApi.saveEstimatorConfigForUser(user.emailAddress, updatedConfig);
      }

      setEstimatorConfigState(undefined);
      showSuccessNotification('Success', 'Configuration saved successfully.');
    } catch {
      showErrorNotification('Errror', 'Failed to save configuration.');
    } finally {
      hidePageLoadingOverlay();
    }
  };

  const onEstimatorConfigDiscardClick = (
    originalConfig: EstimatorHostedConfiguration,
    updatedConfig: EstimatorHostedConfiguration,
  ): void => {
    if (estimatorConfigState && isEqual(updatedConfig, originalConfig)) {
      setEstimatorConfigState(undefined);
      return;
    }

    showConfirmationOverlay({
      title: 'Confirm',
      text: 'Are you sure you want to discard your unsaved changes?',
      buttons: [
        {
          text: 'Yes',
          onClick: async (): Promise<void> => {
            setEstimatorConfigState(undefined);
            hideConfirmationOverlay();
          },
          color: 'normal',
        },
        {
          text: 'No',
          onClick: (): void => {
            hideConfirmationOverlay();
          },
          color: 'gray',
        },
      ],
    });
  };

  const onEsimatorCopyConfigSaveClick = async (
    environment: EstimatorHostedConfigurationEnvironment,
  ): Promise<void> => {
    const displayError = (): void => {
      showErrorNotification('Error', 'Failed to copy user configuration.');
    };

    if (!userToCopyConfigFrom) {
      displayError();
      return;
    }

    showConfirmationOverlay({
      title: 'Confirm',
      text: `Are you sure you want to copy the ${environment} config from "${
        userToCopyConfigFrom.displayName
      }" to the following users? ${selectedUsers.map(u => u.displayName).join(', ')}`,
      buttons: [
        {
          text: 'Yes',
          onClick: async (): Promise<void> => {
            try {
              hideConfirmationOverlay();
              showPageLoadingOverlay();

              await EstimatorHostedApi.copyEstimatorConfigToUsers(
                userToCopyConfigFrom.emailAddress,
                environment,
                selectedUsers.map(u => u.emailAddress),
              );

              setUserToCopyConfigFrom(undefined);
              showSuccessNotification('Success', 'User configuration copied successfully.');
            } catch {
              displayError();
            } finally {
              hidePageLoadingOverlay();
            }
          },
          color: 'normal',
        },
        {
          text: 'No',
          onClick: (): void => {
            hideConfirmationOverlay();
          },
          color: 'gray',
        },
      ],
    });
  };

  const onEsimatorCopyConfigDiscardClick = (): void => {
    setUserToCopyConfigFrom(undefined);
  };

  const isUserValid = (user: GridHostedUser): boolean => {
    const isEmptyString = (text: string): boolean => text.trim().length === 0;
    return !isEmptyString(user.displayName) && !isEmptyString(user.clientOrganizationId);
  };

  const onItemChanged = (event: GridItemChangeEvent): void => {
    const editedUser = event.dataItem as GridHostedUser;

    setUsers(users => {
      const editedUsers = users.map(user => {
        if (user.emailAddress !== editedUser.emailAddress) {
          return user;
        }
        return { ...user, [event.field!]: event.value };
      });

      return editedUsers;
    });
  };

  const onSelectionChange = (event: GridSelectionChangeEvent): void => {
    const user = event.dataItem as GridHostedUser;
    setUsers(users => {
      const usersClone = [...users];
      const userToEdit = getUser(user.emailAddress, usersClone);
      userToEdit.selected = !user.selected;
      return usersClone;
    });
  };

  const onHeaderSelectionChange = (event: GridHeaderSelectionChangeEvent): void => {
    const checkbox = event.syntheticEvent.target as HTMLInputElement;

    setUsers(users => {
      const usersClone = [...users];

      filteredUsers.forEach(filteredUser => {
        const user = getUser(filteredUser.emailAddress, usersClone);
        user.selected = checkbox.checked;
      });

      return usersClone;
    });
  };

  const onFilterChange = (event: GridFilterChangeEvent): void => {
    setFilter(event.filter);
  };

  const onAddUsersClick = (): void => {
    setShowAddUsersModal(true);
  };

  const onUpdateConfigForSelectedUsersClick = (): void => {
    const selectedClientOrgIds = new Set(selectedUsers.map(u => u.clientOrganizationId));

    if (selectedClientOrgIds.size > 1) {
      showErrorNotification(
        'Error',
        'The selected users are from different Client Organizations. We only support bulk editing for users in the same Client Organization.',
      );
      return;
    }

    setEstimatorConfigState({ users: selectedUsers });
  };

  const onEditClick = (user: GridHostedUser): void => {
    setUsers(users => {
      const usersClone = [...users];
      const userToEdit = getUser(user.emailAddress, usersClone);
      userToEdit.inEdit = true;
      return usersClone;
    });
  };

  const viewEditEstimatorConfigClick = async (user: GridHostedUser): Promise<void> => {
    setEstimatorConfigState({ users: [user] });
  };

  const copyEstimatorConfigClick = async (user: GridHostedUser): Promise<void> => {
    if (selectedUsers.some(u => u.clientOrganizationId !== user.clientOrganizationId)) {
      showErrorNotification(
        'Error',
        'The selected users are from different Client Organizations. We only support copying configuration for users in the same Client Organization.',
      );
      return;
    }

    setUserToCopyConfigFrom(user);
  };

  const onResetPasswordClick = async (user: GridHostedUser): Promise<void> => {
    const resetPassword = async (): Promise<void> => {
      const tempPassword: string = await EstimatorHostedApi.resetUserPassword(user.emailAddress);
      setTemporaryPasswordInfo([
        {
          name: user.displayName,
          email: user.emailAddress,
          password: tempPassword,
        },
      ]);
    };

    showConfirmationOverlay({
      title: 'Confirm',
      text: `Are you sure you want to reset the password for user "${user.displayName}"?`,
      buttons: [
        {
          text: 'Yes',
          onClick: async (): Promise<void> => {
            try {
              hideConfirmationOverlay();
              showPageLoadingOverlay();
              await resetPassword();
            } catch {
              showErrorNotification('Error', "Failed to reset the user's password");
            } finally {
              hidePageLoadingOverlay();
            }
          },
          color: 'normal',
        },
        {
          text: 'No',
          onClick: (): void => {
            hideConfirmationOverlay();
          },
          color: 'gray',
        },
      ],
    });
  };

  const onDeleteClick = async (user: GridHostedUser): Promise<void> => {
    const deleteUser = async (): Promise<void> => {
      await EstimatorHostedApi.deleteUser(user.emailAddress);
      setUsers(users => {
        const usersClone = [...users];
        const index = usersClone.findIndex(u => u.emailAddress === user.emailAddress);
        usersClone.splice(index, 1);
        return usersClone;
      });
    };

    showConfirmationOverlay({
      title: 'Confirm',
      text: `Are you sure you want to delete the user "${user.displayName}"?`,
      buttons: [
        {
          text: 'Yes',
          onClick: async (): Promise<void> => {
            try {
              hideConfirmationOverlay();
              showPageLoadingOverlay();
              await deleteUser();
              showSuccessNotification('Success', 'User deleted successfully');
            } catch {
              showErrorNotification('Error', 'Failed to delete the user.');
            } finally {
              hidePageLoadingOverlay();
            }
          },
          color: 'normal',
        },
        {
          text: 'No',
          onClick: (): void => {
            hideConfirmationOverlay();
          },
          color: 'gray',
        },
      ],
    });
  };

  const onSaveClick = async (user: GridHostedUser): Promise<void> => {
    await saveUsers([user]);
  };

  const onDiscardClick = async (user: GridHostedUser): Promise<void> => {
    if (user.isNew) {
      const removeNewUser = (): void => {
        setUsers(users => {
          const usersClone = [...users];
          const index = usersClone.findIndex(u => u.emailAddress === user.emailAddress);
          usersClone.splice(index, 1);
          return usersClone;
        });
      };

      if (!user.displayName && !user.clientOrganizationId) {
        removeNewUser();
      } else {
        showConfirmationOverlay({
          title: 'Confirm',
          text: 'Are you sure you want to discard your unsaved changes?',
          buttons: [
            {
              text: 'Yes',
              onClick: (): void => {
                hideConfirmationOverlay();
                removeNewUser();
              },
              color: 'normal',
            },
            {
              text: 'No',
              onClick: (): void => {
                hideConfirmationOverlay();
              },
              color: 'gray',
            },
          ],
        });
      }
    } else {
      try {
        const savedUser = await EstimatorHostedApi.getUserByEmail(user.emailAddress);
        const uneditedUser: GridHostedUser = {
          ...savedUser,
          isNew: user.isNew,
          inEdit: user.inEdit,
          selected: user.selected,
        };

        const resetUser = (): void => {
          setUsers(users => {
            const usersClone = [...users];
            const index = usersClone.findIndex(u => u.emailAddress === user.emailAddress);
            usersClone[index] = { ...uneditedUser, inEdit: false };
            return usersClone;
          });
        };

        if (isEqual(uneditedUser, user)) {
          resetUser();
        } else {
          showConfirmationOverlay({
            title: 'Confirm',
            text: 'Are you sure you want to discard your unsaved changes?',
            buttons: [
              {
                text: 'Yes',
                onClick: (): void => {
                  hideConfirmationOverlay();
                  resetUser();
                },
                color: 'normal',
              },
              {
                text: 'No',
                onClick: (): void => {
                  hideConfirmationOverlay();
                },
                color: 'gray',
              },
            ],
          });
        }
      } catch {
        showErrorNotification('Error', 'Failed to load user.');
        history.push(ApplicationRoutes.ERROR_ERROR);
      }
    }
  };

  const commandCell = (gcp: GridCellProps): JSX.Element => {
    const user = gcp.dataItem as GridHostedUser;
    return (
      <td>
        <div className="d-flex justify-content-center">
          {user.inEdit ? (
            <>
              <BTIconButton
                tooltip="Save"
                icon="save"
                onClick={async (): Promise<void> => await onSaveClick(user)}
                disabled={!isUserValid(user)}
              />
              <BTIconButton
                tooltip="Discard"
                icon="times"
                onClick={async (): Promise<void> => await onDiscardClick(user)}
              />
            </>
          ) : (
            <>
              <BTIconButton tooltip="Edit" icon="edit" onClick={(): void => onEditClick(user)} />
              <BTIconButton
                tooltip="View / Edit Estimator Config"
                icon="cog"
                onClick={async (): Promise<void> => await viewEditEstimatorConfigClick(user)}
              />
              <BTIconButton
                tooltip="Copy Estimator Config to Selected Users"
                icon="copy"
                onClick={async (): Promise<void> => await copyEstimatorConfigClick(user)}
                disabled={
                  selectedUsers.length === 1
                    ? selectedUsers[0].emailAddress === user.emailAddress
                    : selectedUsers.length === 0
                }
              />
              <BTIconButton
                tooltip="Reset Password"
                icon="key"
                onClick={async (): Promise<void> => await onResetPasswordClick(user)}
              />
              <BTIconButton
                tooltip="Delete"
                icon="trash"
                onClick={async (): Promise<void> => await onDeleteClick(user)}
              />
            </>
          )}
        </div>
      </td>
    );
  };

  const emailCell = (gcp: GridCellProps): JSX.Element => {
    const user = gcp.dataItem as GridHostedUser;
    return <td>{user.isNew ? <>{'<none>'}</> : <>{user.emailAddress}</>}</td>;
  };

  const nameCell = useCallback((gcp: GridCellProps): JSX.Element => {
    const user = gcp.dataItem as GridHostedUser;
    if (user.inEdit) {
      return <GridTextCell {...gcp} required={true} />;
    } else {
      return <td>{user.displayName}</td>;
    }
  }, []);

  const clientOrgCell = useCallback(
    (gcp: GridCellProps): JSX.Element => {
      const user = gcp.dataItem as GridHostedUser;

      if (user.inEdit) {
        return (
          <GridDropdownCell
            {...gcp}
            required={true}
            listValues={clientOrgs}
            dataItemKey={nameof<ClientOrganization>('id')}
            valueField={nameof<ClientOrganization>('id')}
            textField={nameof<ClientOrganization>('name')}
            listAnchor={gridContainer}
          />
        );
      }

      const clientOrg = clientOrgs.find(co => co.id === user.clientOrganizationId);
      if (!clientOrg) {
        return (
          <td className="clientOrgError">
            <ul>
              <li>{'ERROR: Client Organization ID not set'}</li>
              <li>{`Value: ${user.clientOrganizationId}`}</li>
            </ul>
          </td>
        );
      }

      return <td>{clientOrg.name}</td>;
    },
    [clientOrgs],
  );

  const clientOrgFilterCell = useCallback(
    (gfcp: GridFilterCellProps): JSX.Element => {
      return (
        <GridDropdownFilterCell
          {...gfcp}
          listValues={clientOrgs}
          dataItemKey={nameof<ClientOrganization>('id')}
          valueField={nameof<ClientOrganization>('id')}
          textField={nameof<ClientOrganization>('name')}
        />
      );
    },
    [clientOrgs],
  );

  const groupCell = useCallback(
    (gcp: GridCellProps): JSX.Element => {
      const user = gcp.dataItem as GridHostedUser;
      if (user.inEdit) {
        return (
          <GridMultiSelectCell
            {...gcp}
            listValues={userGroups}
            dataItemKey={nameof<EstimatorHostedUserGroup>('id')}
            valueField={nameof<EstimatorHostedUserGroup>('id')}
            textField={nameof<EstimatorHostedUserGroup>('displayName')}
            listAnchor={gridContainer}
          />
        );
      }

      return (
        <td>
          <ul>
            {user.groupIds.map(groupId => {
              const group = userGroups.find(g => g.id === groupId);
              return <li key={groupId}>{group ? group.displayName : groupId}</li>;
            })}
          </ul>
        </td>
      );
    },
    [userGroups],
  );

  const groupFilterCell = useCallback(
    (gfcp: GridFilterCellProps): JSX.Element => {
      return (
        <GridDropdownFilterCell
          {...gfcp}
          listValues={userGroups}
          dataItemKey={nameof<EstimatorHostedUserGroup>('id')}
          valueField={nameof<EstimatorHostedUserGroup>('id')}
          textField={nameof<EstimatorHostedUserGroup>('displayName')}
          filterOperator="contains"
        />
      );
    },
    [userGroups],
  );

  if (isLoading) {
    return <></>;
  }

  return (
    <div className="estimatorHostedUserManagementPage">
      <div className="container">
        <h1>Estimator Hosted - User Management</h1>
      </div>

      {!showAddUsersModal ? (
        <></>
      ) : (
        <AddUsersModal
          clientOrgs={clientOrgs}
          userGroups={userGroups}
          onSaveClick={onAddUsersSaveClick}
          onDiscardClick={onAddUsersDiscardClick}
        />
      )}

      {!tempPasswordInfo ? (
        <></>
      ) : (
        <TemporaryPasswordModal
          passwordInfo={tempPasswordInfo}
          onCopyPasswordClick={onCopyPasswordClick}
        />
      )}

      {!estimatorConfigState ? (
        <></>
      ) : (
        <EstimatorConfigurationModal
          users={estimatorConfigState.users}
          getConfigForUser={getConfigForUser}
          closeModal={(): void => setEstimatorConfigState(undefined)}
          onSaveClick={onEstimatorConfigSaveClick}
          onDiscardClick={onEstimatorConfigDiscardClick}
        />
      )}

      {!userToCopyConfigFrom ? (
        <></>
      ) : (
        <EstimatorCopyConfigurationModal
          onSaveClick={onEsimatorCopyConfigSaveClick}
          onDiscardClick={onEsimatorCopyConfigDiscardClick}
        />
      )}

      <div ref={gridContainer}>
        <Grid
          data={filteredUsers}
          dataItemKey={nameof<GridHostedUser>('emailAddress')}
          editField={nameof<GridHostedUser>('inEdit')}
          onItemChange={onItemChanged}
          selectedField={nameof<GridHostedUser>('selected')}
          onSelectionChange={onSelectionChange}
          onHeaderSelectionChange={onHeaderSelectionChange}
          filterable={true}
          filter={filter}
          onFilterChange={onFilterChange}
        >
          <GridToolbar>
            <div className="flex-grow-1 d-flex justify-content-end">
              <BTButton text="Add Users" onClick={onAddUsersClick} />
              <div className="ml-3">
                <BTButton
                  text="Update Configuration for Selected Users"
                  onClick={onUpdateConfigForSelectedUsersClick}
                  disabled={selectedUsers.length === 0}
                />
              </div>
            </div>
          </GridToolbar>
          <GridColumn
            field={nameof<GridHostedUser>('selected')}
            filterable={false}
            headerSelectionValue={
              filteredUsers.length > 0 && filteredUsers.every(user => user.selected)
            }
          />
          <GridColumn
            title="Email"
            field={nameof<GridHostedUser>('emailAddress')}
            editable={false}
            cell={emailCell}
          />
          <GridColumn title="Name" field={nameof<GridHostedUser>('displayName')} cell={nameCell} />
          <GridColumn
            title="Client Organization"
            field={nameof<GridHostedUser>('clientOrganizationId')}
            cell={clientOrgCell}
            filterCell={clientOrgFilterCell}
          />
          <GridColumn
            title="Groups"
            field={nameof<GridHostedUser>('groupIds')}
            cell={groupCell}
            filterCell={groupFilterCell}
          />
          <GridColumn cell={commandCell} filterable={false} width={150} />
        </Grid>
      </div>
    </div>
  );
};

const getUser = (email: string, users: GridHostedUser[]): GridHostedUser => {
  const user = users.find(u => u.emailAddress === email);

  if (!user) {
    throw new Error(`Could not find user with email: ${email}`);
  }

  return user;
};

const sortUsers = (users: GridHostedUser[]): GridHostedUser[] => {
  return sortBy(users, user => user.displayName);
};

export default EstimatorHostedUserManagementPage;
