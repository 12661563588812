import UserConstants from '../../../constants/users';

export interface UserDto {
  emailAddress: string;
  id: string;
  isBeckTechUser: boolean;
  name?: string;
  roles: string[];
}

// NOTE: Right now we are not returning everything from the API and must populate later
export const getUserFromDto = (userDto: UserDto): User => {
  const user: User = {
    id: userDto.id,
    isNew: false,
    emailAddress: userDto.emailAddress,
    isBeckTechUser: userDto.isBeckTechUser,
    roles: userDto.roles
      .map((currRole): UserRole | null => {
        switch (currRole) {
          case UserConstants.UserRolesValues.ADMIN:
            return {
              id: UserConstants.UserRolesValues.ADMIN,
              name: UserConstants.UserRolesDisplayNames.ADMIN,
            };
          case UserConstants.UserRolesValues.INTERNALUSER:
            return {
              id: UserConstants.UserRolesValues.INTERNALUSER,
              name: UserConstants.UserRolesDisplayNames.INTERNALUSER,
            };
          case UserConstants.UserRolesValues.CLIENTUSER:
            return {
              id: UserConstants.UserRolesValues.CLIENTUSER,
              name: UserConstants.UserRolesDisplayNames.CLIENTUSER,
            };
          case UserConstants.UserRolesValues.BIDDAYSUPPORT:
            return {
              id: UserConstants.UserRolesValues.BIDDAYSUPPORT,
              name: UserConstants.UserRolesDisplayNames.BIDDAYSUPPORT,
            };
          default:
            return null;
        }
      })
      .filter(v => v)
      .map(v => v!),
  };

  return user;
};

export const getUserDtoFromUser = (user: User): UserDto => {
  return {
    emailAddress: user.emailAddress,
    id: user.id!,
    roles: user.roles.map(v => v.id),
    isBeckTechUser: user.isBeckTechUser,
  };
};
