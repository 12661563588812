import axios from 'axios';
import LocalStorageKeys from '../constants/local-storage';
import { AuthenticationService } from '../services/authentication/authentication-service';
import { getObjectWithDateStringsAsDates } from '../utils/dates';
import { getConfig } from '../utils/config';

const enableDateStringToDateResponseMiddleware = (): void => {
  axios.interceptors.response.use(response => {
    if (response && response.data) {
      response.data = getObjectWithDateStringsAsDates(response.data);
    }
    return response;
  });

  axios.interceptors.request.use(request => {
    // Attach the authorization headers if they're present
    const accessToken = localStorage.getItem(LocalStorageKeys.AccessToken);
    if (accessToken) {
      request.headers['Authorization'] = `Bearer ${accessToken}`;
      request.headers['Content-Type'] = 'application/json';
    }

    request.headers['api-version'] = getConfig('REACT_APP_API_VERSION');

    return request;
  });

  // Handle 401 responses
  axios.interceptors.response.use(
    async response => response,
    async error => {
      // NOTE: If there was an error and it wasn't from the server (ex. network issues), we want to explicitly
      // throw an exception, otherwise Axios will just return whatever we return from this callback function to
      // the calling code as a regular HTTP response.
      if (error && error.isAxiosError) {
        throw error;
      }

      if (error && error.response && error.response.status === 401) {
        try {
          // Try to refresh the access token and retry the AJAX call
          const accessTokenWasAcquired = await AuthenticationService.getAccessToken();

          if (!accessTokenWasAcquired) {
            // NOTE: This will trigger a browser redirect
            AuthenticationService.logout();
            return error;
          }

          // Now that we have an access token, try to redo the AJAX call without using Axios so we don't trigger this error handler again
          // if we get another 401 response.
          // NOTE: Fetch will not throw an error for error HTTP codes such as 401.

          // The AuthenticationService.getAccessToken() call will set the new access token in local storage
          const accessToken = localStorage.getItem(LocalStorageKeys.AccessToken);
          if (!accessToken) {
            // Throw an error so it gets caught below
            throw new Error();
          }

          const originalHttpConfig = error.response.config;

          const retryResult = await fetch(
            new Request(originalHttpConfig.url, {
              method: originalHttpConfig.method,
              headers: {
                ...originalHttpConfig.headers,
                Authorization: `Bearer ${accessToken}`,
              },
              mode: 'cors',
              cache: 'default',
            }),
          );

          if (retryResult.status === 401) {
            // If we got a 401 again, throw an error so it gets caught below
            throw new Error();
          }

          return retryResult;
        } catch {
          // NOTE: This will actually trigger a browser redirect
          AuthenticationService.logout();
          return error;
        }
      }
    },
  );
};

export default { enableDateStringToDateResponseMiddleware };
