import React, { useContext } from 'react';
import LoadingIndicator from '../loading-indicator/LoadingIndicator';
import './LoadingPageOverlay.scss';
import { LoadingPageOverlayContext } from './LoadingPageOverlayContext';

const LoadingPageOverlay: React.FC = () => {
  const { pageLoadingOverlayDisplayInfo } = useContext(LoadingPageOverlayContext);

  const loadingPageOverlayCssClasses = ['loadingPageOverlay'];

  // Don't even show the loading overlay if it hasn't explicitly been toggled
  if (!pageLoadingOverlayDisplayInfo.hasBeenToggled) {
    loadingPageOverlayCssClasses.push('d-none');
  } else if (pageLoadingOverlayDisplayInfo.isDisplayed) {
    loadingPageOverlayCssClasses.push('isDisplayed');
  }

  return (
    <div className={loadingPageOverlayCssClasses.join(' ')}>
      <div>
        <LoadingIndicator />
      </div>
    </div>
  );
};

export default LoadingPageOverlay;
