import React from 'react';
import { useHistory } from 'react-router';
import BTButton from '../../../common-page-components/controls/button/BTButton';
import { useTranslation } from 'react-i18next';

interface Props {
  availableReports: Report[];
  reportFilters: ReportFiltersBase;
  setReportFilters: (reportFiltersBase: ReportFiltersBase) => void; // NOTE: We have this function so this component doesn't explicitly update the parent component's state
  onClick?: () => void;
}

const ReportsPageReportsListing: React.FC<Props> = ({
  availableReports,
  reportFilters,
  setReportFilters,
  onClick,
}) => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <div className="availableReportsArea">
      <h2>{t('reporting:availableReports')}</h2>

      <div className="reportsListings">
        {availableReports.map(currReport => (
          <div
            className={[
              'reportButton',
              reportFilters.reportName === currReport.name ? 'isSelected' : '',
            ].join(' ')}
            key={currReport.name}
            onClick={(): void => {
              if (onClick) {
                onClick();
              }

              // NOTE: Since we match multiple routes to get to this page, this page component will not reinitialize and will retain its state if the URL changes to one that it matches
              history.push(currReport.url);

              setReportFilters({
                ...reportFilters,
                reportName: currReport.name,
              });
            }}
          >
            <BTButton
              icon={currReport.icon}
              text={currReport.name}
              onClick={(): void => {
                // NOTE: Since we match multiple routes to get to this page, this page component will not reinitialize and will retain its state if the URL changes to one that it matches
                history.push(currReport.url);

                setReportFilters({
                  ...reportFilters,
                  reportName: currReport.name,
                });
              }}
              roundedCorners={true}
              align="left"
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default ReportsPageReportsListing;
