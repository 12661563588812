import React, { useCallback, useContext, useEffect, useState } from 'react';
import './NotificationPanel.scss';
import {
  NotificationPanelContext,
  NotificationPanelMessage,
  NotificationPanelTypes,
} from './NotificationPanelContext';

interface Props {
  queuedNotifications: NotificationPanelMessage[];
  testTimeout?: number;
}

const NotificationPanel: React.FC<Props> = props => {
  const { popFirstQueuedNotification } = useContext(NotificationPanelContext);

  const [
    notificationBeingDisplayed,
    setNotificationBeingDisplayed,
  ] = useState<NotificationPanelMessage | null>(null);

  const [isDisplayed, setIsDisplayed] = useState<boolean>(false);

  const [
    hideCurrentNotificationTimeoutPromise,
    setHideCurrentNotificationTimeoutPromise,
  ] = useState<NodeJS.Timeout | null>(null);

  const setTimeoutToHideCurrentNotification = useCallback(() => {
    const notificationTimeout = props.testTimeout || 4000;
    hideCurrentNotificationTimeoutPromise && clearTimeout(hideCurrentNotificationTimeoutPromise);

    setHideCurrentNotificationTimeoutPromise(
      setTimeout(() => {
        setIsDisplayed(false);

        setTimeout(() => {
          setNotificationBeingDisplayed(null);
        }, 500);
      }, notificationTimeout),
    );
  }, [hideCurrentNotificationTimeoutPromise, props.testTimeout]);

  useEffect(() => {
    if (props.queuedNotifications.length > 0 && !notificationBeingDisplayed) {
      const nextNotification = popFirstQueuedNotification();
      setNotificationBeingDisplayed(nextNotification);

      // Stagger things so the UI has time to animate
      setTimeout(() => {
        setIsDisplayed(true);
      }, 250);

      setTimeoutToHideCurrentNotification();
    }

    // NOTE: By having the queued notifications / notification currently displayed as dependencies, we can guarantee that we will cycle through any pending notifications in the queue
  }, [
    props.queuedNotifications,
    notificationBeingDisplayed,
    popFirstQueuedNotification,
    setTimeoutToHideCurrentNotification,
  ]);

  const cssClassesArray = ['notificationPanel', isDisplayed ? 'isDisplayed' : ''];

  if (notificationBeingDisplayed) {
    switch (notificationBeingDisplayed.type) {
      case NotificationPanelTypes.Success:
        cssClassesArray.push('success');
        break;
      case NotificationPanelTypes.Error:
        cssClassesArray.push('error');
        break;
      case NotificationPanelTypes.Info:
        cssClassesArray.push('info');
        break;
    }
  }

  return (
    <div
      data-testid="notification-panel"
      className={cssClassesArray.join(' ')}
      onClick={(): void => {
        setIsDisplayed(false);

        setTimeout(() => {
          setNotificationBeingDisplayed(null);
        }, 500);

        setTimeoutToHideCurrentNotification();
      }}
    >
      {notificationBeingDisplayed && (
        <div className="container" data-testid="notification-panel-message">
          <h1>{notificationBeingDisplayed.title}</h1>
          <div className="message">{notificationBeingDisplayed.message}</div>
        </div>
      )}
    </div>
  );
};

export default NotificationPanel;
