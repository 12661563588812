import axios from 'axios';
import {
  LicenseUsageByPeriodDto,
  getDailyBTChartDataFromDto,
  getWeeklyBTChartDataFromDto,
  getMonthlyBTChartDataFromDto,
} from './usage-api-service-helper';
import ApiRoutes from '../../../constants/routes/api-routes';
import _ from 'lodash';
import moment from 'moment';

const getRawUsage = async (
  fromDate: Date,
  toDate: Date,
  client: ClientOrganization | null,
  product: Product | null,
): Promise<ArrayBuffer> => {
  const params = [
    `start=${fromDate.toLocaleDateString()}`,
    `end=${toDate.toLocaleDateString()}`,
    client ? `clientOrgId=${client.id}` : '',
    product ? `productId=${product.id}` : '',
  ].filter(v => v);

  const url = `${ApiRoutes.Raw}?${params.join('&')}`;
  // NOTE: This api call is expecting to receive binary data
  const response = await axios.get<ArrayBuffer>(url, {
    responseType: 'arraybuffer',
  });
  return response.data;
};

const getUsageByDay = async (
  fromDate: Date,
  toDate: Date,
  client: ClientOrganization | null,
  product: Product | null,
  typeOfUsage: 'distinct' | 'total' | 'duration',
): Promise<BTChartData> => {
  const params = [
    `start=${fromDate.toLocaleDateString()}`,
    `end=${toDate.toLocaleDateString()}`,
    client ? `clientOrgId=${client.id}` : '',
    product ? `productId=${product.id}` : '',
  ].filter(v => v);
  const url =
    typeOfUsage === 'duration'
      ? `${ApiRoutes.DurationByDay}?${params.join('&')}`
      : `${ApiRoutes.UsageByDay}?${params.join('&')}`;
  const response = await axios.get<LicenseUsageByPeriodDto[]>(url);
  const dtos: LicenseUsageByPeriodDto[] = response.data;
  const data: BTChartData = getDailyBTChartDataFromDto(dtos, typeOfUsage);
  if (data.chartSeries[0]) {
    data.chartSeries[0].data = initChartRange(data.chartSeries[0].data, fromDate, toDate, 'days');
  }
  return data;
};

const getUsageByWeek = async (
  fromDate: Date,
  toDate: Date,
  client: ClientOrganization | null,
  product: Product | null,
  typeOfUsage: 'distinct' | 'total' | 'duration',
): Promise<BTChartData> => {
  const params = [
    `start=${fromDate.toLocaleDateString()}`,
    `end=${toDate.toLocaleDateString()}`,
    client ? `clientOrgId=${client.id}` : '',
    product ? `productId=${product.id}` : '',
  ].filter(v => v);
  const url =
    typeOfUsage === 'duration'
      ? `${ApiRoutes.DurationByWeek}?${params.join('&')}`
      : `${ApiRoutes.UsageByWeek}?${params.join('&')}`;
  const response = await axios.get<LicenseUsageByPeriodDto[]>(url);

  const dtos: LicenseUsageByPeriodDto[] = response.data;
  const data: BTChartData = getWeeklyBTChartDataFromDto(dtos, typeOfUsage);
  if (data.chartSeries[0]) {
    data.chartSeries[0].data = initChartRange(data.chartSeries[0].data, fromDate, toDate, 'weeks');
  }
  return data;
};

const getUsageByMonth = async (
  fromDate: Date,
  toDate: Date,
  client: ClientOrganization | null,
  product: Product | null,
  typeOfUsage: 'distinct' | 'total' | 'duration',
): Promise<BTChartData> => {
  const params = [
    `start=${fromDate.toLocaleDateString()}`,
    `end=${toDate.toLocaleDateString()}`,
    client ? `clientOrgId=${client.id}` : '',
    product ? `productId=${product.id}` : '',
  ].filter(v => v);
  const url =
    typeOfUsage === 'duration'
      ? `${ApiRoutes.DurationByMonth}?${params.join('&')}`
      : `${ApiRoutes.UsageByMonth}?${params.join('&')}`;
  const response = await axios.get<LicenseUsageByPeriodDto[]>(url);

  const dtos: LicenseUsageByPeriodDto[] = response.data;
  const data: BTChartData = getMonthlyBTChartDataFromDto(dtos, typeOfUsage);
  if (data.chartSeries[0]) {
    data.chartSeries[0].data = initChartRange(data.chartSeries[0].data, fromDate, toDate, 'months');
  }
  return data;
};

const initChartRange = (
  data: BTChartSeriesData[],
  fromDate: Date,
  toDate: Date,
  increment: 'days' | 'weeks' | 'months',
): BTChartSeriesData[] => {
  const first = _.first(data);
  const last = _.last(data);
  const dataClone = [...data];
  if (first && last) {
    //If we have data, check to see if it includes the range specified, hydrate the range if necessary
    if (moment(first.label).isAfter(fromDate, increment)) {
      dataClone.unshift({
        label: fromDate,
        value: 0,
      });
    }
    if (moment(last.label).isBefore(toDate, increment)) {
      dataClone.push({
        label: toDate,
        value: 0,
      });
    }
  } else {
    //Else, stage the two and from dates as empty records;
    dataClone.unshift({
      label: fromDate,
      value: 0,
    });
    dataClone.push({
      label: toDate,
      value: 0,
    });
  }
  return dataClone;
};

export default {
  getRawUsage,
  getUsageByDay,
  getUsageByWeek,
  getUsageByMonth,
};
