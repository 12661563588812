import React, { useState } from 'react';
import { Input } from '@progress/kendo-react-inputs';
import { Field, FieldProps } from 'formik';
import BTIconButton from '../controls/icon-button/BTIconButton';
import _ from 'lodash';
import './Forms.scss';

interface Props {
  label: string;
  name: string;
  type?: 'text' | 'password';
  validator?: (value: string) => string | void;
  disabled?: boolean;
  tabIndex?: number;
  tooltip?: string;
  'data-testid'?: string;
  placeholder?: string;
}

const WrappedInput: React.FC<FieldProps & Props> = ({ field, form, label, type, ...rest }) => {
  const errors = _.get(form.errors, field.name);
  const hasErrors = !!errors;
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const classNames = ['formInput'];
  if (hasErrors && form.submitCount) {
    classNames.push('formInputError');
  }

  let inputType = type || 'text';
  if (inputType === 'password' && showPassword) {
    inputType = 'text';
  }

  return (
    <>
      <label htmlFor={field.name}>{label}</label>
      <div className="d-flex">
        <Input className={classNames.join(' ')} type={inputType} {...field} {...rest} />
        {type !== 'password' ? (
          <></>
        ) : (
          <BTIconButton
            tooltip="Toggle Password Visibility"
            icon={showPassword ? 'eye-slash' : 'eye'}
            onClick={(): void => setShowPassword(showPassword => !showPassword)}
          />
        )}
      </div>
    </>
  );
};

const FormTextInput: React.FC<Props> = ({ name, label, validator, tabIndex, tooltip, ...rest }) => {
  return (
    // TODO: move tooltip to the Kendo Input in WrappedInput
    // Kendo's Input isn't properly displaying a tooltip, but it looks fixed in newer versions
    <div className="formInput formTextInput" title={tooltip}>
      <Field
        id={name}
        label={label}
        name={name}
        validate={validator}
        tabIndex={tabIndex}
        component={WrappedInput}
        {...rest}
      />
    </div>
  );
};

export default FormTextInput;
