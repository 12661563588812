import * as Msal from 'msal';
import { Configuration } from 'msal';
import LocalStorageKeys from '../../constants/local-storage';
import { getConfig } from '../../utils/config';

const { localStorage } = window;

export enum SSOErrorCode {
  OtherError = 1,
  LicensingAppSSOError = 2,
}

export interface SSOError {
  message: string;
  code: SSOErrorCode;
}

export interface AccessTokenUserInfo {
  name: string;
  emailAddress: string;
}

export class AuthenticationService {
  public static msalObj: Msal.UserAgentApplication;
  private static scope = getConfig('REACT_APP_MSAL_SCOPE') as string;
  private static clientId = getConfig('REACT_APP_MSAL_CLIENTID') as string;
  private static signInAuthority = getConfig('REACT_APP_MSAL_AUTHORITY_SIGNIN') as string;
  private static passwordResetAuthority = getConfig(
    'REACT_APP_MSAL_AUTHORITY_PASSWORDRESET',
  ) as string;
  private static redirectUri = getConfig('REACT_APP_MSAL_REDIRECT_URL') as string;
  private static cacheLocation = 'localStorage' as ('localStorage' | 'sessionStorage' | undefined);

  private static requestObj = {
    scopes: [AuthenticationService.scope],
  };

  private static signInConfig: Configuration = {
    auth: {
      clientId: AuthenticationService.clientId,
      authority: AuthenticationService.signInAuthority,
      validateAuthority: false,
      redirectUri: AuthenticationService.redirectUri,
      postLogoutRedirectUri: AuthenticationService.redirectUri,
    },
    cache: {
      cacheLocation: AuthenticationService.cacheLocation,
      storeAuthStateInCookie: true,
    },
  };

  private static passwordResetConfig: Configuration = {
    auth: {
      clientId: AuthenticationService.clientId,
      authority: AuthenticationService.passwordResetAuthority,
      validateAuthority: false,
      redirectUri: AuthenticationService.redirectUri,
    },
    cache: {
      cacheLocation: AuthenticationService.cacheLocation,
      storeAuthStateInCookie: true,
    },
  };

  public static initializeSignIn(): void {
    this.msalObj = new Msal.UserAgentApplication(this.signInConfig);
    this.setAuthRedirectCallBack();
  }

  public static initializePasswordReset(): void {
    this.msalObj = new Msal.UserAgentApplication(this.passwordResetConfig);
    this.setAuthRedirectCallBack();
  }

  // Note: The loginRedirect() method uses the currently configured 'authority' property on the
  // msalObj to know whether to navigate to a sign-in or password reset b2c form
  public static navigateToSignInOrPasswordReset(): void {
    this.msalObj.loginRedirect(this.requestObj);
  }

  // Note: The msalObj.logout() method redirects to an SSO logout page so it can clear cookies for that domain, then redirects back to our app
  public static logout(): void {
    localStorage.clear();
    this.msalObj.logout();
  }

  public static async getAccessToken(): Promise<boolean> {
    if (this.msalObj.getAccount()) {
      try {
        const response = await this.msalObj.acquireTokenSilent(this.requestObj);
        localStorage.setItem(LocalStorageKeys.AccessToken, response.accessToken);
        return true;
      } catch (error) {
        // NOTE: We do nothing here and let the code continue on and return false
      }
    }

    return false;
  }

  public static isTokenExpirationValid(jwtToken: string): boolean {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const parsedToken: any = AuthenticationService.parseJwtToken(jwtToken);
    if (parsedToken && parsedToken.exp) {
      // NOTE: The expiration should be a number but parse it as one just in case, or else the logic below will not work correctly
      const jwtTokenExpiration = parseInt(parsedToken.exp);

      // NOTE: The expiration date on a JWT token is the date as a number of seconds, NOT milliseconds so we have to adjust it
      const tokenExpirationAsDate = new Date(jwtTokenExpiration * 1000);
      return new Date() < tokenExpirationAsDate;
    }

    return false;
  }

  public static getUserInfoFromAccessToken(jwtToken: string): AccessTokenUserInfo | null {
    try {
      const parsedToken = this.parseJwtToken(jwtToken);
      // NOTE: We only require that the email be present
      if (parsedToken && parsedToken.email) {
        return {
          emailAddress: parsedToken.email,
          name: parsedToken.name || 'User',
        };
      }
    } catch {}

    return null;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private static parseJwtToken(jwtToken: string): any | null {
    try {
      return JSON.parse(atob(jwtToken.split('.')[1]));
    } catch {
      return null;
    }
  }

  private static setAuthRedirectCallBack(): void {
    this.msalObj.handleRedirectCallback(
      (error: Msal.AuthError, response?: Msal.AuthResponse): void => {
        if (error) {
          // NOTE: There are 3 error codes that require user interaction so we want to redirect the user to
          // the SSO login page if this is the case.
          // https://docs.microsoft.com/en-us/azure/active-directory/develop/msal-handling-exceptions?tabs=javascript
          if (
            ['consent_required', 'interaction_required', 'login_required'].includes(error.errorCode)
          ) {
            this.navigateToSignInOrPasswordReset();
            return;
          }

          // If we have received an error code, we set necessary local storage
          error.message = error.message || '';
          if (error.message.indexOf('AADB2C90118') > -1) {
            // If we received the password reset error code, set a local storage variable
            localStorage.setItem(LocalStorageKeys.SSOPasswordResetInitiated, 'true');
          } else if (error.message.indexOf('AADB2C90091') > -1) {
            // Else if we received the password reset cancelled error code, we don't need to do anything and can return early
            return;
          } else {
            // Else we set an SSOError based on the error code received
            this.setSSOErrors(error.message);
          }
        } else {
          if (response) {
            // NOTE:  The token claim below indicates that a password change has been completed.
            // If it exists, we create a localStorage variable for other code to use.
            if (
              response.idTokenClaims &&
              response.idTokenClaims.acr &&
              response.idTokenClaims.acr === 'b2c_1a_passwordreset'
            ) {
              localStorage.setItem(LocalStorageKeys.SSOPasswordResetCompleted, 'true');
            }
            const token = response.idToken;
            if (token) {
              localStorage.setItem(LocalStorageKeys.AuthToken, token.rawIdToken);
              localStorage.setItem(LocalStorageKeys.UserName, response.account.name);
              return;
            }
          }
        }
      },
    );
  }

  // If SSO errors come into our app, they come in as a URL hash.
  // This code will read the errors and then set a local storage variable.
  // The MSAL code will then run, strip the hash off the URL, and refresh the page.
  private static setSSOErrors(errorDescription: string): void {
    let errorCode = SSOErrorCode.OtherError;

    // Certain error codes will need to be handled differently, so here we set an enum value based on the error received
    if (errorDescription.indexOf('AADB2C') > -1) {
      errorCode = SSOErrorCode.LicensingAppSSOError;
    }

    // A typical error message might look as follows so we want to clean it up to make the error message more user-friendly:
    //   AADB2C: No matching ogranization for 'destiniestimatordev.onmicrosoft.com'
    //   Correlation ID: be38782a-e576-437e-b449-190ec9756c5e
    //   Timestamp: 2020-02-11 18:05:50Z
    errorDescription = errorDescription.replace(/(\r\n|\r|\n)/g, '').trim();
    errorDescription = errorDescription.replace(/^[\w]+:/g, '').trim();
    errorDescription = errorDescription.replace(/Correlation ID.*$/gi, '').trim();

    // If the error message doesn't have a period at the end, add one
    if (!/\.$/.test(errorDescription)) {
      errorDescription += '.';
    }

    const ssoError: SSOError = {
      message: errorDescription || 'Sorry, but an error occurred.',
      code: errorCode,
    };

    localStorage.setItem(LocalStorageKeys.SSOError, JSON.stringify(ssoError));
  }
}
