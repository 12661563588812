import axios from 'axios';
import ApiRoutes from '../../../constants/routes/api-routes';
import { getUserDtoFromUser, getUserFromDto, UserDto } from './users-api-service-helper';

const getUser = async (): Promise<User> => {
  const response = await axios.get<UserDto>(`${ApiRoutes.Users}/current-user`);
  const dto: UserDto = response.data;

  const user: User = getUserFromDto(dto);
  return user;
};

const getUsers = async (): Promise<User[]> => {
  const response = await axios.get<UserDto[]>(ApiRoutes.Users);
  const dtos: UserDto[] = response.data;

  const users: User[] = dtos.map(dto => getUserFromDto(dto));
  return users;
};

const addUser = async (user: User): Promise<User> => {
  const userDto = getUserDtoFromUser(user);
  const response = await axios.post<UserDto>(`${ApiRoutes.Users}`, userDto);
  const dto: UserDto = response.data;
  return getUserFromDto(dto);
};

const editUser = async (user: User): Promise<void> => {
  const userDto = getUserDtoFromUser(user);
  await axios.put<UserDto>(`${ApiRoutes.Users}/${user.id}`, userDto);
};

const deleteUser = async (userId: string): Promise<void> => {
  await axios.delete(`${ApiRoutes.Users}/${userId}`);
};

export default { getUser, getUsers, addUser, editUser, deleteUser };
