const UserRolesValues = {
  ClientUser: 'ClientUser',
  InternalUser: 'InternalUser',
  Admin: 'Admin',
  BidDaySupport: 'BidDaySupport',
};

const UserRolesDisplayNames = {
  ClientUser: 'Client User',
  InternalUser: 'Internal User',
  Admin: 'Admin',
  BidDaySupport: 'Bid Day Support',
};

export { UserRolesValues, UserRolesDisplayNames };
