import { DatePicker } from '@progress/kendo-react-dateinputs';
import { Field, FieldProps } from 'formik';
import _ from 'lodash';
import React from 'react';
import './Forms.scss';

interface Props {
  label: string;
  name: string;
  tabIndex?: number;
  validator?: (value: Date) => string | void;
}

const WrappedFormDatePicker: React.FC<FieldProps & Props> = ({ field, form, label, ...rest }) => {
  const errors = _.get(form.errors, field.name);
  const hasErrors = !!errors;

  const classNames = ['formInput'];
  if (hasErrors && form.submitCount) {
    classNames.push('formInputError');
  }

  return (
    <>
      <label htmlFor={field.name}>{label}</label>
      <DatePicker
        className={classNames.join(' ')}
        {...field}
        {...rest}
        format="MM/dd/yyyy"
        onChange={(event): void => {
          form.setFieldValue(field.name, event.target.value);
        }}
        onBlur={(): void => {
          // We have to override the onBlur event or else the date picker will set the value several times when
          // the user clicks to choose a date. The values it sets fluctuate between the old / new values and only once the user
          // clicks outside of the date picker will the new value be set.
        }}
      />
    </>
  );
};

const FormDatePicker: React.FC<Props> = props => {
  return (
    <div>
      <Field
        id={props.name}
        name={props.name}
        validate={props.validator}
        label={props.label}
        tabIndex={props.tabIndex}
        component={WrappedFormDatePicker}
        {...props}
      />
    </div>
  );
};

export default FormDatePicker;
