export interface Import {
  name: string;
  translations: Dictionary<string>;
}

const getFileNameWithoutExtension = (fileName: string): string => {
  return fileName
    .slice(2) // remove starting ./
    .replace(/\.[^/.]+$/, ''); // remove file extension
};

// NOTE: webpack defines a global context rather than a module so we must fully qualify the type
const importAll = (requireContext: __WebpackModuleApi.RequireContext): Import[] => {
  return requireContext.keys().map(
    (fileName): Import => ({
      name: getFileNameWithoutExtension(fileName),
      translations: requireContext(fileName),
    }),
  );
};

export { importAll };
