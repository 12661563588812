import { DropDownList } from '@progress/kendo-react-dropdowns';
import { Field, FieldProps } from 'formik';
import { get, isEqual, sortBy } from 'lodash';
import React from 'react';
import './Forms.scss';

export interface FormDropDownListOption {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any;
  name: string;
}

interface Props {
  placeholderText?: string;
  label: string;
  name: string;
  data: FormDropDownListOption[];
  validator?: (value: string) => string | void;
  onChangeHandler?: (newValue: {}) => void;
  disabled?: boolean;
  tooltip?: string;
}

const WrappedDropDownList: React.FC<FieldProps & Props> = ({
  field,
  form,
  placeholderText,
  label,
  onChangeHandler,
  tooltip,
  data,
  ...rest
}) => {
  const errors = get(form.errors, field.name);
  const hasErrors = !!errors;
  const classNames = ['formInput'];

  let defaultItem: FormDropDownListOption | unknown;
  if (placeholderText) {
    defaultItem = { name: placeholderText, value: null };
  }

  if (hasErrors && form.submitCount) {
    classNames.push('formInputError');
  }

  data = sortBy(data, v => v.name.toLowerCase());

  const getSelectedValue = (): FormDropDownListOption | undefined => {
    return data.find(v => isEqual(v.value, field.value));
  };

  // NOTE: Keep the field / rest props at the top so we can override the onChange property
  return (
    <div title={tooltip}>
      <label htmlFor={field.name}>{label}</label>
      <DropDownList
        id={field.name}
        className={classNames.join(' ')}
        textField="name"
        dataItemKey="value"
        defaultItem={defaultItem}
        data={data}
        {...field}
        {...rest}
        onChange={(event): void => {
          form.setFieldValue(field.name, event.target.value.value);

          if (onChangeHandler) {
            onChangeHandler(event.target.value.value);
          }
        }}
        value={getSelectedValue()}
      />
    </div>
  );
};
const FormDropDownList: React.FC<Props> = props => {
  return (
    <div>
      <Field
        {...props}
        name={props.name}
        validate={props.validator}
        label={props.label}
        component={WrappedDropDownList}
        onChangeHandler={props.onChangeHandler}
      />
    </div>
  );
};
export default FormDropDownList;
