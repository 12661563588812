import * as Yup from 'yup';

// eslint-disable-next-line
Yup.addMethod(Yup.array, 'unique', function(message: string, mapper = (a: any) => a) {
  return this.test('unique', message, list => {
    return list.length === new Set(list.map(mapper)).size;
  });
});

export default Yup;
