import React, { useEffect, useState } from 'react';
import BTCard from '../../../../common-page-components/card/BTCard';
import './ProductListing.scss';

interface Props {
  product: Product;
  disableButtons: boolean;
  onProductEdit: (product: Product) => void;
  onProductDelete: (product: Product) => void;
}

const getMetadata = (product: Product): string[] => {
  const featureNames = product.features.map(f => f.name);
  return [product.id, product.name, ...featureNames];
};

const ProductListing: React.FC<Props> = props => {
  const { product } = props;

  const [metadata, setMetadata] = useState<string[]>(getMetadata(product));

  // Update metadata when product changes
  useEffect(() => {
    setMetadata(getMetadata(product));
  }, [product]);

  const onEditClick = (): void => {
    props.onProductEdit(product);
  };

  const onDeleteClick = (): void => {
    props.onProductDelete(product);
  };

  let deleteTooltip = props.disableButtons ? '' : 'Delete';
  if (product.hasActiveLicenses) {
    deleteTooltip = 'Cannot delete a product with active licenses';
  }

  return (
    <BTCard
      cardObject={product}
      sortValue={product.name}
      title={product.name}
      data-testid={`product-${product.name}`}
      metadata={metadata}
      cardIcons={[
        {
          buttonIcon: 'edit',
          tooltip: props.disableButtons ? '' : 'Edit',
          onClick: onEditClick,
          disabled: props.disableButtons,
          'data-testid': `product-edit-${product.name}`,
        },
        {
          buttonIcon: 'trash',
          tooltip: deleteTooltip,
          onClick: onDeleteClick,
          disabled: props.disableButtons || product.hasActiveLicenses,
          'data-testid': `product-delete-${product.name}`,
        },
      ]}
    >
      <div>
        <label className="cardLabel">Features</label>

        {!product.features || product.features.length === 0 ? (
          <div>(No Features)</div>
        ) : (
          <div className="featuresListings">
            {product.features.map(feature => (
              <div
                className=""
                key={feature.name}
                data-testid={`product-${product.name}-feature-${feature.name}`}
              >
                {feature.name}
              </div>
            ))}
          </div>
        )}
      </div>

      <div className="d-flex">
        <div>
          <label className="cardLabel">ID</label>
          <div>{product.id}</div>
        </div>

        <div className="flex-grow-1 text-right">
          <label className="cardLabel">Deployment Type</label>
          <div>{product.deploymentTypeId || '(Not Selected)'}</div>
        </div>
      </div>
    </BTCard>
  );
};

export default ProductListing;
