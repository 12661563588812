export interface LicenseViewDto {
  id: string;
  clientOrganizationId: string;
  productId: string;
  featureIds: string[];
  userCount: number;
  enforceStrictUserCount: boolean;
  effectiveDate: Date;
  expirationDate: Date;
  deploymentStatus: string | null;
  deploymentDetails: Dictionary<object>;
  adminUser: string;
}

export interface LicenseAddDto {
  clientOrganizationId: string;
  productId: string;
  featureIds: string[];
  userCount: number;
  enforceStrictUserCount: boolean;
  effectiveDate: Date;
  expirationDate: Date;
  adminUser: string | null;
}

export interface LicenseEditDto {
  clientOrganizationId: string;
  productId: string;
  featureIds: string[];
  userCount: number;
  enforceStrictUserCount: boolean;
  effectiveDate: Date;
  expirationDate: Date;
  adminUser: string | null;
}

export interface DeploymentRequest {
  licenseId: string;
  adminUser: string;
  emailDomains: string[];
}

// NOTE: Right now we are not returning everything from the API and must populate later
export const getLicenseFromDto = (licenseDto: LicenseViewDto): License => {
  const license: License = {
    id: licenseDto.id,
    clientOrganization: {
      id: licenseDto.clientOrganizationId,
      name: '', // TODO: get from server
      domains: [''], //TODO: get from server
    },
    product: {
      id: licenseDto.productId,
      name: '', // TODO: get from server
      features: licenseDto.featureIds.map<ProductFeature>(featureId => ({
        id: featureId,
        name: '', // TODO: get from server
        hasActiveLicenses: false, // TODO: get from server
        isNew: false,
      })),
      deploymentTypeId: null,
    },
    userCount: licenseDto.userCount,
    effectiveDate: licenseDto.effectiveDate,
    expirationDate: licenseDto.expirationDate,
    enforceStrictUserCount: licenseDto.enforceStrictUserCount,
    isNew: false,
    deploymentStatus: licenseDto.deploymentStatus,
    deploymentDetails: licenseDto.deploymentDetails,
    adminUser: licenseDto.adminUser,
  };

  return license;
};
