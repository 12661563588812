import { isEqual } from 'lodash';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { Collapse } from 'reactstrap';
import ApplicationRoutes from '../../../../constants/routes';
import { UserApi } from '../../../../services/api';
import { scrollToRef } from '../../../../utils/general';
import BTCardsContainer from '../../../common-page-components/cards-container/BTCardsContainer';
import { ConfirmationOverlayContext } from '../../../common-page-components/confirmation-overlay/ConfirmationOverlayContext';
import BTFloatingIconButton from '../../../common-page-components/controls/floating-icon-button/BTFloatingIconButton';
import { LoadingPageOverlayContext } from '../../../common-page-components/loading-page-overlay/LoadingPageOverlayContext';
import { NotificationPanelContext } from '../../../common-page-components/notification-panel/NotificationPanelContext';
import UserEditForm from './components/UserEditForm';
import UserListing from './components/UserListing';

const UsersPage: React.FC = () => {
  const history = useHistory();

  const { showPageLoadingOverlay, hidePageLoadingOverlay } = useContext(LoadingPageOverlayContext);
  const { showConfirmationOverlay, hideConfirmationOverlay } = useContext(
    ConfirmationOverlayContext,
  );
  const { showSuccessNotification, showInfoNotification, showErrorNotification } = useContext(
    NotificationPanelContext,
  );

  const [isEditAreaOpen, setIsEditAreaOpen] = useState<boolean>(false);
  const [isPageDataLoaded, setIsPageDataLoaded] = useState<boolean>(false);
  const [userToEdit, setUserToEdit] = useState<User | null>();
  const [users, setUsers] = useState<User[]>([]);
  const adminPageEditAreaRef = useRef(null);

  // Initial load of data
  useEffect(() => {
    const loadPageData = async (): Promise<void> => {
      try {
        showPageLoadingOverlay();

        const usersData = await UserApi.getUsers();
        setUsers(usersData);

        setIsPageDataLoaded(true);
      } catch (error) {
        // In this case, we should send the user to an error page since this page isn't very useful without this core data
        showErrorNotification('Error', 'An error occurred while loading the page.');
        history.push(ApplicationRoutes.ERROR_ERROR);
      } finally {
        hidePageLoadingOverlay();
      }
    };

    loadPageData();
  }, [hidePageLoadingOverlay, history, showPageLoadingOverlay, showErrorNotification]);

  const editUserButtonClick = (user: User | null): void => {
    if (isEditAreaOpen) {
      return;
    }

    if (!user) {
      user = {
        emailAddress: '',
        isNew: true,
        id: null,
        isBeckTechUser: false,
        roles: [],
      };
    }

    setUserToEdit({ ...user });

    // Delay this slightly so the edit area has time to animate in
    setTimeout(() => {
      scrollToRef(adminPageEditAreaRef);
    }, 500);

    setIsEditAreaOpen(true);
  };

  const deleteUserButtonClick = (user: User): void => {
    if (isEditAreaOpen) {
      return;
    }

    const deleteUser = async (): Promise<void> => {
      showPageLoadingOverlay();

      try {
        if (!user.id) {
          showErrorNotification('Error', 'An error occurred while attempting to delete the user.');
          return;
        }

        await UserApi.deleteUser(user.id);

        const newUsers = users.filter(u => u.id !== user.id);
        setUsers(newUsers);

        showSuccessNotification('Success', 'The user was deleted successfully.');
      } catch {
        showErrorNotification('Error', 'An error occurred while attempting to delete the user.');
      } finally {
        hidePageLoadingOverlay();
      }
    };

    showConfirmationOverlay({
      title: 'Confirm',
      text: `Are you sure you want to delete the user "${user.emailAddress}"?`,
      buttons: [
        {
          text: 'Yes',
          onClick: async (): Promise<void> => {
            hideConfirmationOverlay();
            await deleteUser();
          },
        },
        {
          text: 'No',
          onClick: (): void => hideConfirmationOverlay(),
          color: 'gray',
        },
      ],
    });
  };

  const onDiscard = (user: User): void => {
    const discardChanges = (): void => {
      setIsEditAreaOpen(false);

      // Delay this slightly so the edit area has time to animate closed
      setTimeout(() => {
        setUserToEdit(null);
      }, 250);
    };

    if (!isEqual(user, userToEdit)) {
      showConfirmationOverlay({
        title: 'Confirm',
        text: 'Are you sure you want to discard your unsaved changes?',
        buttons: [
          {
            text: 'Yes',
            onClick: (): void => {
              discardChanges();
              hideConfirmationOverlay();
            },
            color: 'normal',
          },
          {
            text: 'No',
            onClick: (): void => {
              hideConfirmationOverlay();
            },
            color: 'gray',
          },
        ],
      });
    } else {
      discardChanges();
    }
  };

  const onSubmit = async (user: User): Promise<void> => {
    showPageLoadingOverlay();

    const closeEditAreaAndClearOutEditModel = (): void => {
      setIsEditAreaOpen(false);

      // Delay this slightly so the edit area has time to animate closed
      setTimeout(() => {
        setUserToEdit(null);
      }, 250);
    };

    try {
      if (isEqual(user, userToEdit)) {
        closeEditAreaAndClearOutEditModel();

        showInfoNotification('No Changes', 'No changes were made to the user.');
        return;
      }

      // NOTE: The add / edit functionality hits the same API endpoint

      if (user.isNew) {
        const updatedUserObj = await UserApi.addUser(user);
        setUsers([...users, updatedUserObj]);
      } else {
        await UserApi.editUser(user);

        const indexOfUserToUpdate = users.findIndex(v => v.id === user.id);
        if (indexOfUserToUpdate === -1) {
          throw new Error(`Could not find user with id '${user.id}'.`);
        }
        const newUsersArray = [...users];
        newUsersArray.splice(indexOfUserToUpdate, 1, user);
        setUsers(newUsersArray);
      }

      closeEditAreaAndClearOutEditModel();

      showSuccessNotification('Success', 'The user was updated successfully.');
    } catch (error) {
      showErrorNotification(
        'Error',
        `An error occurred while attempting to ${user.isNew ? 'add' : 'update'} the user.`,
      );
    } finally {
      hidePageLoadingOverlay();
    }
  };

  if (!isPageDataLoaded) {
    return <></>;
  }

  return (
    <>
      <div className="container">
        <h1>Users</h1>
      </div>

      <div className="adminPageEditArea" ref={adminPageEditAreaRef}>
        <Collapse isOpen={isEditAreaOpen}>
          <div className="container-fluid grayBackground">
            <div className="container">
              {userToEdit && (
                <UserEditForm
                  user={userToEdit}
                  allUsers={users}
                  onDiscardClick={onDiscard}
                  onSubmitClick={onSubmit}
                />
              )}
            </div>
          </div>
        </Collapse>
      </div>

      <div className="container">
        <BTCardsContainer enableSearch recordsNotFoundMessage="No users were found.">
          {users.map(currUser => {
            return (
              <UserListing
                key={currUser.id!}
                user={currUser}
                isEditAreaOpen={isEditAreaOpen}
                editUserButtonClick={editUserButtonClick}
                deleteUserButtonClick={deleteUserButtonClick}
              />
            );
          })}
        </BTCardsContainer>
      </div>

      <BTFloatingIconButton
        buttonIcon="plus"
        onClick={(): void => editUserButtonClick(null)}
        tooltip="Add new user"
        disabled={isEditAreaOpen}
      />
    </>
  );
};

export default UsersPage;
