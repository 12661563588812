import 'hammerjs'; //For KendoCharts event handling
import moment from 'moment';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router';
import ApplicationRoutes from '../../../constants/routes';
import UserConstants from '../../../constants/users';
import { ProductApi } from '../../../services/api';
import { saveFile } from '../../../utils/file';
import { isDevelopmentEnvironment, scrollToRef } from '../../../utils/general';
import { populateUserInformationFromLocalStorage } from '../../../utils/user-authorization';
import BTButton from '../../common-page-components/controls/button/BTButton';
import { LoadingPageOverlayContext } from '../../common-page-components/loading-page-overlay/LoadingPageOverlayContext';
import { NotificationPanelContext } from '../../common-page-components/notification-panel/NotificationPanelContext';
import ReportFilterConstants from './../../../constants/reports/filters';
import ClientApi from './../../../services/api/clients/clients-api-service';
import { UserInformationContext } from './../../UserInformationContext';
import ReportsPageCustomChart from './components/ReportsPageCustomChart';
import ReportsPageCustomFilters from './components/ReportsPageCustomFilters';
import ReportsPageFilters from './components/ReportsPageFilters';
import ReportsPageReportsListing from './components/ReportsPageReportsListing';
import { ReportNames } from './reports';
import './ReportsPage.scss';

const LOCAL_STORAGE_REPORT_FILTERS = 'ModifiedBaseReportFilters';

const ReportNameRouteMappings = {
  [ApplicationRoutes.REPORTS_USAGE.toLowerCase()]: ReportNames.Usage,
  [ApplicationRoutes.REPORTS_DURATION.toLowerCase()]: ReportNames.Duration,
  [ApplicationRoutes.REPORTS_CHECKOUTS.toLowerCase()]: ReportNames.Checkouts,
  [ApplicationRoutes.REPORTS_RAWDATA.toLowerCase()]: ReportNames.RawData,
};

const generateReportFiltersObject = (
  clients: ClientOrganization[],
  products: Product[],
): ReportFiltersBase => {
  // Attempt to return existing filter settings from local storage.
  const localStorageReportFilters = localStorage.getItem(LOCAL_STORAGE_REPORT_FILTERS);
  if (localStorageReportFilters) {
    try {
      const localStorageReportFiltersBase: ReportFiltersBase = JSON.parse(
        localStorageReportFilters,
      );

      const cachedClientId =
        localStorageReportFiltersBase.client === null
          ? null
          : localStorageReportFiltersBase.client.id;

      const cachedProductId =
        localStorageReportFiltersBase.product === null
          ? null
          : localStorageReportFiltersBase.product.id;

      const filters: ReportFiltersBase = {
        client: !cachedClientId ? null : clients.find(c => c.id === cachedClientId) || null,
        product: !cachedProductId ? null : products.find(p => p.id === cachedProductId) || null,
        defaultFromDate: new Date(localStorageReportFiltersBase.defaultFromDate),
        defaultToDate: new Date(localStorageReportFiltersBase.defaultToDate),
        fromDate: new Date(localStorageReportFiltersBase.fromDate),
        toDate: new Date(localStorageReportFiltersBase.toDate),
        reportName: localStorageReportFiltersBase.reportName,
        calendarType: localStorageReportFiltersBase.calendarType,
      };

      return filters;
    } catch {
      // We intentionally leave this catch empty and allow the code to continue
    }
  }

  const defaultFromDate = moment()
    .add(-7, 'days')
    .startOf('day')
    .toDate();

  const defaultToDate = moment()
    .startOf('day')
    .toDate();

  const defaultFilters = {
    client: clients[0] || null,
    product: products[0] || null,
    defaultFromDate: defaultFromDate,
    defaultToDate: defaultToDate,
    fromDate: defaultFromDate,
    toDate: defaultToDate,
    reportName: ReportNames.Usage,
    calendarType: ReportFilterConstants.FILTERINCREMENT_DAY,
  };

  return defaultFilters;
};

const saveReportFiltersToLocalStorage = (newReportFilters: ReportFiltersBase): void => {
  localStorage.setItem(LOCAL_STORAGE_REPORT_FILTERS, JSON.stringify(newReportFilters));
};

const removeReportFiltersFromLocalStorage = (): void => {
  localStorage.removeItem(LOCAL_STORAGE_REPORT_FILTERS);
};

const ReportsPage: React.FC<{}> = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const customChartRef = useRef(null);
  const [isPageDataLoaded, setIsPageDataLoaded] = useState<boolean>(false);

  const availableReports: Report[] = [
    {
      icon: 'wrench',
      name: t('reporting:reportNames.usage'),
      url: ApplicationRoutes.REPORTS_USAGE,
    },
    {
      icon: 'shopping-basket',
      name: t('reporting:reportNames.checkouts'),
      url: ApplicationRoutes.REPORTS_CHECKOUTS,
    },
    {
      icon: 'clock',
      name: t('reporting:reportNames.duration'),
      url: ApplicationRoutes.REPORTS_DURATION,
    },
    {
      icon: 'database',
      name: t('reporting:reportNames.rawData'),
      url: ApplicationRoutes.REPORTS_RAWDATA,
    },
  ];

  const { showPageLoadingOverlay, hidePageLoadingOverlay } = useContext(LoadingPageOverlayContext);

  const { userInformation } = useContext(UserInformationContext);

  const { showErrorNotification, showInfoNotification } = useContext(NotificationPanelContext);

  const [currentChartData, setCurrentChartData] = useState<BTChartDataWithFilters | null>(null);

  const [availableClients, setAvailableClients] = useState<ClientOrganization[]>([]);

  const [availableProducts, setAvailableProducts] = useState<Product[]>([]);

  const [clientProductsCache, setClientProductsCache] = useState<Dictionary<Product[]>>({});

  const [reportFilters, setReportFilters] = useState<ReportFiltersBase>();

  // Use state for all of the reports filters so the user can jump between reports while retaining previously selected filter values
  const [allSpecificReportFilters, setAllSpecificReportFilters] = useState<
    Dictionary<SpecificReportFiltersBase>
  >({});

  // Maintain a list of reports chart data
  const [allReportsData, setAllReportsData] = useState<Dictionary<BTChartData>>({});

  // Maintain a list of report data sources
  const [allReportDataSources, setAllReportDataSources] = useState<
    Dictionary<() => Promise<BTChartData>>
  >({});

  const [isLoadingData, setIsLoadingData] = useState<boolean>(false);
  const [chartDataErrorOccurred, setChartDataErrorOccurred] = useState<boolean>(false);

  const addAllProductsOption = useCallback(
    (clientProducts: Product[]): Product[] => {
      clientProducts.unshift({
        id: ReportFilterConstants.PRODUCTOPTION_ALL_PRODUCTS,
        name: t('reporting:filters.productOptionAllProducts'),
        isNew: false,
        hasActiveLicenses: true,
        features: [],
        deploymentTypeId: '',
      });
      return clientProducts;
    },
    [t],
  );

  const addNoProductsOption = useCallback(
    (clientProducts: Product[]): Product[] => {
      clientProducts.unshift({
        id: ReportFilterConstants.PRODUCTOPTION_NO_PRODUCTS,
        name: t('reporting:filters.productOptionNoProducts'),
        isNew: false,
        hasActiveLicenses: false,
        features: [],
        deploymentTypeId: '',
      });
      return clientProducts;
    },
    [t],
  );

  const checkAndUpdateProductOptions = useCallback(
    (clientProducts: Product[]): Product[] => {
      if (clientProducts.length < 1) {
        return addNoProductsOption(clientProducts);
      } else if (clientProducts.length > 1) {
        return addAllProductsOption(clientProducts);
      }
      return clientProducts;
    },
    [addAllProductsOption, addNoProductsOption],
  );

  const updateClientProductsCache = useCallback(
    (clientId: string, clientProducts: Product[]): void => {
      setClientProductsCache({
        ...clientProductsCache,
        [clientId]: clientProducts,
      });
    },
    [clientProductsCache, setClientProductsCache],
  );

  useEffect(() => {
    const loadPageData = async (): Promise<void> => {
      showPageLoadingOverlay();

      const reportName =
        ReportNameRouteMappings[location.pathname.toLowerCase()] ||
        ReportNameRouteMappings[ApplicationRoutes.REPORTS_USAGE];

      if (userInformation && (userInformation.userIsAdmin || userInformation.userIsInternalUser)) {
        try {
          const [clients, allProducts] = await Promise.all([
            await ClientApi.getClientOrganizations(),
            await ProductApi.getProducts(),
          ]);

          if (!clients.length) {
            throw new Error();
          }
          setAvailableClients(clients);

          const reportFilters = generateReportFiltersObject(clients, allProducts);

          const client = reportFilters.client || clients[0];
          let clientProducts = await ProductApi.getProductsByClient(client);
          clientProducts = checkAndUpdateProductOptions(clientProducts);
          setAvailableProducts(clientProducts);

          const updatedReportFilters = {
            ...reportFilters,
            client: reportFilters.client || clients[0] || null,
            product: reportFilters.product || clientProducts[0] || null,
            reportName: reportName,
          };

          setReportFilters(updatedReportFilters);

          // If the calendar type was populated, set it as the increment for the specific report filter
          if (updatedReportFilters.calendarType) {
            setAllSpecificReportFilters(v => ({
              ...v,
              [updatedReportFilters.reportName]: {
                increment: updatedReportFilters.calendarType,
              },
            }));
          }

          setIsPageDataLoaded(true);
        } catch (error) {
          showErrorNotification('Error', 'An error occurred while loading the page.');
          history.push(ApplicationRoutes.ERROR_ERROR);
        } finally {
          hidePageLoadingOverlay();
        }
      } else {
        try {
          const user = populateUserInformationFromLocalStorage();
          if (user) {
            const client = await ClientApi.getClientOrganizationByEmail(user.emailAddress);
            let clientProducts = await ProductApi.getProductsByClient(client);
            clientProducts = checkAndUpdateProductOptions(clientProducts);
            setAvailableProducts(clientProducts);

            const reportFilters = generateReportFiltersObject([client], clientProducts);

            const updatedReportFilters = {
              ...reportFilters,
              client: client || null,
              reportName: reportName,
            };

            setReportFilters(updatedReportFilters);

            // If the calendar type was populated, set it as the increment for the specific report filter
            if (updatedReportFilters.calendarType) {
              setAllSpecificReportFilters(v => {
                return {
                  ...v,
                  [updatedReportFilters.reportName]: {
                    increment: updatedReportFilters.calendarType,
                  },
                };
              });
            }

            setIsPageDataLoaded(true);
          } else {
            throw new Error('Unable to pull user information from local storage.');
          }
        } catch (error) {
          showErrorNotification('Error', 'An error occurred while loading the page.');
          history.push(ApplicationRoutes.ERROR_ERROR);
        } finally {
          hidePageLoadingOverlay();
        }
      }
    };

    loadPageData();
  }, [
    history,
    userInformation,
    addAllProductsOption,
    addNoProductsOption,
    checkAndUpdateProductOptions,
    hidePageLoadingOverlay,
    showErrorNotification,
    showInfoNotification,
    showPageLoadingOverlay,
    location.pathname,
  ]);

  const getChartData = async (): Promise<void> => {
    if (!reportFilters) {
      return;
    }

    setIsLoadingData(true);

    showPageLoadingOverlay();

    try {
      const currReportDataSource = allReportDataSources[reportFilters.reportName];

      if (currReportDataSource) {
        const reportsData = await currReportDataSource();

        // For the raw data, the promise we await will actually return binary data, not JSON,
        // so we don't want to put this in the state variable that holds our chart data
        if (reportFilters.reportName === ReportNames.RawData) {
          saveFile(reportsData, 'Export', 'csv');
          return;
        }

        setAllReportsData({
          ...allReportsData,
          [reportFilters.reportName]: reportsData,
        });

        const chartData: BTChartDataWithFilters = {
          ...reportsData,
          appliedFilters: {
            baseFilters: reportFilters,
            specificFilters: allSpecificReportFilters[reportFilters.reportName],
          },
        };

        setCurrentChartData(chartData);
      }
    } catch (error) {
      setChartDataErrorOccurred(true);
    } finally {
      setIsLoadingData(false);
      hidePageLoadingOverlay();
    }
  };

  const getProductsForClient = (clientId: string): void => {
    if (!reportFilters) {
      return;
    }

    const loadProductData = async (): Promise<void> => {
      try {
        const getProduct = (products: Product[], filters: ReportFiltersBase): Product | null => {
          if (!filters || !filters.product || products.length === 0) {
            return null;
          }
          const product = products.find(p => p.id === filters!.product!.id);
          return product || products[0];
        };

        const client = availableClients.find(c => c.id === clientId);
        if (client) {
          //Attempt to use cached products first and avoid a round trip
          const cachedProducts: Product[] = clientProductsCache[client.id];
          if (cachedProducts) {
            setAvailableProducts(cachedProducts);
            setReportFilters(filters => {
              if (!filters) {
                return filters;
              }

              return {
                ...filters,
                product: getProduct(cachedProducts, filters),
              };
            });
          } else {
            //Else fetch products for the client and cache the results
            showPageLoadingOverlay();
            let clientProducts = await ProductApi.getProductsByClient(client);
            clientProducts = checkAndUpdateProductOptions(clientProducts);
            setAvailableProducts(clientProducts);
            updateClientProductsCache(client.id, clientProducts);
            setReportFilters(filters => {
              if (!filters) {
                return filters;
              }

              return {
                ...filters,
                product: getProduct(clientProducts, filters),
              };
            });
          }
        }
      } catch (error) {
        showErrorNotification('Error', 'An error occurred while loading products.');
        history.push(ApplicationRoutes.ERROR_ERROR);
      } finally {
        hidePageLoadingOverlay();
      }
    };
    if (reportFilters.client) {
      loadProductData();
    }
  };

  const setSpecificReportFilters = useCallback(
    (reportName: string, specificReportFilters: SpecificReportFiltersBase): void => {
      if (!reportFilters) {
        return;
      }

      // Update our internal state collection for the rendered report
      setAllSpecificReportFilters(reportFilters => ({
        ...reportFilters,
        [reportName]: specificReportFilters,
      }));

      const newReportFilters = {
        ...reportFilters,
        calendarType: (specificReportFilters as ReportFiltersWithIncrement).increment,
      };
      setReportFilters(newReportFilters);

      saveReportFiltersToLocalStorage(newReportFilters);
    },
    [reportFilters, setAllSpecificReportFilters, setReportFilters],
  );

  const setCalendarType = useCallback(
    (calendarType: string): void => {
      setReportFilters(reportFilters => {
        if (!reportFilters) {
          return reportFilters;
        }

        return {
          ...reportFilters,
          calendarType: calendarType,
        };
      });
    },
    [setReportFilters],
  );

  const setSpecificReportDataSource = useCallback(
    (reportName: string, reportDataSource: () => Promise<{}>): void => {
      // Update our internal state collection for the rendered report
      setAllReportDataSources(dataSources => ({
        ...dataSources,
        [reportName]: reportDataSource,
      }));
    },
    [setAllReportDataSources],
  );

  if (!isPageDataLoaded || !reportFilters) {
    return <></>;
  }

  return (
    <div className="reportsPage">
      <div className="container">
        <h1>{t('reporting:reports')}</h1>

        <ReportsPageReportsListing
          availableReports={availableReports}
          reportFilters={reportFilters}
          setReportFilters={(reportFiltersBase: ReportFiltersBase): void => {
            setReportFilters(reportFiltersBase);
          }}
          onClick={(): void => setCurrentChartData(null)}
        />

        <div className="filtersArea">
          <h2>{t('reporting:filters.filters')}</h2>

          <ReportsPageFilters
            availableClients={availableClients}
            availableProducts={availableProducts}
            reportFilters={reportFilters}
            getProductsForClient={getProductsForClient}
            setReportFilters={(newReportFilters: ReportFiltersBase): void => {
              setReportFilters({
                ...reportFilters,
                ...newReportFilters,
                // Only let the client be updated if the user has correct permissions, as an extra layer of security
                client:
                  userInformation &&
                  userInformation.userRoles.find(
                    v =>
                      v.id === UserConstants.UserRolesValues.ADMIN ||
                      v.id === UserConstants.UserRolesValues.INTERNALUSER,
                  )
                    ? newReportFilters.client
                    : reportFilters.client,
                product: newReportFilters.product,
              });
              saveReportFiltersToLocalStorage(newReportFilters);
            }}
          />

          <ReportsPageCustomFilters
            reportFilters={reportFilters}
            allSpecificReportFilters={allSpecificReportFilters}
            setSpecificReportFilters={setSpecificReportFilters}
            setReportDataSource={setSpecificReportDataSource}
            setCalendarType={setCalendarType}
          />
        </div>

        <div className="filterActionsArea">
          {/* Only show the 'apply' button if the current report provided a data source. If not, it can handle things itself. */}
          {allReportDataSources[reportFilters.reportName] && (
            <BTButton
              text={
                reportFilters.reportName === ReportNames.RawData
                  ? t('general:download')
                  : t('general:apply')
              }
              disabled={
                isLoadingData ||
                (availableProducts[0] &&
                  availableProducts[0].id === ReportFilterConstants.PRODUCTOPTION_NO_PRODUCTS)
              }
              onClick={(): void => {
                setChartDataErrorOccurred(false);
                if (reportFilters.reportName === ReportNames.RawData) {
                  showInfoNotification('Downloading', 'Your download will begin shortly...');
                  //Adding a slight delay to allow the user time to process the notification before actually serving the data
                  setTimeout(() => {
                    getChartData();
                  }, 1500);
                } else {
                  const getChartDataAsync = async (): Promise<void> => {
                    await getChartData();
                    setTimeout(() => {
                      scrollToRef(customChartRef);
                    }, 500);
                  };
                  getChartDataAsync();
                }
              }}
            />
          )}

          <BTButton
            text={t('general:reset')}
            color="gray"
            onClick={(): void => {
              removeReportFiltersFromLocalStorage();
              setReportFilters({
                ...generateReportFiltersObject(availableClients, availableProducts),
                reportName: reportFilters.reportName, // Keep the report name the same
              });

              // Remove the specific report filters object from state -- the active filter component should regenerate its state
              setAllSpecificReportFilters({
                ...allSpecificReportFilters,
                [reportFilters.reportName]: undefined,
              });
            }}
          />
        </div>

        {chartDataErrorOccurred ? (
          <div className="chartDataErrorMessage">
            <h1>We're sorry, but an error occurred while loading data.</h1>

            {isDevelopmentEnvironment ? (
              <div className="d-flex justify-content-center my-5">
                <img className="img-fluid" src="/images/error/SadEmoji.png" alt="Error" />
              </div>
            ) : (
              <></>
            )}
          </div>
        ) : (
          currentChartData && ( // There is a known issue with the chart below that causes additional redraws of the chart when the client changes or filters are applied
            <div className="reportChartArea" ref={customChartRef}>
              <ReportsPageCustomChart data={currentChartData} />
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default ReportsPage;
