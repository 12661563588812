export const Settings = {
  colors: {
    blue: '#0033a0',
    red: 'rgba(239, 47, 33, 0.9)',
  },
  chartAreaCeiling: 10,
  chartAreaFloor: 1,
};

export default { Settings };
