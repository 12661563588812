import React from 'react';
import { useHistory } from 'react-router';
import ApplicationRoutes from '../../../../../constants/routes';
import { isDevelopmentEnvironment } from '../../../../../utils/general';
import SvgImage from '../../../../common-page-components/svg-image/SvgImage';

const PageNotFoundPage: React.FC = () => {
  const history = useHistory();

  return (
    <div className="errorPage">
      <div className="container">
        <div className="errorMessageArea">
          <div className="errorImage text-center">
            <SvgImage size="xl" image="exclamation" />
          </div>
        </div>

        <div className="mt-4 text-center">
          <h1>
            Sorry, but we can't find that page.
            {isDevelopmentEnvironment && (
              <>
                <br />
                <br />
                Maybe if we had used DESTINI Estimator during site preconstruction, we could have
                made enough money to build all the pages.
              </>
            )}
          </h1>
        </div>

        {isDevelopmentEnvironment ? (
          <div className="d-flex justify-content-center my-5">
            <img
              className="img-fluid shadow rounded-lg pointer"
              src="/images/error/NotFound.jpg"
              alt="Page not found"
              onClick={(): void => history.push(ApplicationRoutes.SITE_ROOT)}
              title="Click here to go home, Forrest"
            />
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default PageNotFoundPage;
