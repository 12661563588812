import React from 'react';
import basket from './../../assets/icons/basket.svg';
import bulb from './../../assets/icons/bulb.svg';
import certificate from './../../assets/icons/certificate.svg';
import clock from './../../assets/icons/clock.svg';
import database from './../../assets/icons/database.svg';
import error from './../../assets/icons/error.svg';
import exclamation from './../../assets/icons/exclamation.svg';
import key from './../../assets/icons/key.svg';
import estimator from './../../assets/icons/products/estimator.svg';
import stop from './../../assets/icons/stop.svg';
import tools from './../../assets/icons/tools.svg';
import users from './../../assets/icons/users.svg';
import users2 from './../../assets/icons/users2.svg';
import verifiedCheck from './../../assets/icons/verified-check.svg';
import './SvgImage.scss';

export type SvgImages =
  | 'basket'
  | 'bulb'
  | 'certificate'
  | 'clock'
  | 'error'
  | 'database'
  | 'estimator'
  | 'exclamation'
  | 'key'
  | 'stop'
  | 'tools'
  | 'users'
  | 'users2'
  | 'verified-check';
type SvgImageSizes = 'sm' | 'lg' | 'xl';

interface Props {
  image: SvgImages;
  size?: SvgImageSizes;
}

const SvgImage: React.FC<Props> = ({ image, size }) => {
  const getSvgImage = (): string => {
    switch (image) {
      case 'basket':
        return basket;
      case 'bulb':
        return bulb;
      case 'certificate':
        return certificate;
      case 'clock':
        return clock;
      case 'database':
        return database;
      case 'error':
        return error;
      case 'estimator':
        return estimator;
      case 'exclamation':
        return exclamation;
      case 'key':
        return key;
      case 'stop':
        return stop;
      case 'tools':
        return tools;
      case 'users':
        return users;
      case 'users2':
        return users2;
      case 'verified-check':
        return verifiedCheck;
      default:
        return '';
    }
  };

  const getSizeClass = (): string => {
    switch (size) {
      case 'sm':
        return 'sm';
      case 'xl':
        return 'xl';
      case 'lg':
      default:
        return 'lg';
    }
  };

  return (
    <div className={['svgImage', getSizeClass()].join(' ')}>
      <img className="img-fluid" src={getSvgImage()} alt={image} />
    </div>
  );
};

export default SvgImage;
