import i18n from 'i18next';
import moment from 'moment';
import { importAll, Import } from '../utils/imports';

export const DEFAULT_LANG = 'en-US';

export const TranslationFilesMap: Dictionary<() => Import[]> = {
  'en-US': () => importAll(require.context('./translations/en-US', false, /\.json$/i)),
  'fr-CA': () => importAll(require.context('./translations/fr-CA', false, /\.json$/i)),
};

export const loadTranslationsForLocale = (locale: string): void => {
  const getTranslationsFunc = TranslationFilesMap[locale] as () => Import[];

  if (!getTranslationsFunc) {
    throw new Error(
      `Could not find translations for locale "${locale}". Verify that they are added to "translationFilesMap" in "i18n.ts"`,
    );
  }

  const translationFiles = getTranslationsFunc();

  translationFiles.forEach(translationFile => {
    i18n.addResourceBundle(locale, translationFile.name, translationFile.translations);
  });
};

// NOTE: This function declaration is defined by i18n so we must abide by their types
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const format = (value: any, format?: string, lng?: string): string => {
  if (value instanceof Date) {
    if (!lng) {
      throw new Error('`lng` must have a value for date formatting');
    }

    const date = moment(value).locale(lng);

    if (format === 'shortDate') {
      return date.format('L');
    } else if (format === 'longDate') {
      return date.format('LL');
    }

    return date.toString();
  }

  if (typeof value === 'number') {
    const num = value as number;

    if (format === 'numberFormat') {
      return num.toLocaleString(lng);
    }
  }

  return value.toString();
};
