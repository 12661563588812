const ForbiddenDomains = [
  'gmail.com',
  'yahoo.com',
  'hotmail.com',
  'aol.com',
  'msn.com',
  'live.com',
  'comcast.net',
  'live.com',
];

export default ForbiddenDomains;
