import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Button } from 'reactstrap';
import './BTFloatingIconButton.scss';

interface Props {
  buttonIcon: IconProp;
  disabled?: boolean;
  onClick: () => void;
  tooltip?: string;
  'data-testid'?: string;
}

const BTFloatingIconButton: React.FC<Props> = props => {
  return (
    <div className="container btFloatingIconButtonContainer">
      {/* NOTE: We put the test ID on the button itself, since that is where click events are, etc. */}
      <Button
        className="btFloatingIconButton shadow rounded-circle"
        onClick={(): void => {
          props.onClick();
        }}
        disabled={props.disabled}
        title={props.tooltip}
        data-testid={props['data-testid']}
      >
        <FontAwesomeIcon icon={props.buttonIcon} className="buttonIcon" />
      </Button>
    </div>
  );
};

export default BTFloatingIconButton;
