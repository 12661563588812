import { DatePicker } from '@progress/kendo-react-dateinputs';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import moment from 'moment';
import React, { useContext } from 'react';
import { FormDropDownListOption } from '../../../common-page-components/forms/FormDropDownList';
import { NotificationPanelContext } from '../../../common-page-components/notification-panel/NotificationPanelContext';
import { UserInformationContext } from '../../../UserInformationContext';
import { useTranslation } from 'react-i18next';
import { applyPartialDateRangeRules } from '../../../../utils/calendars';
import ReportFilterConstants from './../../../../constants/reports/filters';
import { sortBy } from 'lodash';

interface Props {
  availableClients: ClientOrganization[];
  availableProducts: Product[];
  reportFilters: ReportFiltersBase;
  getProductsForClient: (clientId: string) => void;
  setReportFilters: (reportFilters: ReportFiltersBase) => void;
}

const ReportsPageFilters: React.FC<Props> = ({
  availableClients,
  availableProducts,
  reportFilters,
  getProductsForClient,
  setReportFilters,
}) => {
  const { t } = useTranslation();
  const { userInformation } = useContext(UserInformationContext);

  const { showInfoNotification } = useContext(NotificationPanelContext);

  const resetAllDatePickers = (): void => {
    setReportFilters({
      ...reportFilters,
      toDate: applyPartialDateRangeRules(
        reportFilters.defaultToDate,
        reportFilters.calendarType,
        false,
      ),
      fromDate: applyPartialDateRangeRules(
        reportFilters.defaultFromDate,
        reportFilters.calendarType,
        true,
      ),
    });
  };

  const resetToDatePicker = (): void => {
    setReportFilters({
      ...reportFilters,
      toDate: applyPartialDateRangeRules(
        reportFilters.defaultToDate,
        reportFilters.calendarType,
        false,
      ),
    });
  };

  const resetFromDatePicker = (): void => {
    setReportFilters({
      ...reportFilters,
      fromDate: applyPartialDateRangeRules(
        reportFilters.defaultFromDate,
        reportFilters.calendarType,
        true,
      ),
    });
  };

  const getClientsForDropdown = (items: ClientOrganization[]): FormDropDownListOption[] => {
    return sortBy(
      items.map(v => {
        return {
          name: v.name,
          value: v.id,
        };
      }),
      v => v.name,
    );
  };

  const getProductsForDropdown = (items: Product[]): FormDropDownListOption[] => {
    return sortBy(
      items.map(v => {
        return {
          name: v.name,
          value: v.id,
        };
      }),
      v => v.name,
    );
  };

  return (
    <div className="row">
      {userInformation && (userInformation.userIsAdmin || userInformation.userIsInternalUser) && (
        <div className="col-12 col-md-3 mt-3">
          <label htmlFor="clientsDropdown">{t('reporting:filters.clients')}</label>

          <DropDownList
            id="clientsDropdown"
            name="clients"
            data={getClientsForDropdown(availableClients)}
            textField="name"
            dataItemKey="value"
            onChange={(event): void => {
              const id = (event.target.value as FormDropDownListOption).value;
              //Avoid triggering a reload of product data if the user reselects the same value
              if (
                !reportFilters.client ||
                (reportFilters.client && id !== reportFilters.client.id)
              ) {
                setReportFilters({
                  ...reportFilters,
                  client: availableClients.find(v => v.id === id)!,
                });
                getProductsForClient(id);
              }
            }}
            value={
              availableClients.find(
                currClient => reportFilters.client && reportFilters.client.name === currClient.name,
              ) ||
              availableClients[0] ||
              null
            }
          />
        </div>
      )}
      <div className="col-12 col-md-3 mt-3">
        <label htmlFor="productsDropdown">{t('reporting:filters.products')}</label>

        <DropDownList
          id="productsDropdown"
          name="products"
          data={getProductsForDropdown(availableProducts)}
          textField="name"
          dataItemKey="value"
          onChange={(event): void => {
            setReportFilters({
              ...reportFilters,
              product: availableProducts.find(
                v => v.id === (event.target.value as FormDropDownListOption).value,
              )!,
            });
          }}
          value={
            availableProducts.find(
              currProduct =>
                reportFilters.product && reportFilters.product.name === currProduct.name,
            ) ||
            availableProducts[0] ||
            null
          }
        />
      </div>
      {/*
       * The DatePickers below are using the onChange and onBlur hooks to modify and validate their values.
       * Keying the date manually will trigger onChange events until the user clicks away, which triggers an onBlur,
       * while selecting a date from the calendar control will trigger both an onChange, then an onBlur.
       * ----------------------------------------------------------------------------------------------------
       * onChange is handling modification of the data before setting it on the reportFilters state object.
       * We won't be validating here since manually keyed dates can fail validation prior to being completely typed
       * ----------------------------------------------------------------------------------------------------
       * onBlur is ensuring the provided value is a fully keyed date and that it conforms to typical date range rules
       */}
      <div className="col-12 col-md-3 mt-3 d-flex">
        <div className="mr-4">
          <label htmlFor="fromDate">{t('reporting:filters.from')}</label>
          <DatePicker
            id="fromDate"
            name="fromDate"
            onBlur={(): void => {
              const momentObj = moment(reportFilters.fromDate).startOf('day');
              if (momentObj.isValid() && momentObj.year().toString().length === 4) {
                if (momentObj.toDate() > reportFilters.toDate) {
                  if (reportFilters.toDate >= reportFilters.defaultFromDate) {
                    resetFromDatePicker();
                  } else {
                    resetAllDatePickers();
                  }
                  showInfoNotification(
                    'Invalid From Date',
                    'The from date must come before the to date.',
                  );
                } else {
                  if (
                    [
                      ReportFilterConstants.FILTERINCREMENT_WEEK,
                      ReportFilterConstants.FILTERINCREMENT_MONTH,
                    ].includes(reportFilters.calendarType)
                  ) {
                    const fromDate = applyPartialDateRangeRules(
                      momentObj.toDate(),
                      reportFilters.calendarType,
                      true,
                    );
                    // Due to a conflict between react re-renders and how the onBlur is firing,
                    // a timeout is necessary to allow the calendar to toggle correctly while
                    // also applying the partialDateRange logic
                    setTimeout(() => {
                      setReportFilters({
                        ...reportFilters,
                        fromDate: fromDate,
                      });
                    });
                  }
                }
              } else {
                resetFromDatePicker();
              }
            }}
            onChange={(event): void => {
              const newValue = event.target.value!;
              if (newValue) {
                setReportFilters({
                  ...reportFilters,
                  fromDate: applyPartialDateRangeRules(newValue, reportFilters.calendarType, true),
                });
              }
            }}
            value={applyPartialDateRangeRules(
              reportFilters.fromDate,
              reportFilters.calendarType,
              true,
            )}
          />
        </div>
        <div>
          <label htmlFor="toDate">{t('reporting:filters.to')}</label>
          <DatePicker
            id="toDate"
            name="toDate"
            onBlur={(): void => {
              const momentObj = moment(reportFilters.toDate).startOf('day');
              if (momentObj.isValid() && momentObj.year().toString().length === 4) {
                if (momentObj.toDate() < reportFilters.fromDate) {
                  if (reportFilters.fromDate <= reportFilters.defaultToDate) {
                    resetToDatePicker();
                  } else {
                    resetAllDatePickers();
                  }
                  showInfoNotification(
                    'Invalid To Date',
                    'The to date must come after the from date.',
                  );
                } else {
                  if (
                    [
                      ReportFilterConstants.FILTERINCREMENT_WEEK,
                      ReportFilterConstants.FILTERINCREMENT_MONTH,
                    ].includes(reportFilters.calendarType)
                  ) {
                    const toDate = applyPartialDateRangeRules(
                      momentObj.toDate(),
                      reportFilters.calendarType,
                      false,
                    );
                    // Due to a conflict between react re-renders and how the onBlur is firing,
                    // a timeout is necessary to allow the calendar to toggle correctly while
                    // also applying the partialDateRange logic
                    setTimeout(() => {
                      setReportFilters({
                        ...reportFilters,
                        toDate: toDate,
                      });
                    });
                  }
                }
              } else {
                resetToDatePicker();
              }
            }}
            onChange={(event): void => {
              const newValue = event.target.value!;
              if (newValue) {
                setReportFilters({
                  ...reportFilters,
                  toDate: applyPartialDateRangeRules(newValue, reportFilters.calendarType, false),
                });
              }
            }}
            value={applyPartialDateRangeRules(
              reportFilters.toDate,
              reportFilters.calendarType,
              false,
            )}
          />
        </div>
      </div>
    </div>
  );
};

export default ReportsPageFilters;
