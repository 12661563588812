import React from 'react';
import { NumericTextBox } from '@progress/kendo-react-inputs';
import { Field, FieldProps } from 'formik';
import _ from 'lodash';
import './Forms.scss';

interface Props {
  label: string;
  name: string;
  min?: number;
  max?: number;
  tabIndex?: number;
  validator?: (value: string) => string | void;
}

const WrappedNumericTextInput: React.FC<FieldProps & Props> = ({ field, form, label, ...rest }) => {
  const errors = _.get(form.errors, field.name);
  const hasErrors = !!errors;

  const classNames = ['formInput'];
  if (hasErrors && form.submitCount) {
    classNames.push('formInputError');
  }

  return (
    <>
      <label htmlFor={field.name}>{label}</label>
      <NumericTextBox className={classNames.join(' ')} {...field} {...rest} />
    </>
  );
};

const FormNumericTextInput: React.FC<Props> = props => {
  return (
    <div>
      <Field
        id={props.name}
        name={props.name}
        validate={props.validator}
        label={props.label}
        tabIndex={props.tabIndex}
        component={WrappedNumericTextInput}
        {...props}
      />
    </div>
  );
};

export default FormNumericTextInput;
