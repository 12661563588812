import React, { useState } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import BTButton from '../../../../common-page-components/controls/button/BTButton';
import { DropDownList, DropDownListChangeEvent } from '@progress/kendo-react-dropdowns';
import './EstimatorConfigurationModal.scss';

interface Props {
  onSaveClick: (environment: EstimatorHostedConfigurationEnvironment) => Promise<void>;
  onDiscardClick: () => void;
}

const EstimatorCopyConfigurationModal: React.FC<Props> = props => {
  // State
  const [selectedEnvironment, setSelectedEnvironment] = useState<
    EstimatorHostedConfigurationEnvironment
  >();

  const onEnvironmentDropdownChange = async (event: DropDownListChangeEvent): Promise<void> => {
    const environment = event.target.value as EstimatorHostedConfigurationEnvironment;
    setSelectedEnvironment(environment);
  };

  return (
    <Modal isOpen={true}>
      <ModalHeader className="justify-content-center">Copy Estimator Configuration</ModalHeader>
      <ModalBody>
        <div className="container">
          <label htmlFor="configEnvironmentDropdown">Select an environment</label>
          <DropDownList
            id="configEnvironmentDropdown"
            data={['Test', 'Production']}
            value={selectedEnvironment}
            onChange={onEnvironmentDropdownChange}
          />
          <div className="d-flex mt-4">
            <BTButton
              text="Save"
              onClick={async (): Promise<void> => await props.onSaveClick(selectedEnvironment!)}
              disabled={!selectedEnvironment}
            />
            <div className="ml-3">
              <BTButton text="Discard" color="gray" onClick={props.onDiscardClick} />
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default EstimatorCopyConfigurationModal;
