import React, { RefObject } from 'react';
import { GridCellProps } from '@progress/kendo-react-grid';
import { MultiSelect, MultiSelectChangeEvent } from '@progress/kendo-react-dropdowns';

interface Props extends GridCellProps {
  listAnchor: RefObject<HTMLElement>;
  required?: boolean;
  listValues: any[]; // eslint-disable-line @typescript-eslint/no-explicit-any
  dataItemKey?: string;
  valueField?: string;
  textField?: string;
}

const GridMultiSelectCell: React.FC<Props> = props => {
  const onChange = (event: MultiSelectChangeEvent): void => {
    if (props.onChange) {
      props.onChange({
        ...props,
        value: props.valueField
          ? event.target.value.map(v => v[props.valueField!])
          : event.target.value,
        syntheticEvent: event.syntheticEvent,
      });
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const value: any[] = props.field ? props.dataItem[props.field] : [];

  return (
    <td>
      <MultiSelect
        data={props.listValues}
        textField={props.textField}
        dataItemKey={props.dataItemKey}
        value={
          props.valueField
            ? props.listValues.filter(lv => value.includes(lv[props.valueField!]))
            : value
        }
        onChange={onChange}
        required={props.required}
        popupSettings={{ appendTo: props.listAnchor.current || undefined }}
      />
    </td>
  );
};

export default GridMultiSelectCell;
