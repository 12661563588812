import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Button } from 'reactstrap';
import './BTButton.scss';

interface Props {
  color?: 'normal' | 'gray';
  type?: 'submit' | 'button';
  align?: 'left' | 'center' | 'right';
  roundedCorners?: boolean;
  text: string;
  icon?: IconProp;
  disabled?: boolean;
  onClick?: () => void;
  tooltip?: string;
  tabIndex?: number;
  ref?: React.Ref<HTMLButtonElement>;
}

const BTButton: React.FC<Props> = (props, ref: React.Ref<HTMLButtonElement>) => {
  let buttonStyleCssClass = '';

  switch (props.color) {
    case 'gray':
      buttonStyleCssClass = 'grayButton';
      break;
    default:
      break;
  }

  return (
    <Button
      className={['btButton shadow-sm', buttonStyleCssClass].join(' ')}
      innerRef={ref}
      onClick={props.onClick}
      disabled={props.disabled}
      title={props.tooltip}
      type={props.type || 'button'}
      tabIndex={props.tabIndex}
      style={{
        textAlign: props.align || 'center',
        borderRadius: props.roundedCorners ? '40px' : '0',
      }}
    >
      {props.icon ? <FontAwesomeIcon icon={props.icon} className="buttonIcon" /> : <></>}

      {props.text}
    </Button>
  );
};

export default React.forwardRef(BTButton);
