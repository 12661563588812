import { isEqual } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Collapse } from 'reactstrap';
import ApplicationRoutes from '../../../../constants/routes';
import { ProductApi } from '../../../../services/api/';
import BTCardsContainer from '../../../common-page-components/cards-container/BTCardsContainer';
import { ConfirmationOverlayContext } from '../../../common-page-components/confirmation-overlay/ConfirmationOverlayContext';
import { NotificationPanelContext } from '../../../common-page-components/notification-panel/NotificationPanelContext';
import BTFloatingIconButton from './../../../common-page-components/controls/floating-icon-button/BTFloatingIconButton';
import { LoadingPageOverlayContext } from './../../../common-page-components/loading-page-overlay/LoadingPageOverlayContext';
import ProductEditForm from './components/ProductEditForm';
import ProductListing from './components/ProductListing';

const getNewProduct = (deploymentType: DeploymentType): Product => ({
  id: '',
  name: '',
  isNew: true,
  hasActiveLicenses: false,
  features: [],
  deploymentTypeId: deploymentType.id,
});

const ProductsPage: React.FC = () => {
  const { showPageLoadingOverlay, hidePageLoadingOverlay } = useContext(LoadingPageOverlayContext);

  const { showConfirmationOverlay, hideConfirmationOverlay } = useContext(
    ConfirmationOverlayContext,
  );

  const { showSuccessNotification, showInfoNotification, showErrorNotification } = useContext(
    NotificationPanelContext,
  );

  const history = useHistory();

  const [isPageDataLoaded, setIsPageDataLoaded] = useState<boolean>(false);
  const [products, setProducts] = useState<Product[]>([]);
  const [deploymentTypes, setDeploymentTypes] = useState<DeploymentType[]>([]);
  const [isEditAreaOpen, setIsEditAreaOpen] = useState<boolean>(false);
  const [productToEdit, setProductToEdit] = useState<Product | null>();

  // Initial load of data
  useEffect(() => {
    const loadProducts = async (): Promise<void> => {
      try {
        showPageLoadingOverlay();

        const [productsData, deploymentsData] = await Promise.all([
          await ProductApi.getProducts(),
          await ProductApi.getDeploymentTypes(),
        ]);

        setProducts(productsData);
        setDeploymentTypes(deploymentsData);

        setIsPageDataLoaded(true);
      } catch (error) {
        // In this case, we should send the user to an error page since this page isn't very useful without this core data
        showErrorNotification('Error', 'An error occurred while loading the page.');
        history.push(ApplicationRoutes.ERROR_ERROR);
      } finally {
        hidePageLoadingOverlay();
      }
    };

    loadProducts();
  }, [hidePageLoadingOverlay, history, showPageLoadingOverlay, showErrorNotification]);

  const onProductAdd = (): void => {
    // NOTE: We will always have at least one deployment type
    setProductToEdit(getNewProduct(deploymentTypes[0]));
    setIsEditAreaOpen(true);
  };

  const onProductEdit = (product: Product): void => {
    // Set the deployment type if it doesn't exist
    if (!product.deploymentTypeId) {
      product.deploymentTypeId = deploymentTypes[0].id;
    }

    setProductToEdit(product);
    setIsEditAreaOpen(true);
  };

  const onProductDelete = (product: Product): void => {
    const deleteProduct = async (): Promise<void> => {
      showPageLoadingOverlay();

      try {
        await ProductApi.deleteProduct(product.id);

        const newProducts = products.filter(p => p.id !== product.id);
        setProducts(newProducts);

        showSuccessNotification('Success', 'The product was deleted successfully.');
      } catch {
        showErrorNotification('Error', 'An error occurred while attempting to delete the product.');
      } finally {
        hidePageLoadingOverlay();
      }
    };

    showConfirmationOverlay({
      title: 'Confirm',
      text: `Are you sure you want to delete the product "${product.name}"?`,
      buttons: [
        {
          text: 'Yes',
          onClick: async (): Promise<void> => {
            hideConfirmationOverlay();
            await deleteProduct();
          },
        },
        {
          text: 'No',
          onClick: (): void => hideConfirmationOverlay(),
          color: 'gray',
        },
      ],
    });
  };

  const onFormDiscard = (product: Product): void => {
    // Delay this slightly so the edit area has time to animate closed
    setTimeout(() => {
      setProductToEdit(null);
    }, 250);

    setIsEditAreaOpen(false);
  };

  const onFormSave = async (product: Product): Promise<void> => {
    showPageLoadingOverlay();

    const closeEditAreaAndClearOutEditModel = (): void => {
      setIsEditAreaOpen(false);

      // Delay this slightly so the edit area has time to animate closed
      setTimeout(() => {
        setProductToEdit(null);
      }, 250);
    };

    try {
      if (product.isNew) {
        const newProduct: Product = await ProductApi.addProduct(product);
        setProducts([...products, newProduct]);

        closeEditAreaAndClearOutEditModel();
      } else {
        if (isEqual(product, productToEdit)) {
          closeEditAreaAndClearOutEditModel();

          showInfoNotification('No Changes', 'No changes were made to the product.');
          return;
        }

        const updatedProduct = await ProductApi.editProduct(product);
        const productsClone = [...products];

        const index = productsClone.findIndex(product => product.id === updatedProduct.id);

        if (index === -1) {
          throw new Error(`Could not find product with ID '${updatedProduct.id}'.`);
        }

        // Update product with new ID
        if (updatedProduct.newId && updatedProduct.newId !== updatedProduct.id) {
          updatedProduct.id = updatedProduct.newId;
          delete updatedProduct.newId;
        }

        productsClone[index] = updatedProduct;

        setProducts(productsClone);
      }

      setIsEditAreaOpen(false);

      // Delay this slightly so the edit area has time to animate closed
      setTimeout(() => {
        setProductToEdit(null);
      }, 250);

      showSuccessNotification(
        'Success',
        `The product was ${product.isNew ? 'created' : 'updated'} successfully.`,
      );
    } catch {
      showErrorNotification(
        'Error',
        `An error occurred while attempting to ${product.isNew ? 'create' : 'update'} the product.`,
      );
    } finally {
      hidePageLoadingOverlay();
    }
  };

  const renderProducts = (): JSX.Element => {
    const productListings = products.map(product => (
      <ProductListing
        key={product.name}
        product={product}
        disableButtons={isEditAreaOpen}
        onProductEdit={onProductEdit}
        onProductDelete={onProductDelete}
      />
    ));

    return (
      <div>
        <BTCardsContainer enableSearch recordsNotFoundMessage="No products were found.">
          {productListings}
        </BTCardsContainer>
      </div>
    );
  };

  if (!isPageDataLoaded) {
    return <></>;
  }

  return (
    <>
      <div className="container">
        <h1>Products</h1>
      </div>
      <div className="adminPageEditArea">
        <Collapse isOpen={isEditAreaOpen}>
          <div className="container-fluid grayBackground">
            <div className="container">
              {productToEdit && (
                <ProductEditForm
                  product={productToEdit}
                  existingProducts={products}
                  onDiscardClick={onFormDiscard}
                  onSaveClick={onFormSave}
                  deploymentTypes={deploymentTypes}
                />
              )}
            </div>
          </div>
        </Collapse>
      </div>

      <div className="container">{renderProducts()}</div>

      <BTFloatingIconButton
        buttonIcon="plus"
        onClick={onProductAdd}
        tooltip="Add new product"
        disabled={isEditAreaOpen}
      />
    </>
  );
};

export default ProductsPage;
