import React, { useMemo } from 'react';
import { Route, Switch } from 'react-router';
import ApplicationRoutes from '../../../../constants/routes';
import LicenseUsageChart from './../charts/LicenseUsage/LicenseUsageChart';
import LicenseCheckoutsChart from './../charts/LicenseCheckouts/LicenseCheckoutsChart';
import LicenseDurationChart from './../charts/LicenseDuration/LicenseDurationChart';

const ReportsPageCustomChart: React.FC<ReportChartProps> = props => {
  // NOTE: This is required so that the chart doesn't re-render with filter changes
  return useMemo(
    () => (
      <Switch>
        <Route
          exact
          path={ApplicationRoutes.REPORTS_USAGE}
          render={(): JSX.Element => <LicenseUsageChart data={props.data} />}
        />
        <Route
          exact
          path={ApplicationRoutes.REPORTS_CHECKOUTS}
          render={(): JSX.Element => <LicenseCheckoutsChart data={props.data} />}
        />
        <Route
          exact
          path={ApplicationRoutes.REPORTS_DURATION}
          render={(): JSX.Element => <LicenseDurationChart data={props.data} />}
        />
      </Switch>
    ),
    [props.data],
  );
};

export default ReportsPageCustomChart;
