import React, { useContext } from 'react';
import BTCard from '../../../../common-page-components/card/BTCard';
import { UserInformationContext } from '../../../../UserInformationContext';
import './UserListing.scss';

interface Props {
  editUserButtonClick: (user: User) => void;
  deleteUserButtonClick: (user: User) => void;
  isEditAreaOpen: boolean;
  user: User;
}

const UserListing: React.FC<Props> = ({
  user,
  isEditAreaOpen,
  editUserButtonClick,
  deleteUserButtonClick,
}) => {
  const { userInformation } = useContext(UserInformationContext);

  const isUserCurrentUser = (): boolean => {
    return userInformation !== null && userInformation.id === user.id;
  };

  const getTooltipForEditButton = (): string => {
    if (isUserCurrentUser()) {
      return 'You cannot edit your own user';
    } else if (isEditAreaOpen) {
      return '';
    }
    return 'Edit';
  };

  const getTooltipForDeleteButton = (): string => {
    if (isUserCurrentUser()) {
      return 'You cannot delete your own user';
    } else if (isEditAreaOpen) {
      return '';
    }
    return 'Delete';
  };

  return (
    <BTCard
      cardObject={user}
      sortValue={user.emailAddress || ''}
      title={user.emailAddress || ''}
      key={user.id || 'newUserId'}
      metadata={[user.emailAddress, (user.roles || []).map(v => v.name).join(' ')]}
      cardIcons={[
        {
          buttonIcon: 'edit',
          onClick: (): void => {
            editUserButtonClick(user);
          },
          tooltip: getTooltipForEditButton(),
          disabled: isUserCurrentUser() || isEditAreaOpen,
          'data-testid': `user-edit`,
        },
        {
          buttonIcon: 'trash',
          onClick: (): void => {
            deleteUserButtonClick(user);
          },
          tooltip: getTooltipForDeleteButton(),
          disabled: isUserCurrentUser() || isEditAreaOpen,
          'data-testid': `user-delete`,
        },
      ]}
    >
      <div className="userDetails">
        <div>
          <label className="cardLabel">User Roles</label>

          {!user.roles || user.roles.length === 0 ? (
            <div>(No Roles)</div>
          ) : (
            <div className="rolesListings">
              {user.roles.map(currRole => (
                <div key={currRole.id}>{currRole.name}</div>
              ))}

              {!user.roles && <div>(No Roles)</div>}
            </div>
          )}
        </div>
      </div>
    </BTCard>
  );
};

export default UserListing;
