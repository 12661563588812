import _ from 'lodash';

export interface LicenseUsageByPeriodDto {
  clientOrganizationId: string;
  productId: string;
  month: string;
  week: string;
  day: string;
  totalAutomaticCheckouts: number;
  totalManualCheckouts: number;
  distinctAutomaticCheckouts: number;
  distinctManualCheckouts: number;
  avgManualDuration: TimeSpan;
  avgAutomaticDuration: TimeSpan;
}

export const getDailyBTChartDataFromDto = (
  dtos: LicenseUsageByPeriodDto[],
  typeOfUsage: 'distinct' | 'total' | 'duration',
): BTChartData => {
  const data: BTChartData = {
    chartSeries: [
      {
        name: 'Automatic',
        data: dtos.map((dto: LicenseUsageByPeriodDto) => {
          return {
            label: new Date(dto.day),
            value: getPropertyByUsageType(dto, typeOfUsage, false),
          };
        }),
      },
      {
        name: 'Manual',
        data: dtos.map((dto: LicenseUsageByPeriodDto) => {
          return {
            label: new Date(dto.day),
            value: getPropertyByUsageType(dto, typeOfUsage, true),
          };
        }),
      },
    ],
  };
  return data;
};

export const getWeeklyBTChartDataFromDto = (
  dtos: LicenseUsageByPeriodDto[],
  typeOfUsage: 'distinct' | 'total' | 'duration',
): BTChartData => {
  const data: BTChartData = {
    chartSeries: [
      {
        name: 'Automatic',
        data: dtos.map((dto: LicenseUsageByPeriodDto) => {
          return {
            label: new Date(dto.week),
            value: getPropertyByUsageType(dto, typeOfUsage, false),
          };
        }),
      },
      {
        name: 'Manual',
        data: dtos.map((dto: LicenseUsageByPeriodDto) => {
          return {
            label: new Date(dto.week),
            value: getPropertyByUsageType(dto, typeOfUsage, true),
          };
        }),
      },
    ],
  };
  return data;
};

export const getMonthlyBTChartDataFromDto = (
  dtos: LicenseUsageByPeriodDto[],
  typeOfUsage: 'distinct' | 'total' | 'duration',
): BTChartData => {
  const data: BTChartData = {
    chartSeries: [
      {
        name: 'Automatic',
        data: dtos.map((dto: LicenseUsageByPeriodDto) => {
          return {
            label: new Date(dto.month),
            value: getPropertyByUsageType(dto, typeOfUsage, false),
          };
        }),
      },
      {
        name: 'Manual',
        data: dtos.map((dto: LicenseUsageByPeriodDto) => {
          return {
            label: new Date(dto.month),
            value: getPropertyByUsageType(dto, typeOfUsage, true),
          };
        }),
      },
    ],
  };
  return data;
};

function getPropertyByUsageType(
  dto: LicenseUsageByPeriodDto,
  usage: string,
  isManual: boolean,
): number {
  switch (usage) {
    case 'distinct': {
      return isManual ? dto.distinctManualCheckouts : dto.distinctAutomaticCheckouts;
    }
    case 'total': {
      return isManual ? dto.totalManualCheckouts : dto.totalAutomaticCheckouts;
    }
    case 'duration': {
      if (isManual) {
        return dto.avgManualDuration ? _.round(dto.avgManualDuration.value.totalHours, 2) : 0;
      } else {
        return dto.avgAutomaticDuration ? _.round(dto.avgAutomaticDuration.value.totalHours, 2) : 0;
      }
    }
    default: {
      return 0;
    }
  }
}
