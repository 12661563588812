import { Form, Formik, FormikProps, connect, FieldArrayRenderProps, FieldArray } from 'formik';
import React from 'react';
import * as Yup from 'yup';
import { getFormErrorMessages } from '../../../../../utils/forms';
import BTButton from '../../../../common-page-components/controls/button/BTButton';
import FormErrorContainer from '../../../../common-page-components/forms/form-error-container/FormErrorContainer';
import FormFooter from '../../../../common-page-components/forms/FormFooter';
import FormTextInput from '../../../../common-page-components/forms/FormTextInput';
import FormDropDownList, {
  FormDropDownListOption,
} from './../../../../common-page-components/forms/FormDropDownList';
import FormSwitch from '../../../../common-page-components/forms/FormSwitch';
import BTIconButton from '../../../../common-page-components/controls/icon-button/BTIconButton';
import './ClientEditForm.scss';
import ForbiddenDomains from '../../../../../constants/domains';

Yup.addMethod(Yup.array, 'unique', function(message) {
  return this.test('unique', message, function(value) {
    if (!value) {
      return true;
    }

    this.parent.domains.forEach((domain: string, idx: number) => {
      if (domain !== undefined) {
        const isDuplicate = this.parent.domains.find(
          (d: string, i: number) =>
            d && idx !== i && d.trim().toLowerCase() === domain.trim().toLowerCase(),
        );

        if (isDuplicate) {
          throw this.createError({
            path: `${this.path}[${idx}]`,
            message,
          });
        }
      }
    });
    return true;
  });
});

const FormSchema = Yup.object().shape<ClientOrganization>({
  id: Yup.string(),
  name: Yup.string()
    .trim()
    .required('Name is required'),
  address: Yup.object().shape<Address>({
    streetAddress: Yup.string().trim(),
    aptSuiteBldg: Yup.string().trim(),
    city: Yup.string().trim(),
    stateOrRegion: Yup.object().shape<ClientRegion>({
      id: Yup.number().nullable(),
      name: Yup.string(),
      countryId: Yup.number(),
    }),
    postalCode: Yup.string().trim(),
    country: Yup.object().shape<ClientCountry>({
      id: Yup.number().nullable(),
      name: Yup.string(),
    }),
  }),
  accountExecutive: Yup.string()
    .trim()
    .required('Account executive is required'),
  isNew: Yup.boolean(),
  domains: Yup.array()
    .of(Yup.string().trim())
    .unique('Client domain name must be unique', d => d)
    .required('Client domain names are required and cannot be empty'),
  usesCorporateActiveDirectory: Yup.boolean(),
});

interface Props {
  client: ClientOrganization;
  countries: ClientCountry[];
  regions: ClientRegion[];
  licenses: License[];
  existingClientNames: string[];
  existingClientDomains: string[];
  onSaveClick: (client: ClientOrganization) => void;
  onDiscardClick: (client: ClientOrganization) => void;
}

const ClientEditForm: React.FC<Props> = props => {
  const DEFAULT_COUNTRY_ID = 0;
  const currentDate = new Date();
  const domainsInUseByLicenseAdmin = props.client.domains.filter(domain => {
    if (
      props.licenses.find(
        license =>
          license.expirationDate > currentDate &&
          license.adminUser &&
          license.adminUser.indexOf(domain) > 0,
      )
    ) {
      return true;
    }
    return false;
  });

  const getCountriesForDropdown = (): FormDropDownListOption[] => {
    return props.countries.map(c => {
      return {
        name: c.name,
        value: c.id,
      };
    });
  };

  const getRegionsForDropdown = (values: ClientOrganization): FormDropDownListOption[] => {
    let options: ClientRegion[] = [];
    const defaultOptions = (options = props.regions.filter(
      r => r.countryId === DEFAULT_COUNTRY_ID,
    ));

    if (values.address) {
      if (values.address.country && values.address.country.id) {
        //If we have a valid country, display regions based on the countryId
        options = props.regions.filter(r => r.countryId === values.address!.country!.id);
      } else if (
        (!values.address.country || values.address.country.id === null) &&
        (values.address.stateOrRegion && values.address.stateOrRegion.id !== null)
      ) {
        //If we don't have a valid country, but we have a valid stateOrRegion, display regions based on selected stateOrRegion's countryId
        const region = props.regions.find(r => r.id === values!.address!.stateOrRegion!.id);
        if (region && region.countryId) {
          options = props.regions.filter(r => r.countryId === region.countryId);
        } else {
          options = defaultOptions;
        }
      } else {
        //Else, display a list of regions based on the DEFAULT_COUNTRY_ID
        options = defaultOptions;
      }
    }

    return options.map(r => {
      return {
        name: r.name,
        value: r.id,
      };
    });
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const FormikEffect = connect((props: any) => {
    props.onFormChanged(props.formik);
    return null;
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onFormChanged = (form: FormikProps<any>): void => {
    if (form && form.values) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const formValues = form.values as any;
      //If both a country and a region are selected
      if (
        formValues.address &&
        formValues.address.country &&
        formValues.address.stateOrRegion &&
        formValues.address.country.id &&
        formValues.address.stateOrRegion.id
      ) {
        const country = props.countries.find(c => c.id === formValues.address.country.id);
        const region = props.regions.find(r => r.id === formValues.address.stateOrRegion.id);
        if (country && region && country.id !== region.countryId) {
          form.setFieldValue('address.stateOrRegion.id', null);
        }
      }
    }
  };

  const nameValidator = (name: string): string | void => {
    if (props.client.name === name) {
      return;
    }

    if (
      props.existingClientNames
        .map(name => name.toLowerCase().trim())
        .includes(name.toLowerCase().trim())
    ) {
      return 'Client name must be unique';
    }
  };

  const domainValidator = (domain: string): string | void => {
    const domainRegex = /.+\..+$/;

    //Check each domain to be a valid domain address and for uniqueness with existing client domains
    if (!domainRegex.test(domain)) {
      return 'Client domain must be a valid domain address (ex. "abc.com")';
    }
    const cleanedUpDomain = domain.toLowerCase().trim();

    if (
      props.existingClientDomains
        .map(domain => domain.toLowerCase().trim())
        .includes(cleanedUpDomain)
    ) {
      return 'Client domain name must be unique';
    }

    if (ForbiddenDomains.includes(cleanedUpDomain)) {
      return `Client domain name cannot be a common domain such as ${cleanedUpDomain}.`;
    }
  };

  return (
    <div className="editForm">
      <h2>{`${props.client && props.client.isNew ? 'Create' : 'Edit'} Client`}</h2>

      <Formik
        initialValues={props.client}
        onSubmit={props.onSaveClick}
        validationSchema={FormSchema}
      >
        {({ errors, values, submitCount }): JSX.Element => (
          <>
            <FormikEffect onFormChanged={onFormChanged} />
            <Form>
              {
                <>
                  <div className="row">
                    <div className="col-12 col-lg-6 col-xl-4">
                      <FormTextInput label="Name" name="name" validator={nameValidator} />
                    </div>

                    <div className="col-12 col-lg-6 col-xl-4">
                      <FormTextInput label="Account Executive" name="accountExecutive" />
                    </div>

                    <div className="col-12 col-lg-6 col-xl-4">
                      <FormDropDownList
                        data={getCountriesForDropdown()}
                        label="Country"
                        name="address.country.id"
                        placeholderText="Select a country"
                      />
                    </div>

                    <div className="col-12 col-lg-6 col-xl-4">
                      <FormTextInput label="Street Address" name="address.streetAddress" />
                    </div>

                    <div className="col-12 col-lg-6 col-xl-4">
                      <FormTextInput label="Apt/Suite/Bldg" name="address.aptSuiteBldg" />
                    </div>

                    <div className="col-12 col-lg-6 col-xl-4">
                      <FormTextInput label="City" name="address.city" />
                    </div>

                    <div className="col-12 col-lg-6 col-xl-4">
                      <FormDropDownList
                        data={getRegionsForDropdown(values)}
                        label="State/Region"
                        name="address.stateOrRegion.id"
                        placeholderText="Select a region"
                      />
                    </div>

                    <div className="col-12 col-lg-6 col-xl-4">
                      <FormTextInput label="Postal Code" name="address.postalCode" />
                    </div>
                  </div>

                  <FieldArray
                    name="domains"
                    render={(arrayHelpers: FieldArrayRenderProps): JSX.Element => (
                      <div>
                        <div className="row">
                          <div className="col-12 col-md-6 domainHeader">
                            <label>Domains</label>
                            <div className="addDomain">
                              <div className="addDomain">
                                <BTIconButton
                                  icon="plus"
                                  tooltip="Add domain"
                                  data-testid="add-domain-data-testid"
                                  onClick={(): void => {
                                    arrayHelpers.push('');
                                  }}
                                  tabIndex={-1}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row domainList">
                          {!values.domains || !values.domains.length ? (
                            <div className="col noDomainMessage">(No Domain)</div>
                          ) : (
                            <></>
                          )}

                          {values.domains.map((domain, idx) => {
                            return (
                              <div key={idx} className="domain col-12">
                                <FormTextInput
                                  label=""
                                  name={`domains[${idx}]`}
                                  data-testid={`domains-${idx}`}
                                  tabIndex={3}
                                  validator={domainValidator}
                                  tooltip={
                                    domainsInUseByLicenseAdmin.includes(domain)
                                      ? 'Unable to change domain tied to an administrator of a current license'
                                      : ''
                                  }
                                  disabled={domainsInUseByLicenseAdmin.includes(domain)}
                                />
                                <div className="deleteDomain">
                                  <BTIconButton
                                    icon="trash"
                                    tooltip={
                                      domainsInUseByLicenseAdmin.includes(domain)
                                        ? 'Unable to delete domain tied to an administrator of a current license'
                                        : 'Delete'
                                    }
                                    onClick={(): void => {
                                      arrayHelpers.remove(idx);
                                      return;
                                    }}
                                    tabIndex={-1}
                                    disabled={domainsInUseByLicenseAdmin.includes(domain)}
                                    data-testid={`domains-${idx}-delete-data-testid`}
                                  />
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}
                  />
                  <div className="row">
                    <div className="col-12 col-lg-6 col-xl-12">
                      <FormSwitch
                        label="Uses Corporate Active Directory"
                        name="usesCorporateActiveDirectory"
                      />
                    </div>
                  </div>
                </>
              }
              <FormFooter>
                <div className="buttonsArea">
                  <BTButton text="Save" type="submit" />
                  <BTButton
                    text="Discard"
                    color="gray"
                    onClick={(): void => {
                      props.onDiscardClick(values);
                    }}
                  />
                </div>
                {submitCount > 0 && (
                  <FormErrorContainer errorMessages={getFormErrorMessages(errors)} />
                )}
              </FormFooter>
            </Form>
          </>
        )}
      </Formik>
    </div>
  );
};

export default ClientEditForm;
