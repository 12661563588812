import React from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import BTButton from '../../../../common-page-components/controls/button/BTButton';

export interface TemporaryPasswordInfo {
  name: string;
  email: string;
  password: string;
}

interface Props {
  passwordInfo: TemporaryPasswordInfo[];
  onCopyPasswordClick: () => Promise<void>;
}

const TemporaryPasswordModal: React.FC<Props> = props => {
  return (
    <Modal isOpen={true} size="lg">
      <ModalHeader className="justify-content-center">Temporary Password Info</ModalHeader>
      <ModalBody>
        <div>
          {props.passwordInfo.map(info => (
            <div key={info.email} className="mt-4">
              <div>
                <span className="bold">Name:</span> {info.name}
              </div>
              <div>
                <span className="bold">Email:</span> {info.email}
              </div>
              <div>
                <span className="bold">Password:</span> {info.password}
              </div>
            </div>
          ))}

          <div className="mt-3">
            <BTButton text="Copy and Close" onClick={props.onCopyPasswordClick} />
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default TemporaryPasswordModal;
