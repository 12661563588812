export const Countries: ClientCountry[] = [
  {
    id: 0,
    name: 'United States',
  },
  {
    id: 1,
    name: 'Canada',
  },
];

export const Regions: ClientRegion[] = [
  {
    id: 0,
    name: 'Alabama',
    countryId: 0,
  },
  {
    id: 1,
    name: 'Alaska',
    countryId: 0,
  },
  {
    id: 2,
    name: 'Arizona',
    countryId: 0,
  },
  {
    id: 3,
    name: 'Arkansas',
    countryId: 0,
  },
  {
    id: 4,
    name: 'California',
    countryId: 0,
  },
  {
    id: 5,
    name: 'Colorado',
    countryId: 0,
  },
  {
    id: 6,
    name: 'Connecticut',
    countryId: 0,
  },
  {
    id: 7,
    name: 'Delaware',
    countryId: 0,
  },
  {
    id: 8,
    name: 'District of Columbia',
    countryId: 0,
  },
  {
    id: 9,
    name: 'Florida',
    countryId: 0,
  },
  {
    id: 10,
    name: 'Georgia',
    countryId: 0,
  },
  {
    id: 11,
    name: 'Hawaii',
    countryId: 0,
  },
  {
    id: 12,
    name: 'Idaho',
    countryId: 0,
  },
  {
    id: 13,
    name: 'Illinois',
    countryId: 0,
  },
  {
    id: 14,
    name: 'Indiana',
    countryId: 0,
  },
  {
    id: 15,
    name: 'Iowa',
    countryId: 0,
  },
  {
    id: 16,
    name: 'Kansas',
    countryId: 0,
  },
  {
    id: 17,
    name: 'Kentucky',
    countryId: 0,
  },
  {
    id: 18,
    name: 'Louisiana',
    countryId: 0,
  },
  {
    id: 19,
    name: 'Maine',
    countryId: 0,
  },
  {
    id: 20,
    name: 'Maryland',
    countryId: 0,
  },
  {
    id: 21,
    name: 'Massachusetts',
    countryId: 0,
  },
  {
    id: 22,
    name: 'Michigan',
    countryId: 0,
  },
  {
    id: 23,
    name: 'Minnesota',
    countryId: 0,
  },
  {
    id: 24,
    name: 'Mississippi',
    countryId: 0,
  },
  {
    id: 25,
    name: 'Missouri',
    countryId: 0,
  },
  {
    id: 26,
    name: 'Montana',
    countryId: 0,
  },
  {
    id: 27,
    name: 'Nebraska',
    countryId: 0,
  },
  {
    id: 28,
    name: 'Nevada',
    countryId: 0,
  },
  {
    id: 29,
    name: 'New Hampshire',
    countryId: 0,
  },
  {
    id: 30,
    name: 'New Jersey',
    countryId: 0,
  },
  {
    id: 31,
    name: 'New Mexico',
    countryId: 0,
  },
  {
    id: 32,
    name: 'New York',
    countryId: 0,
  },
  {
    id: 33,
    name: 'North Carolina',
    countryId: 0,
  },
  {
    id: 34,
    name: 'North Dakota',
    countryId: 0,
  },
  {
    id: 35,
    name: 'Ohio',
    countryId: 0,
  },
  {
    id: 36,
    name: 'Oklahoma',
    countryId: 0,
  },
  {
    id: 37,
    name: 'Oregon',
    countryId: 0,
  },
  {
    id: 38,
    name: 'Pennsylvania',
    countryId: 0,
  },
  {
    id: 39,
    name: 'Puerto Rico',
    countryId: 0,
  },
  {
    id: 40,
    name: 'Rhode Island',
    countryId: 0,
  },
  {
    id: 41,
    name: 'South Carolina',
    countryId: 0,
  },
  {
    id: 42,
    name: 'South Dakota',
    countryId: 0,
  },
  {
    id: 43,
    name: 'Tennessee',
    countryId: 0,
  },
  {
    id: 44,
    name: 'Texas',
    countryId: 0,
  },
  {
    id: 45,
    name: 'Utah',
    countryId: 0,
  },
  {
    id: 46,
    name: 'Vermont',
    countryId: 0,
  },
  {
    id: 47,
    name: 'Virginia',
    countryId: 0,
  },
  {
    id: 48,
    name: 'Washington',
    countryId: 0,
  },
  {
    id: 49,
    name: 'West Virginia',
    countryId: 0,
  },
  {
    id: 50,
    name: 'Wisconsin',
    countryId: 0,
  },
  {
    id: 51,
    name: 'Wyoming',
    countryId: 0,
  },
  {
    id: 52,
    name: 'Alberta',
    countryId: 1,
  },
  {
    id: 53,
    name: 'British Columbia',
    countryId: 1,
  },
  {
    id: 54,
    name: 'Manitoba',
    countryId: 1,
  },
  {
    id: 55,
    name: 'New Brunswick',
    countryId: 1,
  },
  {
    id: 56,
    name: 'Newfoundland and Labrador',
    countryId: 1,
  },
  {
    id: 57,
    name: 'Northwest Territories',
    countryId: 1,
  },
  {
    id: 58,
    name: 'Nova Scotia',
    countryId: 1,
  },
  {
    id: 59,
    name: 'Nunavut',
    countryId: 1,
  },
  {
    id: 60,
    name: 'Ontario',
    countryId: 1,
  },
  {
    id: 61,
    name: 'Prince Edward Island',
    countryId: 1,
  },
  {
    id: 62,
    name: 'Quebec',
    countryId: 1,
  },
  {
    id: 63,
    name: 'Saskatchewan',
    countryId: 1,
  },
  {
    id: 64,
    name: 'Yukon',
    countryId: 1,
  },
];
