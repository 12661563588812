const REPORT_USAGE = 'Usage';
const REPORT_CHECKOUTS = 'Checkouts';
const REPORT_DURATION = 'Duration';
const REPORT_RAWDATA = 'Raw Data';

export const ReportNames = {
  Usage: REPORT_USAGE,
  Checkouts: REPORT_CHECKOUTS,
  Duration: REPORT_DURATION,
  RawData: REPORT_RAWDATA,
};
