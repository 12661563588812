import { Form, Formik, FormikErrors, validateYupSchema, yupToFormErrors } from 'formik';
import { Values } from 'formsy-react/dist/interfaces';
import React from 'react';
import * as Yup from 'yup';
import BTButton from '../../../../common-page-components/controls/button/BTButton';
import FormErrorContainer from '../../../../common-page-components/forms/form-error-container/FormErrorContainer';
import FormDatePicker from '../../../../common-page-components/forms/FormDatePicker';
import FormDropDownList from '../../../../common-page-components/forms/FormDropDownList';
import FormFooter from '../../../../common-page-components/forms/FormFooter';
import FormMultiSelect from '../../../../common-page-components/forms/FormMultiSelect';
import FormNumericTextInput from '../../../../common-page-components/forms/FormNumericTextInput';
import FormSwitch from '../../../../common-page-components/forms/FormSwitch';
import { getFormErrorMessages } from '../../../../../utils/forms';
import FormTextInput from '../../../../common-page-components/forms/FormTextInput';

const FormSchema = Yup.object().shape<License>({
  id: Yup.string(),
  isNew: Yup.boolean(),
  clientOrganization: Yup.object().shape<LicenseClientOrganization>({
    id: Yup.string().required('The client is required'),
    name: Yup.string(),
    domains: Yup.array().of(Yup.string()),
  }),
  product: Yup.object().shape<LicenseProduct>({
    id: Yup.string().required('The product is required'),
    name: Yup.string(),
    features: Yup.array().of(
      Yup.object().shape<ProductFeature>({
        id: Yup.string(),
        name: Yup.string(),
        isNew: Yup.boolean(),
        hasActiveLicenses: Yup.boolean(),
      }),
    ),
    deploymentTypeId: Yup.string().nullable(),
  }),
  enforceStrictUserCount: Yup.boolean().required('The unlimited users setting is required'),
  userCount: Yup.number()
    .required()
    .typeError('The user count is required')
    .min(1, 'The user count must be at least 1')
    .max(1000, 'The user count cannot exceed 1000'),
  effectiveDate: Yup.date().required('The effective date is required'),
  expirationDate: Yup.date().required('The effective date is required'),
  deploymentDetails: Yup.object(),
  deploymentStatus: Yup.string().nullable(),
  adminUser: Yup.string()
    .trim()
    .email('The Administrator email address is not valid')
    .nullable(),
});

interface Props {
  license: License;
  availableClientOrganizations: ClientOrganization[];
  availableProducts: Product[];
  existingLicenses: License[];
  onSubmitClick: (license: License) => void;
  onDiscardClick: (license: License) => void;
}

const LicenseEditForm: React.FC<Props> = props => {
  const validateForm = (values: Values): FormikErrors<Values> => {
    const allEditModelValues = values as License;

    const validateWithYup = (): FormikErrors<Values> => {
      try {
        validateYupSchema(values, FormSchema, true, values);
      } catch (err) {
        return yupToFormErrors(err); //for rendering validation errors
      }
      return {};
    };

    const validateDates = (): FormikErrors<Values> => {
      const dateErrors: FormikErrors<Values> = {};
      // Validate that the dates are in order
      if (!(allEditModelValues.effectiveDate < allEditModelValues.expirationDate)) {
        dateErrors.effectiveDate = 'The effective date must come before the expiration date';
      }

      return dateErrors;
    };

    const validateClientOrganizationAndProduct = (): FormikErrors<Values> => {
      const clientOrganizationAndProductErrors: FormikErrors<Values> = {};
      // Validate that the selected client organization / product are unique amongst the other licenses on the page
      if (allEditModelValues.clientOrganization.id && allEditModelValues.product.id) {
        const clientOrganizationAndProductComboAlreadyExist = props.existingLicenses.find(
          v =>
            v.id !== allEditModelValues.id &&
            v.clientOrganization.id === allEditModelValues.clientOrganization.id &&
            v.product.id === allEditModelValues.product.id,
        );

        if (clientOrganizationAndProductComboAlreadyExist) {
          const errorMessage = 'The selected client organization / product is already in use';
          clientOrganizationAndProductErrors['clientOrganization.id'] = errorMessage;
          clientOrganizationAndProductErrors['product.id'] = errorMessage;
        }
      }

      return clientOrganizationAndProductErrors;
    };

    const validateAdministratorEmail = (
      previousErrors: FormikErrors<Values>,
    ): FormikErrors<Values> => {
      const errors: FormikErrors<Values> = {};

      if (
        productHasDeploymentType(allEditModelValues.product.id) &&
        !allEditModelValues.adminUser
      ) {
        errors.adminUser = 'Administrator is a required field';
      } else if (allEditModelValues.adminUser && !previousErrors.adminUser) {
        const administratorDomain = allEditModelValues.adminUser
          .trim()
          .toLowerCase()
          .split('@')[1];
        const selectedClientOrganization = props.availableClientOrganizations.find(
          x => x.id === allEditModelValues.clientOrganization.id,
        );
        if (
          selectedClientOrganization &&
          !selectedClientOrganization.domains.find(
            d => d.trim().toLowerCase() === administratorDomain,
          )
        ) {
          errors.adminUser = `The Administrator email address must be for one of the following domains: "${selectedClientOrganization.domains}".`;
        }
      }

      return errors;
    };

    const yupErrors = validateWithYup();

    return {
      ...yupErrors,
      ...validateDates(),
      ...validateClientOrganizationAndProduct(),
      ...validateAdministratorEmail(yupErrors),
    };
  };

  const getDataForFeatures = (selectedProductId: string | null): ProductFeature[] => {
    if (!selectedProductId) {
      return [];
    }

    const selectedProduct = props.availableProducts.find(v => v.id === selectedProductId);
    return selectedProduct ? selectedProduct.features : [];
  };

  const productHasDeploymentType = (productId: string): boolean => {
    const invalidDeploymentTypes = [null, 'None'];
    const product = props.availableProducts.find(p => p.id === productId);
    return !product ? false : !invalidDeploymentTypes.includes(product.deploymentTypeId);
  };

  const getAdminPlaceholder = (selectedClientOrganizationId: string): string => {
    const selectedClientOrganization = props.availableClientOrganizations.find(
      x => x.id === selectedClientOrganizationId,
    );
    return selectedClientOrganization ? `admin@${selectedClientOrganization.domains[0]}` : '';
  };

  // NOTE: With Formik, once we set the 'initialValues' property, updating it will not update the form values unless we set the 'enableReinitialize' flag
  // so we do not even render the Formik element unless the object we bind to its initial values is defined
  return (
    <div className="editForm">
      <h2>{`${props.license && props.license.isNew ? 'Create' : 'Edit'} License`}</h2>

      {props.license && (
        <Formik
          initialValues={props.license}
          onSubmit={props.onSubmitClick}
          validate={validateForm}
        >
          {({ errors, values, submitCount }): JSX.Element => (
            <Form>
              {
                <>
                  <div className="row">
                    <div className="col-12 col-md-6">
                      <FormDropDownList
                        data={props.availableClientOrganizations.map(client => ({
                          name: client.name,
                          value: client.id,
                        }))}
                        placeholderText="(Select a client)"
                        label="Client"
                        name="clientOrganization.id"
                        disabled={!props.license.isNew}
                      />
                    </div>

                    <div className="col-12 col-md-6">
                      <FormDropDownList
                        data={props.availableProducts.map(product => ({
                          name: product.name,
                          value: product.id,
                        }))}
                        placeholderText="(Select a product)"
                        label="Product"
                        name="product.id"
                        disabled={!props.license.isNew}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12">
                      <FormMultiSelect
                        data={getDataForFeatures(values.product.id)}
                        placeholderText="Features"
                        label="Features"
                        name="product.features"
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12 col-md-3">
                      <FormDatePicker label="Effective Date" name="effectiveDate" tabIndex={2} />
                    </div>

                    <div className="col-12 col-md-3">
                      <FormDatePicker label="Expiration Date" name="expirationDate" tabIndex={3} />
                    </div>

                    {productHasDeploymentType(values.product.id) && (
                      <div className="col-12 col-md-6">
                        <FormTextInput
                          label="Administrator"
                          name="adminUser"
                          placeholder={getAdminPlaceholder(values.clientOrganization.id)}
                        />
                      </div>
                    )}
                  </div>

                  <div className="row">
                    <div className="col-12 col-md-6">
                      <FormNumericTextInput
                        label="Number of Users"
                        name="userCount"
                        min={1}
                        max={1000}
                        tabIndex={4}
                      />
                    </div>

                    <div className="col-12 col-md-3">
                      <FormSwitch label="Enforce Strict User Count" name="enforceStrictUserCount" />
                    </div>
                  </div>
                </>
              }

              <FormFooter>
                <div className="buttonsArea">
                  <BTButton text="Save" type="submit" tabIndex={5} />
                  <BTButton
                    text="Discard"
                    color="gray"
                    onClick={(): void => {
                      props.onDiscardClick(values);
                    }}
                    tabIndex={6}
                  />
                </div>
                {submitCount > 0 && (
                  <FormErrorContainer errorMessages={getFormErrorMessages(errors)} />
                )}
              </FormFooter>
            </Form>
          )}
        </Formik>
      )}
    </div>
  );
};

export default LicenseEditForm;
