import { MutableRefObject } from 'react';
import * as Scroll from 'react-scroll';
import { getConfig } from './config';

const scrollToRef = (ref: MutableRefObject<HTMLElement | null>): void => {
  const currentRef = ref.current as HTMLElement;
  if (!currentRef) {
    return;
  }

  // Subtract 75 to account for the navbar
  const newScrollPosition = currentRef.offsetTop - 75;
  Scroll.animateScroll.scrollTo(newScrollPosition, { duration: 750 });
};

const isDevelopmentEnvironment = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';
const isProductionEnvironment = (getConfig('REACT_APP_ENVIRONMENT') || '').toLowerCase() === 'prod';

export { scrollToRef, isDevelopmentEnvironment, isProductionEnvironment };
