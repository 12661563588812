import { FieldArray, Form, Formik, FormikValues } from 'formik';
import { isEqual } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { getFormErrorMessages } from '../../../../../utils/forms';
import { isProductionEnvironment } from '../../../../../utils/general';
import { generateUuid, isValidUuid } from '../../../../../utils/uuid';
import Yup from '../../../../../utils/yup';
import { ConfirmationOverlayContext } from '../../../../common-page-components/confirmation-overlay/ConfirmationOverlayContext';
import BTButton from '../../../../common-page-components/controls/button/BTButton';
import BTIconButton from '../../../../common-page-components/controls/icon-button/BTIconButton';
import FormErrorContainer from '../../../../common-page-components/forms/form-error-container/FormErrorContainer';
import FormFooter from '../../../../common-page-components/forms/FormFooter';
import FormTextInput from '../../../../common-page-components/forms/FormTextInput';
import './ProductEditForm.scss';
import FormDropDownList from '../../../../common-page-components/forms/FormDropDownList';

const FormSchema = Yup.object().shape<Product>({
  id: Yup.string(),
  name: Yup.string()
    .trim()
    .required('Name is required'),
  isNew: Yup.boolean().required(),
  hasActiveLicenses: Yup.boolean().required(),
  deploymentTypeId: Yup.string().required('Deployment type is required'),
  newId: Yup.string(),
  features: Yup.array()
    .of(
      Yup.object().shape<ProductFeature>({
        id: Yup.string(),
        name: Yup.string()
          .trim()
          .required('Feature name is required'),
        isNew: Yup.boolean(),
        hasActiveLicenses: Yup.boolean().required(),
      }),
    )
    .unique('Feature IDs must be unique', f => f.newId && f.newId.toLowerCase()),
});

interface Props {
  product: Product;
  deploymentTypes: DeploymentType[];
  existingProducts: Product[];
  onSaveClick: (product: Product) => void;
  onDiscardClick: (product: Product) => void;
}

const ProductEditForm: React.FC<Props> = props => {
  const [productIds, setProductIds] = useState<string[]>([]);
  const [productNames, setProductNames] = useState<string[]>([]);

  const { showConfirmationOverlay, hideConfirmationOverlay } = useContext(
    ConfirmationOverlayContext,
  );

  const product = {
    ...props.product,
    newId: props.product.id,
    features: props.product.features.map(feat => ({ ...feat, newId: feat.id })),
  };

  // Keep list of lowercase IDs and Names so we don't have to transform every time
  useEffect(() => {
    const ids = props.existingProducts.map(product => product.id.toLowerCase().trim());
    const names = props.existingProducts.map(product => product.name.toLowerCase().trim());

    setProductIds(ids);
    setProductNames(names);
  }, [props.existingProducts]);

  const saveProduct = (values: FormikValues): void => {
    const features = values.features as ProductFeature[];

    const newFeatures = features.filter(feature => feature.isNew);

    const updatedFeatures = features
      .filter((feature, index) => !feature.isNew && product.features[index])
      .map((feature, index) => {
        const updatedFeature = values.features[index];
        return { ...feature, name: updatedFeature.name };
      });

    const newProduct: Product = {
      id: values.id,
      newId: values.newId,
      name: values.name,
      deploymentTypeId: values.deploymentTypeId,
      isNew: product.isNew,
      hasActiveLicenses: false,
      features: [...newFeatures, ...updatedFeatures],
    };

    props.onSaveClick(newProduct);
  };

  const submit = (values: FormikValues): void => {
    const newProduct = values as Product;

    const productIdWasUpdated = product.id !== values.newId;
    const featureIdWasUpdated = newProduct.features.some(
      (feature, index) => !feature.isNew && feature.newId !== product.features[index].id,
    );

    if (product.isNew || (!productIdWasUpdated && !featureIdWasUpdated)) {
      saveProduct(values);
      return;
    }

    let messageSection: string;

    if (productIdWasUpdated && featureIdWasUpdated) {
      messageSection = 'both product and feature IDs';
    } else if (productIdWasUpdated) {
      messageSection = 'the product ID';
    } else {
      messageSection = 'a feature ID';
    }

    showConfirmationOverlay({
      title: 'Confirm',
      text: `You have edited ${messageSection}. This could cause errors for anything mapped to the current ID. Are you sure you'd like to continue?`,
      buttons: [
        {
          text: 'Yes',
          onClick: async (): Promise<void> => {
            hideConfirmationOverlay();
            saveProduct(values);
          },
        },
        {
          text: 'No',
          onClick: (): void => hideConfirmationOverlay(),
          color: 'gray',
        },
      ],
    });
  };

  const onDiscardClick = (values: FormikValues): void => {
    const newProduct = values as Product;

    if (!isEqual(product, newProduct)) {
      showConfirmationOverlay({
        title: 'Confirm',
        text: 'Are you sure you want to discard your changes?',
        buttons: [
          {
            text: 'Yes',
            onClick: (): void => {
              hideConfirmationOverlay();
              props.onDiscardClick(newProduct);
            },
          },
          {
            text: 'No',
            onClick: (): void => hideConfirmationOverlay(),
            color: 'gray',
          },
        ],
      });
    } else {
      props.onDiscardClick(newProduct);
    }
  };

  const uuidValidator = (id: string): string | void => {
    if (!isValidUuid(id)) {
      return 'ID must be in the format "XXXXXXXX-XXXX-XXXX-XXXX-XXXXXXXXXXXX"';
    }
  };

  const productIdValidator = (id: string): string | void => {
    if (product.id === id) {
      return;
    }

    if (productIds.includes(id.toLowerCase().trim())) {
      return 'ID must be unique';
    }

    return uuidValidator(id);
  };

  const nameValidator = (name: string): string | void => {
    if (product.name === name) {
      return;
    }

    if (productNames.includes(name.toLowerCase().trim())) {
      return 'Name must be unique';
    }
  };

  const featureIdValidator = (id: string): string | void => {
    return uuidValidator(id);
  };

  return (
    <div className="editForm">
      <h2>{`${product && product.isNew ? 'Create' : 'Edit'} Product`}</h2>

      <Formik initialValues={product} onSubmit={submit} validationSchema={FormSchema}>
        {({ errors, values, submitCount, setFieldValue }): JSX.Element => (
          <Form>
            <div className="row">
              <div className="col-12 col-md-9">
                <FormTextInput label="Name" name="name" validator={nameValidator} tabIndex={2} />
              </div>

              <div className="col-12 col-md-3">
                <FormDropDownList
                  data={props.deploymentTypes.map(v => ({
                    name: v.name,
                    value: v.id,
                  }))}
                  label="Deployment Type"
                  name="deploymentTypeId"
                  disabled={product.hasActiveLicenses}
                  tooltip={
                    product.hasActiveLicenses
                      ? 'The deployment type cannot be updated because active licenses exist for this product'
                      : ''
                  }
                />
              </div>
            </div>

            {!product.isNew && (
              <div className="row">
                <div className="col-12 idFormArea">
                  <div className="flex-grow-1">
                    <FormTextInput
                      label="ID"
                      name="newId"
                      validator={productIdValidator}
                      tabIndex={1}
                      disabled={product.hasActiveLicenses || isProductionEnvironment}
                      data-testid="product-id"
                    />
                  </div>

                  <div className="undoId">
                    <BTIconButton
                      icon="undo"
                      tooltip="Undo changes to ID field"
                      onClick={(): void => setFieldValue('newId', product.id)}
                      tabIndex={-1}
                      data-testid="product-id-undo"
                    />
                  </div>
                </div>
              </div>
            )}

            <FieldArray
              name="features"
              render={(arrayHelpers): JSX.Element => (
                <div>
                  <div className="row">
                    <div className="col-12 col-md-6 featureHeader">
                      <label>Features</label>
                      <div className="addFeature">
                        <BTIconButton
                          icon="plus"
                          tooltip="Add feature"
                          data-testid="feature-add"
                          onClick={(): void => {
                            const id = generateUuid();

                            const feature: ProductFeature = {
                              id: id,
                              name: '',
                              isNew: true,
                              hasActiveLicenses: false,
                              newId: id,
                            };

                            arrayHelpers.push(feature);
                          }}
                          tabIndex={-1}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row featureList">
                    {!values.features || !values.features.length ? (
                      <div className="col noFeaturesMessage">(No Features)</div>
                    ) : (
                      <></>
                    )}

                    {values.features.map((feature, index) => {
                      // Must use original ID as the key since it is editable in the form
                      const key = !feature.isNew ? product.features[index].id : feature.id;

                      if (feature.isDeleted) {
                        return <div key={key} />;
                      }

                      const classNames = ['feature'];

                      // If it is an existing product, we will be showing the ID as well as the name so we want them side by side
                      if (product.isNew) {
                        classNames.push('col-12 col-md-6');
                      } else {
                        classNames.push('col-12');
                      }

                      return (
                        <div key={key} className={classNames.join(' ')}>
                          {!product.isNew && (
                            <div className="featureId">
                              <FormTextInput
                                label="ID"
                                name={`features[${index}].newId`}
                                disabled={feature.hasActiveLicenses || isProductionEnvironment}
                                tooltip={
                                  feature.hasActiveLicenses
                                    ? 'Cannot edit the ID of a feature with active licenses'
                                    : ''
                                }
                                validator={featureIdValidator}
                                tabIndex={3}
                                data-testid={`feature-${index}-id`}
                              />
                              <div className="undoId">
                                <BTIconButton
                                  icon="undo"
                                  tooltip={feature.isNew ? '' : 'Undo changes to ID field'}
                                  onClick={(): void => {
                                    arrayHelpers.replace(index, {
                                      ...feature,
                                      newId: product.features[index].newId,
                                    });
                                  }}
                                  disabled={feature.isNew}
                                  tabIndex={-1}
                                  data-testid={`feature-${index}-id-undo`}
                                />
                              </div>
                            </div>
                          )}
                          <FormTextInput
                            label="Name"
                            name={`features[${index}].name`}
                            data-testid={`feature-${index}-name`}
                            tabIndex={3}
                          />
                          <div className="deleteFeature">
                            <BTIconButton
                              icon="trash"
                              tooltip={
                                feature.hasActiveLicenses
                                  ? 'Cannot delete a feature with active licenses'
                                  : 'Delete'
                              }
                              disabled={feature.hasActiveLicenses}
                              onClick={(): void => {
                                if (feature.isNew) {
                                  arrayHelpers.remove(index);
                                  return;
                                }

                                const deletedFeature: ProductFeature = {
                                  ...feature,
                                  isDeleted: true,
                                };

                                arrayHelpers.replace(index, deletedFeature);
                              }}
                              tabIndex={-1}
                              data-testid={`feature-${index}-delete`}
                            />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            />

            <FormFooter>
              <div className="buttonsArea">
                <BTButton text="Save" type="submit" tabIndex={4} />
                <BTButton
                  text="Discard"
                  color="gray"
                  onClick={(): void => onDiscardClick(values)}
                  tabIndex={5}
                />
              </div>
              {submitCount > 0 && (
                <FormErrorContainer errorMessages={getFormErrorMessages(errors)} />
              )}
            </FormFooter>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ProductEditForm;
