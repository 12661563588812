import { IconProp } from '@fortawesome/fontawesome-svg-core';
import React, { ReactElement, useCallback, useContext, useEffect, useState } from 'react';
import { Card, CardBody, CardHeader } from 'reactstrap';
import { getEEClass } from '../../../utils/ee';
import { isDevelopmentEnvironment } from '../../../utils/general';
import { generateUuid } from '../../../utils/uuid';
import { BTCardsContainerContext } from '../cards-container/BTCardsContainer';
import BTIconButton from '../controls/icon-button/BTIconButton';
import './BTCard.scss';

export type BTCardIcons = {
  buttonIcon: IconProp;
  tooltip: string;
  onClick: () => void;
  disabled?: boolean;
  'data-testid'?: string;
};

export interface BTCardProps {
  cardObject: {}; // Holds a reference to an object so the card knows when to update things in relation to the cards container context
  title: string;
  cardIcons?: BTCardIcons[];
  metadata?: string[];
  sortValue: string;
  groupValue?: string;
  'data-testid'?: string;
}

const BTCard: React.FC<BTCardProps> = props => {
  const { registerCard, unregisterCard } = useContext(BTCardsContainerContext);

  // NOTE: The card ID must be unique for each component so we generate it inside the component
  const [cardId] = useState<string>(generateUuid());

  const renderCard = useCallback((): ReactElement => {
    return (
      <Card
        className={`btCard shadow-sm blue-line-top-hover ${
          isDevelopmentEnvironment ? getEEClass() : ''
        }
    `}
        key={props.title}
      >
        <CardHeader>
          <div className="cardTitle" data-testid={props['data-testid']}>
            {props.title}
          </div>
          <div className="cardButtons">
            {props.cardIcons && props.cardIcons.length ? (
              props.cardIcons.map((currCardIcon, i) => {
                return (
                  <BTIconButton
                    key={i}
                    tooltip={currCardIcon.tooltip}
                    icon={currCardIcon.buttonIcon}
                    onClick={currCardIcon.onClick}
                    disabled={currCardIcon.disabled}
                    data-testid={currCardIcon['data-testid']}
                  />
                );
              })
            ) : (
              <></>
            )}
          </div>
        </CardHeader>
        <CardBody>{props.children}</CardBody>
      </Card>
    );
  }, [props]);

  // Register / unregister the card when this component updates
  useEffect(() => {
    registerCard({
      cardId: cardId,
      metadata: props.metadata || [],
      sortValue: props.sortValue,
      groupValue: props.groupValue,
      cardElement: renderCard(),
    });

    // Make sure we unregister the card when this component gets updated or unmounted
    return (): void => {
      unregisterCard(cardId);
    };
    // When the props for this card changes, we want to unregister / reregister it with the new data, including a new render
  }, [props, registerCard, unregisterCard, renderCard, cardId]);

  // Render the card content, in case it isn't wrapped by a BTCardContainer that handles the displaying of the cards
  return renderCard();
};

export default BTCard;
