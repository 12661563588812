import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import ApplicationRoutes from '../../../../constants/routes';
import { UserInformationContext } from '../../../UserInformationContext';
import { getEEClass } from './../../../../utils/ee';
import { isDevelopmentEnvironment } from './../../../../utils/general';
import SvgImage, { SvgImages } from './../../../common-page-components/svg-image/SvgImage';
import './HomePage.scss';
import { getConfig } from '../../../../utils/config';
import LinksConstants from '../../../../constants/links';

interface HomePageNavigationCardsCollection {
  title: string;
  cards: HomePageNavigationCard[];
}

interface HomePageNavigationCard {
  title: string;
  url: string;
  svgImage?: SvgImages;
}

const HomePage: React.FC = () => {
  const { t } = useTranslation();

  const { userInformation } = useContext(UserInformationContext);
  const [allLinkCollections, setAllLinkCollections] = useState<HomePageNavigationCardsCollection[]>(
    [],
  );

  const websiteUrlCommunity =
    getConfig('REACT_APP_BECKTECH_COMMUNITY_URL') || LinksConstants.BeckTechCommunityWebsite;

  const getUrlForDisplay = (url: string): string => {
    return url.replace('http://', '');
  };

  useEffect(() => {
    const generateLinksCollections = (): HomePageNavigationCardsCollection[] => {
      const newLinks: HomePageNavigationCardsCollection[] = [];

      if (userInformation) {
        if (
          userInformation.userIsClientUser ||
          userInformation.userIsInternalUser ||
          userInformation.userIsAdmin
        ) {
          newLinks.push({
            title: t('reporting:reports'),
            cards: [
              {
                title: t('reporting:reportNames.usage'),
                url: ApplicationRoutes.REPORTS_USAGE,
                svgImage: 'tools',
              },

              {
                title: t('reporting:reportNames.checkouts'),
                url: ApplicationRoutes.REPORTS_CHECKOUTS,
                svgImage: 'basket',
              },

              {
                title: t('reporting:reportNames.duration'),
                url: ApplicationRoutes.REPORTS_DURATION,
                svgImage: 'clock',
              },

              {
                title: t('reporting:reportNames.rawData'),
                url: ApplicationRoutes.REPORTS_RAWDATA,
                svgImage: 'database',
              },
            ],
          });
        }

        if (userInformation.userIsAdmin) {
          newLinks.unshift({
            title: 'Admin',
            cards: [
              { title: 'Clients', url: ApplicationRoutes.ADMIN_CLIENTS, svgImage: 'users2' },
              {
                title: 'Licenses',
                url: ApplicationRoutes.ADMIN_LICENSES,
                svgImage: 'certificate',
              },
              { title: 'Products', url: ApplicationRoutes.ADMIN_PRODUCTS, svgImage: 'bulb' },
              // NOTE: The page this navigates to is the users page but for clarity as to what that page actually is, we use a slightly different label
              {
                title: 'Users',
                url: ApplicationRoutes.ADMIN_USERS,
                svgImage: 'key',
              },
            ],
          });
        }
      }
      return newLinks;
    };
    setAllLinkCollections(generateLinksCollections());
  }, [t, userInformation]);

  return (
    <div className="homePage" data-testid="home-page">
      <div className="container">
        {!allLinkCollections.length && (
          <div>
            <div className="verifiedUserMessageArea">
              <div className="verifiedCheckImage text-center">
                <SvgImage size="xl" image="verified-check" />
              </div>

              <div className="textContainer">
                <div>Your license is valid and we think that is awesome! </div>

                <div className="mt-3">
                  Keep using Estimator, keep telling us how we can continue to make preconstruction
                  technology better, and keep revolutionizing the construction industry.
                </div>
                <div className="mt-3">
                  We also encourage you to join our community at{' '}
                  <a href={websiteUrlCommunity} title="Beck Technology Community">
                    {getUrlForDisplay(websiteUrlCommunity)}
                  </a>{' '}
                  to get the latest updates and interact with other Estimator users.
                </div>
              </div>
            </div>
          </div>
        )}

        {allLinkCollections.map((currLinkCollection, i) => (
          <div className="cardCollection" key={i}>
            <h1>{currLinkCollection.title}</h1>

            <div className="row" key={i}>
              {currLinkCollection.cards.map(currCard => {
                return (
                  <div
                    className={[
                      'col-12 col-md-6',
                      currLinkCollection.cards.length % 2 !== 0 ? 'col-xl-4' : 'col-xl-3',
                    ].join(' ')}
                    key={currCard.title}
                    title={currCard.title}
                  >
                    <Link to={currCard.url}>
                      <div
                        className={`navigationCard shadow-sm blue-line-top-hover ${
                          isDevelopmentEnvironment ? getEEClass() : ''
                        }`}
                      >
                        <div className="cardIcon">
                          {currCard.svgImage && <SvgImage image={currCard.svgImage} />}
                        </div>
                        <div className="cardTitle">{currCard.title}</div>
                      </div>
                    </Link>
                  </div>
                );
              })}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default HomePage;
