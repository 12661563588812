const LocalStorageKeys = {
  AccessToken: 'accessToken',
  AccessTokenError: 'accessTokenError',
  AuthToken: 'authToken',
  SSOError: 'SSOError',
  SSOPasswordResetCompleted: 'ssoPasswordResetCompleted',
  SSOPasswordResetInitiated: 'ssoPasswordResetInitiated',
  UserInformation: 'userInformation',
  UserName: 'userName',
};

export default LocalStorageKeys;
