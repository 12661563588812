import { FormikErrors } from 'formik';

// NOTE: using `any` here because of metaprogramming
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getFormErrorMessages = (errors: FormikErrors<any>): string[] => {
  const errorMessages: string[] = [];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const getStringValues = (values: any[]): string[] => {
    const stringValues: string[] = [];

    values.forEach(val => {
      if (typeof val === 'string') {
        stringValues.push(val);
        return;
      }

      if (Array.isArray(val)) {
        stringValues.push(...getStringValues(val));
        return;
      }

      if (typeof val === 'object') {
        stringValues.push(...getStringValues(Object.values(val)));
        return;
      }
    });

    return stringValues;
  };

  const stringValues = getStringValues(Object.values(errors));
  errorMessages.push(...stringValues);

  return errorMessages;
};

export { getFormErrorMessages };
