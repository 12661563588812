import React, { useState, useCallback } from 'react';

interface ConfirmationOverlayDisplayInfo {
  currentConfirmationOverlayParameters: ConfirmationOverlayParameters | null;
  isDisplayed: boolean;
  hasBeenToggled: boolean;
}

interface ConfirmationOverlayParameters {
  title: string;
  text: string;
  buttons: ConfirmationOverlayButton[];
}

interface ConfirmationOverlayButton {
  color?: 'normal' | 'gray';
  text: string;
  onClick: () => void;
}

interface ConfirmationOverlayContextInterface {
  confirmationOverlayDisplayInfo: ConfirmationOverlayDisplayInfo;
  showConfirmationOverlay: (confirmationOverlayParameters: ConfirmationOverlayParameters) => void;
  hideConfirmationOverlay: () => void;
}

const ConfirmationOverlayContext = React.createContext<ConfirmationOverlayContextInterface>({
  confirmationOverlayDisplayInfo: {
    currentConfirmationOverlayParameters: null,
    isDisplayed: false,
    hasBeenToggled: false,
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  showConfirmationOverlay: (confirmationOverlayParameters: ConfirmationOverlayParameters) => {},
  hideConfirmationOverlay: () => {},
});

const ConfirmationOverlayContextProvider: React.FC<{}> = props => {
  const [confirmationOverlayDisplayInfo, setConfirmationOverlayDisplayInfo] = useState<
    ConfirmationOverlayDisplayInfo
  >({
    currentConfirmationOverlayParameters: null,
    isDisplayed: false,
    hasBeenToggled: false,
  });

  const showConfirmationOverlay = useCallback(
    (confirmationOverlayParameters: ConfirmationOverlayParameters): void => {
      setConfirmationOverlayDisplayInfo({
        currentConfirmationOverlayParameters: confirmationOverlayParameters,
        isDisplayed: true,
        hasBeenToggled: true,
      });
    },
    [],
  );

  const hideConfirmationOverlay = useCallback((): void => {
    setConfirmationOverlayDisplayInfo({
      currentConfirmationOverlayParameters: null,
      isDisplayed: false,
      hasBeenToggled: true,
    });
  }, []);

  return (
    <ConfirmationOverlayContext.Provider
      value={{
        confirmationOverlayDisplayInfo: confirmationOverlayDisplayInfo,
        showConfirmationOverlay: showConfirmationOverlay,
        hideConfirmationOverlay: hideConfirmationOverlay,
      }}
    >
      {props.children}
    </ConfirmationOverlayContext.Provider>
  );
};

export { ConfirmationOverlayContext, ConfirmationOverlayContextProvider };
