import axios from 'axios';
import ApiRoutes from '../../../constants/routes/api-routes';
import {
  ClientOrganizationViewDto,
  getClientOrganizationFromDto,
  ClientOrganizationAddOrEditDto,
  getDtoFromAddress,
} from './clients-api-service-helper';

const getClientOrganizations = async (): Promise<ClientOrganization[]> => {
  const response = await axios.get<ClientOrganizationViewDto[]>(ApiRoutes.Clients);
  const clientOrgs = response.data.map(dto => getClientOrganizationFromDto(dto));

  return clientOrgs;
};

const getClientOrganizationByEmail = async (email: string): Promise<ClientOrganization> => {
  const url = `${ApiRoutes.ClientByEmail}/${email}`;
  const response = await axios.get<ClientOrganizationViewDto>(url);
  const clientOrg = getClientOrganizationFromDto(response.data);

  return clientOrg;
};

const addClientOrganization = async (
  clientOrg: ClientOrganization,
): Promise<ClientOrganization> => {
  const dto: ClientOrganizationAddOrEditDto = {
    name: clientOrg.name,
    accountExecutive: clientOrg.accountExecutive,
    address: getDtoFromAddress(clientOrg.address),
    domains: clientOrg.domains,
    usesCorporateActiveDirectory: clientOrg.usesCorporateActiveDirectory,
  };

  const response = await axios.post<ClientOrganizationViewDto>(ApiRoutes.Clients, dto);
  const newClientOrg = getClientOrganizationFromDto(response.data);

  return newClientOrg;
};

const editClientOrganization = async (clientOrg: ClientOrganization): Promise<void> => {
  const dto: ClientOrganizationAddOrEditDto = {
    name: clientOrg.name,
    accountExecutive: clientOrg.accountExecutive,
    address: getDtoFromAddress(clientOrg.address),
    domains: clientOrg.domains,
    usesCorporateActiveDirectory: clientOrg.usesCorporateActiveDirectory,
  };

  await axios.put(`${ApiRoutes.Clients}/${clientOrg.id}`, dto);
};

const deleteClientOrganization = async (clientOrgId: string): Promise<void> => {
  await axios.delete(`${ApiRoutes.Clients}/${clientOrgId}`);
};

export default {
  getClientOrganizations,
  getClientOrganizationByEmail,
  addClientOrganization,
  editClientOrganization,
  deleteClientOrganization,
};
