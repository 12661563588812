import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Button } from 'reactstrap';
import './BTIconButton.scss';

interface Props {
  icon: IconProp;
  disabled?: boolean;
  onClick: React.MouseEventHandler<any>; // eslint-disable-line @typescript-eslint/no-explicit-any
  tooltip: string;
  tabIndex?: number;
  'data-testid'?: string;
}

const BTIconButton: React.FC<Props> = ({ onClick, disabled, tooltip, icon, tabIndex, ...rest }) => {
  return (
    <Button
      type="button"
      className="btIconButton rounded-circle"
      onClick={onClick}
      disabled={disabled}
      title={tooltip}
      tabIndex={tabIndex}
      {...rest} // NOTE: don't remove, this is how we are passing data props like `data-testid`
    >
      <FontAwesomeIcon icon={icon} className="buttonIcon" />
    </Button>
  );
};

export default BTIconButton;
